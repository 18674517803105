import { Link, useNavigate } from 'react-router-dom'; // Updated import
import React, { useState, useEffect } from "react";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  getAuth
} from "firebase/auth";
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { Container } from '../../components/Container';
import { Button } from '../../components/Button.js';
import { trackPageView } from '../../util/analyticsService.js';

const PasswordSignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [resetPasswordSent, setResetPasswordSent] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    trackPageView('/passwordSignIn.js');
  }, []);

  // Instantiate the auth service SDK
  const auth = getAuth();

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "email") setEmail(value);
    if (name === "password") setPassword(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Sign in with email and password in firebase auth service
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      // The signed-in user info
      const user = userCredential.user;
      navigate('/');
    } catch (err) {
     // Handle Errors here.
      const errorMessage = err.message;
      const errorCode = err.code;

      setError(true);

      switch (errorCode) {
        case "auth/invalid-email":
        case "ERROR_EMAIL_ALREADY_IN_USE":
        case "auth/account-exists-with-different-credential":
        case "auth/email-already-exists":
          setErrorMessage("This email address is invalid.");
          break;
        case "auth/user-disabled":
          setErrorMessage("This email address is disabled by the administrator.");
          break;
        case "auth/user-not-found":
          setErrorMessage("This email address is not registered.");
          break;
        case "auth/invalid-credential":
        case "auth/wrong-password":
          setErrorMessage("The password and email combination is invalid.");
          break;
        case "auth/operation-not-allowed":
          setErrorMessage("Too many requests to log into this account.");
        case "auth/user-not-found":
          setErrorMessage("No user found with this email.");
        default:
          setErrorMessage(errorMessage);
          break;
      }
    }
  };

  const handleResetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setResetPasswordSent(true);
      setErrorMessage(""); // Clear any existing error message
    } catch (err) {
      setErrorMessage("Error sending reset password email. Please check your email address.");
    }
  };

  return (
    <div id="app">
      <Container>
        <Header />
      </Container>
        
      <div className="flex flex-col items-center justify-center" style={{ minHeight: '65vh' }}>
        <div className='max-w-lg w-full px-4 pb-8'>
          <h1 className="pb-6 mx-auto max-w-4xl font-display text-5xl font-medium tracking-tight text-slate-900 sm:text-5xl">
            Sign In
          </h1>
          <form className='signinContainer__box__form w-full max-w-lg mx-auto flex flex-col space-y-4' onSubmit={handleSubmit}>
            <input
              type='email'
              placeholder='Email'
              name='email'
              onChange={handleChange}
              className='border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500 w-full'
            />
            <input
              type='password'
              placeholder='Password'
              name='password'
              onChange={handleChange}
              className='border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500'
            />
            <Button type='submit'>Sign In</Button>
            {error && <p className='text-red-500'>{errorMessage}</p>}
          </form>

          {email && ( // Only show reset password if an email is entered
            <div className='signinContainer__box__reset'>
              <p className="pt-4 text-purple-600 cursor-pointer" onClick={handleResetPassword}>Forgot your password?</p>
              {resetPasswordSent && <p className="pb-4 text-green-700">Reset password email sent. Check your inbox.</p>}
            </div>
          )}

          <div className='signinContainer__box__signup'>
            <p className="pt-4">
              Don't have an account? <Link to='/signup' className="text-purple-600 cursor-pointer">Sign Up</Link>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PasswordSignIn;