import React, { useState, useEffect } from 'react';
import { Container } from '../../components/Container.js';
import { getAuth, signInWithCustomToken, getIdTokenResult } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import FetchUserAndFirebaseDocsOnAuth from '../../components/fetchFirebaseDocsOnAuth.js';
import { useNavigate } from 'react-router-dom';

const auth = getAuth();
const functions = getFunctions();

const AdminSettings = () => {
    const [customToken, setCustomToken] = useState('');
    const [targetUserId, setTargetUserId] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const [message, setMessage] = useState('');
    const user = FetchUserAndFirebaseDocsOnAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const checkAdminAccess = async () => {
            const currentUser = auth.currentUser;

            if (currentUser?.email !== "michael@fairground.music") {
                console.warn("User is not authorised, redirecting to home...");
                navigate('/');  // Redirect to home page
            } else {
                setIsAdmin(true);
            }
        };

        checkAdminAccess();
    }, [user, navigate]);

    useEffect(() => {
        const checkAdminClaim = async () => {
            const user = auth.currentUser;            if (user) {
                try {
                    const tokenResult = await getIdTokenResult(user);
                    if (tokenResult.claims.email === "michael@fairground.music") {
                        setIsAdmin(true);
                    }
                } catch (error) {
                    console.error('Error checking admin claim:', error);
                }
            }
        };

        checkAdminClaim();
    }, [user]);

    const handleLoginWithToken = async () => {
        if (!customToken) {
            alert('Please enter a custom token.');
            return;
        }

        try {
            const userCredential = await signInWithCustomToken(auth, customToken);
            const user = userCredential.user;
            console.log('Successfully logged in as user:', user.uid);
            alert(`Logged in as user: ${user.uid}`);
            setMessage('');
            setIsAdmin(false); // Reset since you are now impersonating
        } catch (error) {
            console.error('Error signing in with custom token:', error);
            alert('Failed to sign in with custom token.');
        }
    };

    const fetchCustomToken = async () => {
        if (!targetUserId) {
            alert('Please enter a target user ID.');
            return;
        }

        try {
            const generateToken = httpsCallable(functions, 'generateImpersonationToken');
            const response = await generateToken({ targetUserId });
            setCustomToken(response.data.token);
            alert('Custom token fetched successfully!');
        } catch (error) {
            console.error('Error fetching custom token:', error);
            alert('Failed to fetch custom token.');
        }
    };

    return (
        <div>
            <Container>
                <div className="pb-8 mx-auto" style={{ minHeight: '65vh' }}>
                    <div className="pb-4">
                        <h1 className="font-display text-2xl tracking-tight text-slate-900 sm:text-3xl">
                            Admin Settings
                        </h1>
                    </div>

                    {isAdmin && (
                        <div className="mb-4 p-3 bg-green-100 text-green-800 rounded">
                            ✅ You are an Admin
                        </div>
                    )}

                    {/* Fetch Custom Token */}
                    <div className="mt-4">
                        <input
                            type="text"
                            placeholder="Enter target user UID"
                            value={targetUserId}
                            onChange={(e) => setTargetUserId(e.target.value)}
                            className="border rounded p-2 w-full"
                        />
                        <button
                            onClick={fetchCustomToken}
                            className="mt-2 bg-purple-500 text-white px-4 py-2 rounded"
                        >
                            Fetch Custom Token for User
                        </button>
                    </div>

                    {/* Show and Use Custom Token */}
                    <div className="mt-4">
                        <input
                            type="text"
                            placeholder="Paste custom token here"
                            value={customToken}
                            onChange={(e) => setCustomToken(e.target.value)}
                            className="border rounded p-2 w-full"
                        />
                        <button
                            onClick={handleLoginWithToken}
                            className="mt-2 bg-blue-500 text-white px-4 py-2 rounded"
                        >
                            Log in with Custom Token
                        </button>
                    </div>

                    {message && <p className="mt-4 text-green-700">{message}</p>}
                </div>
            </Container>
        </div>
    );
};

export default AdminSettings;
