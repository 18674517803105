import React, { useState, useContext, useEffect } from 'react';
import { Button } from '../components/Button.js';
import { ApiContext } from '../contexts/ApiContext.js';
import { db } from '../firebase.js';
import FetchUserAndFirebaseDocsOnAuth from '../components/fetchFirebaseDocsOnAuth.js';
import { collection, getDoc, updateDoc, doc } from 'firebase/firestore'; // Import firestore functions
import { UserIcon, ClipboardDocumentListIcon, Cog6ToothIcon, CheckCircleIcon } from '@heroicons/react/24/outline';

const steps = [
    {
        title: "Step 1",
        header: "Welcome to Insert Affiliate",
        content: Step1Content,
        icon: UserIcon,
    },
    {
        title: "Step 2",
        header: "Deep Linking",
        content: Step2Content,
        icon: ClipboardDocumentListIcon,
    },
    {
        title: "Step 3",
        header: "In-App Purchase Verification",
        content: Step3Content,
        icon: Cog6ToothIcon,
    },
    {
        title: "Step 4",
        header: "Confirm Your Settings",
        content: Step4Content,
        icon: CheckCircleIcon,
    }
];

function Step1Content() {
    return (
        <>
            <p className="mb-4">
                To get started, please complete the steps below. These are essential for tracking sales and managing affiliate payments.
            </p>
            <p className="mb-4">
                For a comprehensive guide, visit our detailed documentation <a href="https://docs.insertaffiliate.com" target="_blank" className="text-purple-500 hover:text-purple-600 dark:text-purple-300 dark:hover:text-purple-500">here</a>.
            </p>
        </>
    );
}

function Step2Content() {
  return (
    <>
        <p className="mb-4">
            Deep links enable affiliates to direct customers to your app. We integrate with trusted third-party services to ensure a seamless experience.
        </p>
        <p className="mb-4">
            Select your preferred platform for guidance
        </p>
        <div className="not-prose mt-4 grid grid-cols-1 gap-x-6 gap-y-10 md:grid-cols-2 xl:max-w-none xl:grid-cols-2 dark:border-white/5 mb-4 md:mb-10">
            <a className="flex flex-row-reverse gap-6 mt-4" href="https://docs.insertaffiliate.com/branch" target="_blank" rel="noopener noreferrer">
                <div className="flex-auto p-7 border">
                    <h3 className="text-sm font-semibold text-zinc-900">
                        Branch (Recommended)
                    </h3>
                    <p className="mt-1 text-sm text-zinc-600 dark:text-zinc-400">
                        One of the most popular deep linking platforms.
                    </p>
                    <div className="mt-4">
                        <div
                            className="inline-flex gap-0.5 justify-center overflow-hidden text-sm font-medium transition text-purple-500 hover:text-purple-600 dark:text-purple-300 dark:hover:text-purple-500"
                            href={"docs.insertaffiliate.com/branch"}
                            variant="text"
                            arrow="right"
                        >
                            Setup Guide
                            <svg viewBox="0 0 20 20" fill="none" aria-hidden="true" className="mt-0.5 h-5 w-5 relative top-px -mr-1"><path stroke="currentColor" d="m11.5 6.5 3 3.5m0 0-3 3.5m3-3.5h-9"></path></svg>
                        </div>
                    </div>
                </div>
            </a>
            <a className="flex flex-row-reverse gap-6 mt-4" href="https://docs.insertaffiliate.com/other-deep-linking-platform" target="_blank" rel="noopener noreferrer">
                <div className="flex-auto p-7 border">
                    <h3 className="text-sm font-semibold text-zinc-900">
                        Other Deep Linking Platforms
                    </h3>
                    <p className="mt-1 text-sm text-zinc-600 dark:text-zinc-400">
                        All other deep linking platforms.
                    </p>
                    <div className="mt-4">

                        <div
                            className="inline-flex gap-0.5 justify-center overflow-hidden text-sm font-medium transition text-purple-500 hover:text-purple-600 dark:text-purple-300 dark:hover:text-purple-500"
                            href={"https://docs.insertaffiliate.com/other-deep-linking-platform"}
                            variant="text"
                            arrow="right"
                        >
                            Setup Guide
                            <svg viewBox="0 0 20 20" fill="none" aria-hidden="true" className="mt-0.5 h-5 w-5 relative top-px -mr-1"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="m11.5 6.5 3 3.5m0 0-3 3.5m3-3.5h-9"></path></svg>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </>
  );
}

function Step3Content({
    revenueCatAuthHeader,
    appStoreNotificationUrl,
    googlePlayStoreNotificationUrl,
    localSelectedVerificationMethod,
    setLocalSelectedVerificationMethod,
    localIapticAppName,
    setLocalIapticAppName,
    localIapticSecretKey,
    setLocalIapticSecretKey,
    showIapticSecret,
    toggleSecretIapticVisibility
}) {
    const handleVerificationMethodChange = (e) => {
        setLocalSelectedVerificationMethod(e.target.value);
    };

    return (
      <>
        <p className="mb-4">
          Insert Affiliate integrates with your favourite receipt verification platforms to accurately track sales and profits. 
        </p>
        <p className="mb-4 text-sm">
          If you are using an another Receipt Verification platform not listed here, please <a href="mailto:michael@insertaffiliate.com" className="text-purple-600 hover:underline">contact us</a> and let us know.
        </p>
        <div className="not-prose grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 xl:max-w-none xl:grid-cols-2 dark:border-white/5 mb-8">
            <a className="flex flex-row-reverse gap-6 mt-4" href="https://docs.insertaffiliate.com/iaptic" target="_blank" rel="noopener noreferrer">
                <div className="flex-auto p-7 border">
                    <h3 className="text-sm font-semibold text-zinc-900">
                        Iaptic Setup
                    </h3>
                    <p className="mt-1 text-sm text-zinc-600 dark:text-zinc-400">
                        Service for validating in-app purchase and subscription receipts.
                    </p>
                    <div className="mt-4">
                        <div
                            className="inline-flex gap-0.5 justify-center overflow-hidden text-sm font-medium transition text-purple-500 hover:text-purple-600 dark:text-purple-300 dark:hover:text-purple-500"
                            href={"https://docs.insertaffiliate.com/iaptic"}
                            variant="text"
                            arrow="right"
                        >
                            Setup Guide
                            <svg viewBox="0 0 20 20" fill="none" aria-hidden="true" className="mt-0.5 h-5 w-5 relative top-px -mr-1"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="m11.5 6.5 3 3.5m0 0-3 3.5m3-3.5h-9"></path></svg>
                        </div>
                    </div>
                </div>
            </a>
            <a className="flex flex-row-reverse gap-6 mt-4" href="https://docs.insertaffiliate.com/revenuecat" target="_blank" rel="noopener noreferrer">
                <div className="flex-auto p-7 border">
                    <h3 className="text-sm font-semibold text-zinc-900">
                        RevenueCat Setup
                    </h3>
                    <p className="mt-1 text-sm text-zinc-600 dark:text-zinc-400">
                        Service for validating in-app purchase and subscription receipts.
                    </p>
                    <div className="mt-4">
                        <div
                            className="inline-flex gap-0.5 justify-center overflow-hidden text-sm font-medium transition text-purple-500 hover:text-purple-600 dark:text-purple-300 dark:hover:text-purple-500"
                            href={"https://docs.insertaffiliate.com/revenuecat"}
                            variant="text"
                            arrow="right"
                        >
                            Setup Guide
                            <svg viewBox="0 0 20 20" fill="none" aria-hidden="true" className="mt-0.5 h-5 w-5 relative top-px -mr-1"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="m11.5 6.5 3 3.5m0 0-3 3.5m3-3.5h-9"></path></svg>
                        </div>
                    </div>
                </div>
            </a>
            <a className="flex flex-row-reverse gap-6 mt-4" href="https://docs.insertaffiliate.com/direct-store-purchase-integration" target="_blank" rel="noopener noreferrer">
                <div className="flex-auto p-7 border">
                    <h3 className="text-sm font-semibold text-zinc-900">
                        Beta - I don't use a Receipt Verification Service (Native Integration)
                    </h3>
                    <p className="mt-1 text-sm text-zinc-600 dark:text-zinc-400">
                        Native Integration is a simple way to track sales without a Receipt Verification Service, but it is less secure.
                    </p>
                    <div className="mt-4">
                        <div
                            className="inline-flex gap-0.5 justify-center overflow-hidden text-sm font-medium transition text-purple-500 hover:text-purple-600 dark:text-purple-300 dark:hover:text-purple-500"
                            href={"https://docs.insertaffiliate.com/direct-store-purchase-integration"}
                            variant="text"
                            arrow="right"
                        >
                            Setup Guide
                            <svg viewBox="0 0 20 20" fill="none" aria-hidden="true" className="mt-0.5 h-5 w-5 relative top-px -mr-1"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="m11.5 6.5 3 3.5m0 0-3 3.5m3-3.5h-9"></path></svg>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div className="mb-4">
            <label htmlFor="verificationMethod" className="mb-2 block text-sm font-medium text-gray-700">
                In-App Purchase Verification Method:
            </label>
            <select
                id="verificationMethod"
                value={localSelectedVerificationMethod}
                onChange={handleVerificationMethodChange}
                className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
            >
                <option value="RevenueCat">RevenueCat</option>
                <option value="Iaptic">Iaptic</option>
                <option value="Native">I don't use a Receipt Validator (Native App Store / Google Play  Integration)</option>
            </select>
        </div>
        {localSelectedVerificationMethod === 'Iaptic' && (
            <>
                <div className="grid grid-cols-1 gap-4 md:gap-6 sm:grid-cols-2 mb-4 md:mb-8">
                    <div>
                        <label htmlFor="iapticAppName" className="mb-3 block text-sm font-medium text-gray-700">
                            Iaptic App Name:
                        </label>
                        <div>
                            <p className="text-xs text-gray-500 mb-2 mt-2">
                                Please note, this is CASE SENSITIVE. 
                            </p>
                            <input
                                type="text"
                                id="iapticAppName"
                                value={localIapticAppName}
                                onChange={(e) => setLocalIapticAppName(e.target.value)}
                                className={`block w-full appearance-none rounded-md px-3 py-2 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-blue-500 sm:text-sm ${
                                    localIapticAppName === '' ? 'border-red-500 bg-red-50' : 'border-green-200 bg-green-50'
                                }`}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="iapticSecretKey" className="mb-3 block text-sm font-medium text-gray-700">
                            Iaptic Secret:
                        </label>
                        <div className="relative">
                            <div className="mb-2 mt-2"></div>
                            <input
                                type={showIapticSecret ? 'text' : 'password'}
                                id="iapticSecretKey"
                                value={localIapticSecretKey}
                                onChange={(e) => setLocalIapticSecretKey(e.target.value)}
                                className={`sm:mt-9 block w-full appearance-none rounded-md px-3 py-2 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-blue-500 sm:text-sm ${
                                    localIapticSecretKey === '' ? 'border-red-500 bg-red-50' : 'border-green-200 bg-green-50'
                                }`}
                            />
                            <button
                                type="button"
                                onClick={toggleSecretIapticVisibility}
                                className="absolute inset-y-0 right-0 px-3 flex items-center text-sm font-medium text-gray-700"
                            >
                                {showIapticSecret ? 'Hide' : 'Show'}
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )}
        {localSelectedVerificationMethod === 'Native' && (
            <>
                {/* <div className="mb-4">
                    <label
                        htmlFor="googlePlayStoreNotificationUrl"
                        className="mb-2 block text-sm font-medium text-gray-700"
                    >
                        Google Play Store Pub/Sub Endpoint Url (Android):
                    </label>
                    <input
                        type="text"
                        id="googlePlayStoreNotificationUrl"
                        value={googlePlayStoreNotificationUrl}
                        readOnly
                        className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    />
                </div> */}
                <div className="mb-4">
                    <label
                        htmlFor="appStoreNotificationUrl"
                        className="mb-2 block text-sm font-medium text-gray-700"
                    >
                        iOS App Store Server Notifications Url:
                    </label>
                    <input
                        type="text"
                        id="appStoreNotificationUrl"
                        value={appStoreNotificationUrl}
                        readOnly
                        className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    />
                </div>
            </>
         )}
        {localSelectedVerificationMethod === 'RevenueCat' && (
            <>
                <div className="mb-4">
                    <label
                        htmlFor="revenueCatAuthHeader"
                        className="mb-2 block text-sm font-medium text-gray-700"
                    >
                        RevenueCat Webhook Authentication Header:
                    </label>
                    <input
                        type="text"
                        id="revenueCatAuthHeader"
                        value={revenueCatAuthHeader}
                        readOnly
                        className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    />
                </div>
            </>
        )}
      </>
    );
}

function Step4Content({
    localIapticAppName,
    setLocalIapticAppName,
    localIapticSecretKey,
    setLocalIapticSecretKey,
    showIapticSecret,
    toggleSecretIapticVisibility,
    localCompanyName,
    setLocalCompanyName,
    localCompanyContactEmail,
    setLocalCompanyContactEmail,
    localCompanyAffiliatePaymentPercentage,
    setLocalCompanyAffiliatePaymentPercentage,
    localSelectedVerificationMethod
}) {
    return (
        <>
            <p className="mb-4">Please confirm your settings below:</p>
            <div className="grid grid-cols-1 gap-4 md:gap-6 sm:grid-cols-2 mb-4 md:mb-8">
                <div>
                    <label htmlFor="companyName" className="mb-3 block text-sm font-medium text-gray-700">
                        Company Name:
                    </label>
                    <input
                        type="text"
                        id="companyName"
                        value={localCompanyName}
                        onChange={(e) => setLocalCompanyName(e.target.value)}
                        className={`block w-full appearance-none rounded-md px-3 py-2 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-blue-500 sm:text-sm ${
                            localCompanyName === '' ? 'border-red-500 bg-red-50' : 'border-green-200 bg-green-50'
                        }`}
                    />
                </div>
                <div>
                    <label htmlFor="companyContactEmail" className="mb-3 block text-sm font-medium text-gray-700">
                        Contact Email (for Affiliates):
                    </label>
                    <input
                        type="email"
                        id="companyContactEmail"
                        value={localCompanyContactEmail}
                        onChange={(e) => setLocalCompanyContactEmail(e.target.value)}
                        className={`block w-full appearance-none rounded-md px-3 py-2 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-blue-500 sm:text-sm ${
                            localCompanyContactEmail === '' ? 'border-red-500 bg-red-50' : 'border-green-200 bg-green-50'
                        }`}
                    />
                </div>
                <div>
                    <label htmlFor="companyAffiliatePaymentPercentage" className="mb-3 block text-sm font-medium text-gray-700">
                        Default Affiliate Commission Percentage:
                    </label>
                    <input
                        type="number"
                        id="companyAffiliatePaymentPercentage"
                        value={localCompanyAffiliatePaymentPercentage}
                        onChange={(e) => setLocalCompanyAffiliatePaymentPercentage(e.target.value)}
                        className={`sm:mt-9 block w-full appearance-none rounded-md px-3 py-2 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-blue-500 sm:text-sm ${
                            !localCompanyAffiliatePaymentPercentage ? 'border-red-500 bg-red-50' : 'border-green-200 bg-green-50'
                        }`}
                    />
                </div>
                {localSelectedVerificationMethod === 'Iaptic' && (
                    <>
                        <div>
                            <label htmlFor="iapticAppName" className="mb-3 block text-sm font-medium text-gray-700">
                                Iaptic App Name:
                            </label>
                            <div>
                                <p className="text-xs text-gray-500 mb-2 mt-2">
                                    Please note, this is CASE SENSITIVE. 
                                </p>
                                <input
                                    type="text"
                                    id="iapticAppName"
                                    value={localIapticAppName}
                                    onChange={(e) => setLocalIapticAppName(e.target.value)}
                                    className={`block w-full appearance-none rounded-md px-3 py-2 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-blue-500 sm:text-sm ${
                                        localIapticAppName === '' ? 'border-red-500 bg-red-50' : 'border-green-200 bg-green-50'
                                    }`}
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="iapticSecretKey" className="mb-3 block text-sm font-medium text-gray-700">
                                Iaptic Secret:
                            </label>
                            <div className="relative">
                                <input
                                    type={showIapticSecret ? 'text' : 'password'}
                                    id="iapticSecretKey"
                                    value={localIapticSecretKey}
                                    onChange={(e) => setLocalIapticSecretKey(e.target.value)}
                                    className={`sm:mt-9 block w-full appearance-none rounded-md px-3 py-2 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-blue-500 sm:text-sm ${
                                        localIapticSecretKey === '' ? 'border-red-500 bg-red-50' : 'border-green-200 bg-green-50'
                                    }`}
                                />
                                <button
                                    type="button"
                                    onClick={toggleSecretIapticVisibility}
                                    className="absolute inset-y-0 right-0 px-3 flex items-center text-sm font-medium text-gray-700"
                                >
                                    {showIapticSecret ? 'Hide' : 'Show'}
                                </button>
                            </div>
                        </div>
                    </>
                    
                )}
            </div>
        </>
    );
}

export function OnboardingModal({ type, onClose }) {
    const {
        iapticAppName,
        selectedVerificationMethod,
        setSelectedVerificationMethod,
        setIapticAppName,
        iapticSecretKey,
        setIapticSecretKey,
        companyName,
        setCompanyName,
        companyContactEmail,
        setCompanyContactEmail,
        companyAffiliatePaymentPercentage,
        setCompanyAffiliatePaymentPercentage
    } = useContext(ApiContext);

    const {
        revenueCatAuthHeader,
        setRevenueCatAuthHeader,
        appStoreNotificationUrl,
        setAppStoreNotificationUrl,
        googlePlayStoreNotificationUrl,
        setGooglePlayStoreNotificationUrl,
        companyId
    } = useContext(ApiContext)

    
      


    const [localSelectedVerificationMethod, setLocalSelectedVerificationMethod] = useState(selectedVerificationMethod || 'Iaptic'); // Default to Iaptic
    const [showIapticSecret, setShowIapticSecret] = useState(false);
    const [localCompanyName, setLocalCompanyName] = useState(companyName || ""); // Ensure a default value
    const [localCompanyContactEmail, setLocalCompanyContactEmail] = useState(companyContactEmail || "");
    const [localCompanyAffiliatePaymentPercentage, setLocalCompanyAffiliatePaymentPercentage] = useState(
        typeof companyAffiliatePaymentPercentage === "number" && companyAffiliatePaymentPercentage > 0 ? companyAffiliatePaymentPercentage : 5
    );
    const [localIapticAppName, setLocalIapticAppName] = useState(iapticAppName || ""); // Ensure a default value
    const [localIapticSecretKey, setLocalIapticSecretKey] = useState(iapticSecretKey || "");

    const [currentStep, setCurrentStep] = useState(0);
   
    useEffect(() => {
        const triggerApiCallForRevenueCat = async () => {
          if (localSelectedVerificationMethod === "RevenueCat" && revenueCatAuthHeader === "") {
            try {
              const response = await fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/generate-auth-token`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ companyId }),
              });
      
              if (!response.ok) {
                throw new Error("Failed to generate RevenueCat auth token");
              }
      
              const data = await response.json();
              setRevenueCatAuthHeader(data.token);
              const companiesRef = collection(db, 'Companies');
              const userDocRef = doc(companiesRef, user.uid);
    
              await updateDoc(userDocRef, {
                revenueCatAuthHeader: data.token
              });
            } catch (error) {
              console.error("Error during RevenueCat Auth Token API call:", error);
            }
          }
        };
      
        triggerApiCallForRevenueCat();
    }, [localSelectedVerificationMethod, revenueCatAuthHeader, companyId]); // Dependency array ensures the effect triggers only on relevant changes

    useEffect(() => {
        const triggerApiCallForGooglePlayStoreNotification = async () => {
            if (localSelectedVerificationMethod === "Native" && (googlePlayStoreNotificationUrl === "" || googlePlayStoreNotificationUrl === undefined)) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/generate-google-play-store-notification-url`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ companyId }),
                });
    
                if (!response.ok) {
                throw new Error("Failed to generate App Store notification URL");
                }
    
                const data = await response.json();
                setGooglePlayStoreNotificationUrl(data.url);
                const companiesRef = collection(db, 'Companies');
                const userDocRef = doc(companiesRef, user.uid);
    
                await updateDoc(userDocRef, {
                googlePlayStoreNotificationUrl: data.url
                });
    
            } catch (error) {
                console.error("Error during App Store Notification URL API call:", error);
            }
            }
        };
        
        triggerApiCallForGooglePlayStoreNotification();
    }, [localSelectedVerificationMethod, googlePlayStoreNotificationUrl, companyId]);
    
    useEffect(() => {
        const triggerApiCallForAppStoreNotification = async () => {
          if (localSelectedVerificationMethod === "Native" && (appStoreNotificationUrl === "" || appStoreNotificationUrl === undefined)) {
            try {
              const response = await fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/generate-app-store-notification-url`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ companyId }),
              });
    
              if (!response.ok) {
                throw new Error("Failed to generate App Store notification URL");
              }
    
              const data = await response.json();
              setAppStoreNotificationUrl(data.url);
              const companiesRef = collection(db, 'Companies');
              const userDocRef = doc(companiesRef, user.uid);
    
              await updateDoc(userDocRef, {
                appStoreNotificationUrl: data.url
              });
    
            } catch (error) {
              console.error("Error during App Store Notification URL API call:", error);
            }
          }
        };
        
        triggerApiCallForAppStoreNotification();
      }, [localSelectedVerificationMethod, appStoreNotificationUrl, companyId]); // Dependency array ensures the effect triggers only on relevant changes
      
    // Update local when remote is retrieved
    useEffect(() => {
        if (companyName !== '' && companyName !== localCompanyName) {
        setLocalCompanyName(companyName);
        }
    }, [companyName]);
    useEffect(() => {
        if (companyContactEmail !== '' && companyContactEmail !== localCompanyContactEmail) {
        setLocalCompanyContactEmail(companyContactEmail);
        }
    }, [companyContactEmail]);
    useEffect(() => {
        if (iapticAppName !== '' && iapticAppName !== localIapticAppName) {
        setLocalIapticAppName(iapticAppName);
        }
    }, [iapticAppName]);
    useEffect(() => {
        if (iapticSecretKey !== '' && iapticSecretKey !== localIapticSecretKey) {
        setLocalIapticSecretKey(iapticSecretKey);
        }
    }, [iapticSecretKey]);
    useEffect(() => {
        if (typeof companyAffiliatePaymentPercentage === "number" && companyAffiliatePaymentPercentage > 0) {
            setLocalCompanyAffiliatePaymentPercentage(companyAffiliatePaymentPercentage);
        }
    }, [companyAffiliatePaymentPercentage]);
    

    const user = FetchUserAndFirebaseDocsOnAuth();

    const updateAndSaveSettings = async ({ selectedVerificationMethod, companyName, companyContactEmail, iapticAppName, iapticSecretKey, companyAffiliatePaymentPercentage }) => {
        // Assuming your Firestore collection is named 'copanies'
        const companiesRef = collection(db, 'Companies');

        const userDocRef = doc(companiesRef, user.uid); // assuming user.uid is the user's UID

        const privateRef = collection(userDocRef, 'Private');
        const privateDocRef = doc(privateRef, 'Credentials');

        try {
            const userDocSnapshot = await getDoc(userDocRef);
            const oldCompanyName = userDocSnapshot.data().companyName;


            // Update the Firestore document with the new settings
            await updateDoc(userDocRef, {
                selectedVerificationMethod: selectedVerificationMethod,
                companyName: companyName,
                companyContactEmail: companyContactEmail,
                companyAffiliatePaymentPercentage: companyAffiliatePaymentPercentage
            });

            await updateDoc(privateDocRef, {
                iapticAppName: iapticAppName,
                iapticSecretKey: iapticSecretKey
            });

            setSelectedVerificationMethod(selectedVerificationMethod);
            setCompanyName(companyName);
            setCompanyContactEmail(companyContactEmail);
            setIapticAppName(iapticAppName);
            setIapticSecretKey(iapticSecretKey);
            setCompanyAffiliatePaymentPercentage(companyAffiliatePaymentPercentage);
        } catch (error) {
            console.error('Error updating Firestore document:', error);
        }
    };

    const toggleSecretIapticVisibility = () => {
        setShowIapticSecret(!showIapticSecret);
    };

    const handleNextStep = () => {
        if (currentStep < steps.length - 1) {
            setCurrentStep(currentStep + 1);
        } else {
            updateAndSaveSettings({
                companyName: localCompanyName,
                companyContactEmail: localCompanyContactEmail,
                iapticAppName: localIapticAppName,
                iapticSecretKey: localIapticSecretKey,
                companyAffiliatePaymentPercentage: localCompanyAffiliatePaymentPercentage,
                selectedVerificationMethod: localSelectedVerificationMethod,
            });
            onClose();
        }
    };

    const handleClose = () => {
        // TODO: warn before closing
        onClose();
    };

    const StepContent = steps[currentStep].content;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
              <div className="bg-white w-full max-w-4xl h-full md:h-4/5 rounded-lg shadow-lg flex flex-col md:flex-row md:m-4">
                {/* Left side */}
                <div className="bg-purple-700 w-full md:w-1/3 p-4 md:p-8 flex flex-col items-center">
                    <div className="mt-2 flex flex-col w-full">
                        <h1 className="text-white text-3xl md:mb-8">
                            Let's Get Setup
                        </h1>
                        <div className="flex md:flex-col flex-row md:items-start justify-between w-full pt-5">
                            {steps.map((step, index) => (
                                <div
                                    key={index}
                                    className={`relative flex md:flex-row flex-col items-center mb-4 md:mb-8 cursor-pointer ${index === currentStep ? 'text-white' : 'text-gray-400'}`}
                                    onClick={() => setCurrentStep(index)}
                                >
                                    <step.icon className={`w-6 h-6 ${index === currentStep ? 'text-white' : 'text-gray-400'}`} />
                                    <div className="md:ml-4 md:text-left text-center">
                                        <h4 className="text-lg font-bold">Step {index + 1}</h4>
                                        <p className="text-sm">{step.header}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Right side */}
                <div className="w-full md:w-2/3 p-4 md:p-8 overflow-y-auto">
                <div className="mb-4">
                    <h3 className="text-sm text-gray-500">{steps[currentStep].title}</h3>
                    <h2 className="text-xl font-semibold text-gray-900">{steps[currentStep].header}</h2>
                </div>
                {currentStep === 0 ? (
                    <Step1Content />
                ) : currentStep === 1 ? (
                    <Step2Content />
                ) : currentStep === 2 ? (
                    <Step3Content
                        revenueCatAuthHeader={revenueCatAuthHeader}
                        appStoreNotificationUrl={appStoreNotificationUrl}
                        googlePlayStoreNotificationUrl={googlePlayStoreNotificationUrl}
                        localSelectedVerificationMethod={localSelectedVerificationMethod}
                        setLocalSelectedVerificationMethod={setLocalSelectedVerificationMethod}
                        localCompanyName={localCompanyName}
                        setLocalCompanyName={setLocalCompanyName}
                        localCompanyContactEmail={localCompanyContactEmail}
                        setLocalCompanyContactEmail={setLocalCompanyContactEmail}
                        localCompanyAffiliatePaymentPercentage={localCompanyAffiliatePaymentPercentage}
                        setLocalCompanyAffiliatePaymentPercentage={setLocalCompanyAffiliatePaymentPercentage}
                        localIapticAppName={localIapticAppName}
                        setLocalIapticAppName={setLocalIapticAppName}
                        localIapticSecretKey={localIapticSecretKey}
                        setLocalIapticSecretKey={setLocalIapticSecretKey}
                        showIapticSecret={showIapticSecret}
                        toggleSecretIapticVisibility={toggleSecretIapticVisibility}
                    />
                )  : currentStep === 3 ? (
                    <Step4Content
                        localSelectedVerificationMethod={localSelectedVerificationMethod}
                        setLocalSelectedVerificationMethod={setLocalSelectedVerificationMethod}
                        localCompanyName={localCompanyName}
                        setLocalCompanyName={setLocalCompanyName}
                        localCompanyContactEmail={localCompanyContactEmail}
                        setLocalCompanyContactEmail={setLocalCompanyContactEmail}
                        localCompanyAffiliatePaymentPercentage={localCompanyAffiliatePaymentPercentage}
                        setLocalCompanyAffiliatePaymentPercentage={setLocalCompanyAffiliatePaymentPercentage}
                        localIapticAppName={localIapticAppName}
                        setLocalIapticAppName={setLocalIapticAppName}
                        localIapticSecretKey={localIapticSecretKey}
                        setLocalIapticSecretKey={setLocalIapticSecretKey}
                        showIapticSecret={showIapticSecret}
                        toggleSecretIapticVisibility={toggleSecretIapticVisibility}
                    />
                ) : (
                    <StepContent />
                )}
                <Button
                    disabled={currentStep === 3 && !localCompanyAffiliatePaymentPercentage}
                    onClick={handleNextStep}
                >
                    {currentStep < steps.length - 1 ? 'Continue' : 'Finish'}
                </Button>
                {currentStep !== steps.length - 1 ? (
                    <Button
                        onClick={handleClose} 
                        className="ml-2 bg-red-400 hover:bg-red-500 text-white font-bold py-2 px-4 rounded"
                    >
                        Skip Guide
                    </Button>
                ) : null}
                </div>
            </div>
        </div>
    );
}
