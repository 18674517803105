import React, { useState, useContext, useEffect } from 'react';
import { Container } from '../../components/Container';
import { ApiContext } from '../../contexts/ApiContext';
import FetchUserAndFirebaseDocsOnAuth from '../../components/fetchFirebaseDocsOnAuth.js'
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { Button } from '../../components/Button.js';
import { trackPageView } from '../../util/analyticsService.js';

const Affiliate = () => {
    const [tableData, setTableData] = useState([]);
    const [affiliateCode, setAffiliateCode] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [searchExecuted, setSearchExecuted] = useState(false);
    const [error, setError] = useState(null);
    const {iapticAppName, apiUrl} = useContext(ApiContext);
    const {iapticSecretKey} = useContext(ApiContext)

    FetchUserAndFirebaseDocsOnAuth();

    useEffect(() => {
        trackPageView('/affiliate.js');
    }, []);

    const searchAnAffiliate = () => {
        setSearchExecuted(true);
        setTableData([]);
        setError(null);

        if (affiliateCode === '') {
            setError("Affiliate code cannot be empty. Please enter a valid affiliate code.");
            return;
        } else if ((startDate === '' || !startDate) && endDate === '') {
            try {
                fetch(`${apiUrl}/returnAllCustomersApplicationUsernames?appName=${iapticAppName}&secretKey=${iapticSecretKey}`)
                    .then(response => response.json())
                    .then(customers => {
                        const filteredCustomers = customers.filter(customer => customer.startsWith(affiliateCode));

                        if (filteredCustomers.length === 0) {
                            setError("No customers found with the given affiliate code.");
                            return;
                        }
                        

                        // Map each customer to a promise that fetches their purchases
                        const promises = filteredCustomers.map(customer => {
                            return fetch(`${apiUrl}/returnCustomerPurchases/${customer}?appName=${iapticAppName}&secretKey=${iapticSecretKey}`)
                                .then(response => {
                                    if (!response.ok) {
                                        throw new Error(`Error fetching purchases for customer ${customer}: ${response.status}`);
                                    }
                                    return response.json();
                            });
                        });

                        // Wait for all promises to resolve
                        return Promise.all(promises);
                    })
                    .then(purchases => {
                        // For example, you might flatten the array if each customer's purchases are in a separate array
                        setTableData(purchases.flat());
                    })
                    .catch(error => {
                        // console.error("Error fetching data:", error);
                        setError("Failed to fetch affiliate data. Please check all details are correct and try again later.");
                    });
            } catch (error) {
                // console.error("Error fetching data:", error);
                setError("Failed to fetch affiliate data. Please check all details are correct and try again later.");
            }
        } else {
            // try {
            //     fetch(`${apiUrl}/returnAllCustomersApplicationUsernames?appName=${iapticAppName}&secretKey=${iapticSecretKey}`)
            //         .then(response => response.json())
            //         .then(customers => {
            //             const filteredCustomers = customers.filter(customer => customer.startsWith(affiliateCode));
        
            //             // Map each customer to a promise that fetches their purchases
            //             const promises = filteredCustomers.map(customer => {
            //                 return fetch(`${apiUrl}/returnCustomerPurchasesBetweenDates/${customer}/${startDate}/${endDate}`)
            //                     .then(response => response.json());
            //             });
        
            //             // TODO: This does not return the application username & returns things nested differently. Return customer myself as the application username (see it above)

            //             // Wait for all promises to resolve
            //             return Promise.all(promises);
            //         })
            //         .then(purchases => {
            //             // You may want to process the 'purchases' array to get the desired format for 'setTableData'
            //             // For example, you might flatten the array if each customer's purchases are in a separate array
            //             setTableData(purchases.flat());
            //         })
            //         .catch(error => {
            //             console.error("Error fetching data:", error);
            //         });
            // } catch (error) {
            //     console.error("Error fetching data:", error);
            //     setError("Failed to fetch affiliate data. Please check all details are correct and try again later.");
            // }
        }
    };

    return (
        <div>
            <Container>
                <div>
                    <Header />
                    <div className="pb-8 mx-auto">
                        <div className="pb-4">
                            <h1 className="font-display text-2xl tracking-tight text-slate-900 sm:text-3xl">
                                Affiliate Section
                            </h1>
                            <p className="mt-2 mb-4 text-lg tracking-tight text-slate-700">
                                Search for an affiliate by their Affiliate Name
                            </p>
                        </div>
                        <div className="flex flex-wrap justify-between items-center">
                            <div>
                                <label htmlFor="affiliateCode" className="mb-3 block text-sm font-medium text-gray-700">
                                    Affiliate's Name:
                                </label>
                                <input
                                    type="text"
                                    value={affiliateCode}
                                    onChange={(e) => setAffiliateCode(e.target.value)}
                                    placeholder="Enter Affiliate Name"
                                    className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                                />
                            </div>

                            {/* <div>
                                <label htmlFor="startDate" className="mb-3 block text-sm font-medium text-gray-700">
                                    Start Date:
                                    </label>
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                                />
                            </div>

                            <div>
                                <label htmlFor="endDate" className="mb-3 block text-sm font-medium text-gray-700">
                                    End Date:
                                </label>
                                <input
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                                />
                            </div> */}
                            <div>
                                <Button onClick={searchAnAffiliate}>Search Affiliates</Button>
                            </div>
                        </div>

                        {error && (
                            <div className="mb-4 p-4 text-red-700 bg-red-100 border border-red-400 rounded">
                                {error}
                            </div>
                        )}

                        {searchExecuted && tableData.length === 0 && !error && (
                            <div>
                                <p>No results available for the selected criteria.</p>
                            </div>
                        )}

                        <div>
                            {tableData.length > 0 && (
                                <div>
                                    <table className="dataTable">
                                        <thead>
                                            <tr>
                                                <th>Affiliate Name</th>
                                                <th>Platform</th>
                                                <th>Sandbox</th>
                                                <th>Product Id</th>
                                                <th>Amount USD</th>
                                                <th>Currency of original purchase</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableData.map((row, index) => (
                                                <tr key={index}>
                                                    <td>{row.applicationUsername}</td>
                                                    {row.purchases && Object.values(row.purchases).map((purchase, key) => (
                                                        <React.Fragment key={key}>
                                                            <td>{purchase.platform}</td>
                                                            <td>{purchase.sandbox ? "Yes" : "No"}</td>
                                                            <td>{purchase.productId}</td>
                                                            <td>{purchase.amountUSD !== null ? purchase.amountUSD : "Not Available - Perhaps its in sandbox"}</td>
                                                            <td>{purchase.currency !== null ? purchase.currency : "Not Available"}</td>
                                                        </React.Fragment>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr className="totalRow">
                                                <td>Total</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>{/* Calculate total amount USD */}</td>
                                                <td></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    <br />
                                    <div>
                                        {/* <label htmlFor="affiliatePercentage">TODO: Affiliate's Purchase Percentage:</label> */}
                                        {/* <input type="number" id="affiliatePercentage" value={affiliatePercentage} onChange={(e) => setAffiliatePercentage(e.target.value)} step="0.01" placeholder="Enter percentage" /> */}
                                        {/* <button onClick={calculateTotalPayout}>Calculate Total Payout</button> */}
                                        {/* {affiliatePercentage && <p>Total Payout: {totalPayout}</p>} */}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Container>
            <Footer />
        </div>
    );
};

export default Affiliate;
