import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ApiContext } from '../../contexts/ApiContext';
import FetchUserAndFirebaseDocsOnAuth from '../../components/fetchFirebaseDocsOnAuth';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { getAuth } from 'firebase/auth';
import { Button } from '../../components/Button';
import * as Sentry from "@sentry/react";
import { trackPageView } from '../../util/analyticsService.js';
import SubscriptionSwitcher from './SubscriptionSwitcher';

// Make sure to call `loadStripe` outside of a component’s render to avoid recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);



const Billing = () => {
    const {
        subscriptionStatus,
        priceId,
        packageName,
        stripePurchaserEmail,
        companyId
    } = useContext(ApiContext);
    const user = FetchUserAndFirebaseDocsOnAuth();
    const auth = getAuth();
    const [manualEmail, setManualEmail] = useState('');
    const [noEmailFound, setNoEmailFound] = useState(false);
    const [clientSecret, setClientSecret] = useState(null);
    const [current_period_end, set_current_period_end]=useState(null);
    const [current_period_start, set_current_period_start]=useState(null);
    const [latest_invoice, set_latest_invoice] = useState(null);
    const [invoice_pdf, set_invoice_pdf] = useState(null);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        trackPageView('/billing.js');
    }, []);

    const handleUpdateEmail = () => {
        setError(false);
        setErrorMessage('');

        if (manualEmail === '') {
            alert('Please enter an email address.');
            return;
        }
    
        if (!manualEmail.includes('@') || !manualEmail.includes('.')) {
            alert('Please enter a valid email address.');
            return;
        }
    
        fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/updateStripePurchaserEmail`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ companyId, stripePurchaserEmail: manualEmail })
        })
        .then(async (res) => {
            if (!res.ok) {
                const errorResponse = await res.json().catch(() => ({})); // handle invalid JSON responses gracefully
                console.error('Error response from updateStripePurchaserEmail:', errorResponse.error);
                setError(true);
                setErrorMessage(errorResponse.error);
                throw new Error(`HTTP ${res.status} - ${errorResponse.message || 'Unknown error'}`);
            }
            return res.json();
        })
        .then(data => {
            fetchSubscription(); // only call if successful
        })
        .catch(error => {
            console.error('Failed to update email:', error);
        });
    };

    const fetchClientSecret = useCallback(() => {
        return fetch(`${process.env.REACT_APP_API_URL}/create-checkout-session-billing`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                priceId,
                email: auth.currentUser?.email,
            })
        })
            .then((res) => res.json())
            .then((data) => data.clientSecret);
    }, [priceId, auth.currentUser.email]);

    useEffect(() => {
        if (priceId && subscriptionStatus && auth.currentUser) {
            fetchClientSecret().then(secret => {
                setClientSecret(secret);
            });
        }
    }, [priceId, subscriptionStatus, fetchClientSecret, auth.currentUser]);

    useEffect(()=>{
        fetchSubscription();
    }, [auth.currentUser?.email, stripePurchaserEmail])

    const fetchSubscription = () => {
        
        if (auth.currentUser && stripePurchaserEmail) {
            fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/getInitialPayment`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: stripePurchaserEmail })
            }).then((res) => res.json()).then(data => {
                
                if (data?.status === 'Email not found') {
                    setNoEmailFound(true);
                    Sentry.captureException("Billing error - SubscriptionId not available (email not found)");
                    return;
                }
                setNoEmailFound(false);
                fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/retrieveSubscription`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ subscriptionId: "sub_1QRtUsRuLSxnvF115062wTOG" })
                }).then((res) => res.json()).then(data => {
                    if (data.subscription === null || data.subscription === undefined) {
                        console.error("Billing error - SubscriptionId not available (subscription not found)");
                        return;
                    }

                    const {current_period_end,current_period_start,latest_invoice} = data?.subscription;
                    
                    set_current_period_end(current_period_end);
                    set_current_period_start(current_period_start);
                    set_latest_invoice(latest_invoice);

                    // get invoice info
                    fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/get-invoice`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ invoiceId: latest_invoice })
                    }).then((res) => res.json()).then(data => {
                        const { invoice_pdf } = data.invoice;
                        set_invoice_pdf(invoice_pdf);
                    });
                })
            })
        }
    }

    const options = { clientSecret };

    const handleDownload = async()=>{
        window.location.href=invoice_pdf;
    };

    const [isCustomerId, setCustomerId] = useState(null);
    const [url, setUrl] = useState(null);
    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/getInitialPayment`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: stripePurchaserEmail })
      }).then((res) => res.json()).then(data => {
        setCustomerId(data?.data?.customerId)
      })
    }, [stripePurchaserEmail])

    const handleManage = async() => {
      await fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/manage-subscription`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ customerId: isCustomerId })
      }).then((res) => res.json()).then(data => {
        window.location.href=data.url;
      })
    }

    return (
        <div className="">
        {
            (!priceId || subscriptionStatus == null || noEmailFound ) ? (
                // No Subscription Found
                <div>
                    <h1 className="font-display text-2xl tracking-tight text-slate-900 sm:text-3xl">
                        No Subscription Found
                    </h1>
                
                    <p className="mt-2 mb-4 text-lg tracking-tight text-slate-700">
                        We couldn't find an active subscription linked to your current email.
                    </p>
                
                    {/* Option 1 - Already have a subscription (wrong email) */}
                    <div className="border p-4 rounded mb-6">
                        <h2 className="font-semibold text-lg">I Already Have a Subscription</h2>
                        <p className="mt-2 text-gray-600">
                            If you used a different email when purchasing your subscription, please enter it below and we'll try to find your subscription.
                        </p>
                        <input
                            type="email"
                            placeholder={stripePurchaserEmail}
                            value={manualEmail}
                            onChange={(e) => setManualEmail(e.target.value)}
                            className="border rounded p-2 w-full mt-2 mb-2"
                        />
                        {error && <p className='text-red-500'>{errorMessage}</p>}

                        <button
                            onClick={handleUpdateEmail}
                            className="mt-2 bg-blue-500 text-white px-4 py-2 rounded"
                        >
                            Find My Subscription
                        </button>
                    </div>
                
                    {/* Option 2 - Don’t have a subscription yet */}
                    <div className="border p-4 rounded">
                        <h2 className="font-semibold text-lg">I Don't Have a Subscription Yet</h2>
                        <p className="mt-2 text-gray-600">
                            No problem! You can explore all available packages and get started below.
                        </p>
                        <a
                            href="https://insertaffiliate.com/#pricing"
                            className="mt-2 inline-block bg-purple-500 text-white px-4 py-2 rounded"
                        >
                            View Packages & Subscribe
                        </a>
                    </div>
                </div>
            
            ) : (
                priceId !== null && (subscriptionStatus === "true" || subscriptionStatus) && subscriptionStatus !== null ? (
                    // Manage Billing - Active Subscription
                    <>
                        <SubscriptionSwitcher 
                            currentSubscriptionId={"sub_1Qw8mkRuLSxnvF11CmG0Mpb6"}
                            currentPriceId={priceId}
                            stripePurchaserEmail={stripePurchaserEmail}
                            companyId={companyId}
                            current_period_end={current_period_end}
                        />
                        {isCustomerId && (
                            <div className='mt-8 mx-auto pb-4'>
                                <Button onClick={handleManage} className="bg-red-400 mx-auto mt-4 pt-2">
                                    Manage Billing / Cancel
                                </Button>
                            </div>
                        )}
                    </>
                ) : (
                    // Billing Page - Inactive Subscription
                    <div>
                        <h1 className="font-display text-2xl tracking-tight text-slate-900 sm:text-3xl">
                            Billing Page
                        </h1>
                        <p className="mt-2 mb-4 text-lg tracking-tight text-slate-700">
                            Your subscription status is Inactive -- {subscriptionStatus}
                        </p>
                        <p>
                            You can subscribe again to your previous package, {packageName}.
                        </p>
                        <div className='p-6'>
                            <div id="checkout">
                                {clientSecret && (
                                    <EmbeddedCheckoutProvider
                                        stripe={stripePromise}
                                        options={options}
                                    >
                                        <EmbeddedCheckout />
                                    </EmbeddedCheckoutProvider>
                                )}
                            </div>
                        </div>
                    </div>
                )
            )
        }
    </div>
    );
};

export default Billing;