// src/util/analyticsService.js
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase';

// Generic event tracker
export const trackEvent = (eventName, params = {}) => {
    logEvent(analytics, eventName, params);
};

// Specific helpers for common events

export const trackPageView = (path) => {
    trackEvent('page_view', { page_path: path });
};

export const trackCompanySearchedAfifliate = (affiliateId) => {
    trackEvent('affiliate_created', { affiliate_id: affiliateId });
};


export const trackSettingsUpdated = (userId, changes) => {
    trackEvent('settings_updated', { user_id: userId, changes });
};
