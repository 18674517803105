import { useState, useEffect, useContext } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, getDoc, updateDoc, doc } from 'firebase/firestore'; 
import { db } from '../firebase'; // Make sure to import your Firebase configuration file
import { ApiContext } from '../contexts/ApiContext';
import * as Sentry from "@sentry/react";
import { set } from 'react-ga';

const FetchUserAndFirebaseDocsOnAuth = () => {
    const [user, setUser] = useState(null);
    const {
        setIapticAppName,
        setIapticSecretKey,
        setIapticWebhookEnabled,
        setCompanyName,
        setRevenueCatAuthHeader,
        setAppStoreNotificationUrl,
        setGooglePlayStoreNotificationUrl,
        setSelectedVerificationMethod,
        setSelectedAffiliatePaymentType,
        setCompanyId,
        setCompanyContactEmail,
        setStripePurchaserEmail,
        setDeepLink,
        setAffiliatePaymentPercentage,
        setAffiliateName,
        priceId,
        setPriceId,
        packageName,
        setPackageName,
        setSubscriptionStatus,
        setCompanyAffiliatePaymentPercentage,
        setCompanyOfferCouponCodes,
        setCompanyUnassignedDeepLinks
    } = useContext(ApiContext)

    useEffect(() => {
        const auth = getAuth();
        // Set up the authentication state listener
        const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
            setUser(authUser);
           
            if (auth.currentUser) {
                
                auth.currentUser.getIdTokenResult()
                .then(async (idTokenResult) => {
                    
                    if (idTokenResult.claims.affiliate) {
                        
                        // Assuming your Firestore collection is named 'Companies'
                        const companiesRef = collection(db, 'Companies');
                        
                        try {
                            const docSnap = await getDoc(doc(companiesRef, idTokenResult.claims.companyId));
                            
                            // ####### TODO: Need to allow access ########
                            // Problem: My allow based off admins would work in the same way (rules from firebase) but "in" doesnt work - comparing to admin[0]
                            if (docSnap.exists()) {
                                setCompanyId(idTokenResult.claims.companyId);
                                setCompanyName(docSnap.data().companyName);
                                setSelectedVerificationMethod(docSnap.data().selectedVerificationMethod);
                                setSelectedAffiliatePaymentType(docSnap.data().selectedAffiliatePaymentType);
                                setCompanyContactEmail(docSnap.data().companyContactEmail);
                                setCompanyAffiliatePaymentPercentage(docSnap.data().companyAffiliatePaymentPercentage);
                                setSubscriptionStatus(docSnap.data().subscriptionStatus);
                            }

                            const userAffiliateRef = doc(companiesRef, idTokenResult.claims.companyId, 'Affiliates', auth.currentUser.email);

                            const affiliateRef = await getDoc(userAffiliateRef);

                            if (affiliateRef.exists()) {
                                setAffiliatePaymentPercentage(affiliateRef.data().affiliatePaymentPercentage);
                                setDeepLink(affiliateRef.data().deeplinkurl);
                                setAffiliateName(affiliateRef.data().affiliatename);
                            }
                        } catch (error) {
                            Sentry.captureException("Error fetching document FetchUserAndFirebaseDocsOnAuth:", error);
                        }
                    } else {
                        // Assuming your Firestore collection is named 'Companies'
                        const companiesRef = collection(db, 'Companies');
                        // Fetch the document with the user's UID
                        const credentialsPath = `Private/Credentials`;

                        try {
                            const credentialsRef = doc(companiesRef, `${auth.currentUser.uid}/${credentialsPath}`);
                        
                            const credSnap = await getDoc(credentialsRef);
                            const docSnap = await getDoc(doc(companiesRef, auth.currentUser.uid));

                            // If the document exists, update the state with the value
                            if (credSnap.exists()) {
                                setIapticAppName(credSnap.data().iapticAppName);
                                setIapticSecretKey(credSnap.data().iapticSecretKey);
                            }

                            if (docSnap.exists()) {
                                setCompanyName(docSnap.data().companyName);
                                setSelectedVerificationMethod(docSnap.data().selectedVerificationMethod);
                                setSelectedAffiliatePaymentType(docSnap.data().selectedAffiliatePaymentType);
                                setRevenueCatAuthHeader(docSnap.data().revenueCatAuthHeader);
                                setAppStoreNotificationUrl(docSnap.data().appStoreNotificationUrl);
                                setGooglePlayStoreNotificationUrl(docSnap.data().googlePlayStoreNotificationUrl);
                                setIapticWebhookEnabled(docSnap.data().iapticWebhookEnabled);
                                setCompanyId(docSnap.id);
                                setCompanyContactEmail(docSnap.data().companyContactEmail);
                                setStripePurchaserEmail(docSnap.data().stripePurchaserEmail);
                                setPackageName(docSnap.data().packageName);
                                setSubscriptionStatus(docSnap.data().subscriptionStatus);
                                setPriceId(docSnap.data().priceId);
                                setPackageName(docSnap.data().packageName);
                                setCompanyOfferCouponCodes(docSnap.data().companyOfferCouponCodes);
                                setCompanyUnassignedDeepLinks(docSnap.data().companyUnassignedDeepLinks);
                                setCompanyAffiliatePaymentPercentage(docSnap.data().companyAffiliatePaymentPercentage);
                            }
                        } catch (error) {
                            Sentry.captureException("Error fetching document FetchUserAndFirebaseDocsOnAuth:", error);
                        }
                    }
                })
            }
        });

        // Clean up the listener on component unmount
        return () => unsubscribe();
    }, []);

    return user;
};

export default FetchUserAndFirebaseDocsOnAuth;
