// ApiContext.js
import React, { createContext, useState } from 'react';

const ApiContext = createContext();

const ApiProvider = ({ children }) => {
  const [apiUrl, setApiUrl] = useState(
    `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`
  );
  const [deepLink, setDeepLink] = useState('');
  const [affiliateName, setAffiliateName] = useState('');
  const [iapticAppName, setIapticAppName] = useState('');
  const [iapticSecretKey, setIapticSecretKey] = useState('');
  const [iapticWebhookEnabled, setIapticWebhookEnabled] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [revenueCatAuthHeader, setRevenueCatAuthHeader] = useState('');
  const [appStoreNotificationUrl, setAppStoreNotificationUrl] = useState('');
  const [googlePlayStoreNotificationUrl, setGooglePlayStoreNotificationUrl] = useState('');
  const [selectedVerificationMethod, setSelectedVerificationMethod] = useState('');
  const [selectedAffiliatePaymentType, setSelectedAffiliatePaymentType] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [stripePurchaserEmail, setStripePurchaserEmail] = useState('');
  const [companyContactEmail, setCompanyContactEmail] = useState('');
  const [affiliatePaymentPercentage, setAffiliatePaymentPercentage] = useState('');
  const [companyAffiliatePaymentPercentage, setCompanyAffiliatePaymentPercentage] = useState('');
  const [companyOfferCouponCodes, setCompanyOfferCouponCodes] = useState([]);
  const [companyUnassignedDeepLinks, setCompanyUnassignedDeepLinks] = useState([]);
  const [branchApiEndpoint] = useState('https://api2.branch.io/v1/url');
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [priceId, setPriceId] = useState('');
  const [packageName, setPackageName] = useState('');

  const value = {
    apiUrl,
    setApiUrl,
    deepLink,
    setDeepLink,
    affiliateName,
    setAffiliateName,
    iapticAppName,
    setIapticAppName,
    iapticSecretKey,
    setIapticSecretKey,
    iapticWebhookEnabled,
    setIapticWebhookEnabled,
    companyName,
    revenueCatAuthHeader,
    setRevenueCatAuthHeader,
    appStoreNotificationUrl,
    setAppStoreNotificationUrl,
    googlePlayStoreNotificationUrl,
    setGooglePlayStoreNotificationUrl,
    selectedVerificationMethod,
    setSelectedVerificationMethod,
    selectedAffiliatePaymentType,
    setSelectedAffiliatePaymentType,
    affiliatePaymentPercentage,
    setAffiliatePaymentPercentage,
    companyAffiliatePaymentPercentage,
    setCompanyAffiliatePaymentPercentage,
    companyOfferCouponCodes,
    setCompanyOfferCouponCodes,
    companyUnassignedDeepLinks,
    setCompanyUnassignedDeepLinks,
    setCompanyName,
    companyId,
    setCompanyId,
    stripePurchaserEmail,
    setStripePurchaserEmail,
    companyContactEmail,
    setCompanyContactEmail,
    branchApiEndpoint,
    subscriptionStatus,
    setSubscriptionStatus,
    priceId,
    setPriceId,
    packageName,
    setPackageName,
  }

  return (
    <ApiContext.Provider
      value={
        value
      }
    >
      {children}
    </ApiContext.Provider>
  );
};

export { ApiContext, ApiProvider };
