import { signOut, getAuth } from "firebase/auth";
import React, { useEffect } from 'react';
import { Button } from '../../components/Button.js';
import { trackPageView } from "../../util/analyticsService.js";

const Home = () => {
  // Instantiate the auth service SDK
  const auth = getAuth();

  useEffect(() => {
    trackPageView('/resetPassword.js');
  }, []);

  const handleSignOut = () => {
    signOut(auth).then(() => {
      window.location.href = '/signIn';
    }).catch((error) => {
      console.error('Sign out error', error);
    });
  };

  return (
    <section className='home'>
      <div className='home__container'>
        <Button
          type="button"
          color="red"
          onClick={handleSignOut}
        >
          Sign Out
        </Button>   
      </div>
    </section>
  );
};

export default Home;