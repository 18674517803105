import { parse } from 'papaparse';
import React, { useState, useContext, useEffect } from 'react';
import { Container } from '../../components/Container.js';
import FetchUserAndFirebaseDocsOnAuth from '../../components/fetchFirebaseDocsOnAuth.js';
import { Button } from '../../components/Button.js';
import { doc, getDocs, getDoc, collection, setDoc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../../firebase.js';
import { ApiContext } from '../../contexts/ApiContext.js';
import { useNavigate } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import { BillingAlertModal } from '../../components/BillingAlertModal';
import { trackPageView } from '../../util/analyticsService.js';

const generateRandomCode = (length = 10) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
};

const CreateAffiliate = () => {
    const [newAffiliateName, setNewAffiliateName] = useState("");
    const [newAffiliateShortCode, setNewAffiliateShortCode] = useState(generateRandomCode);
    const [newAffiliateEmail, setNewAffiliateEmail] = useState("");
    const [selectedDeepLinkPlatform, setSelectedDeepLinkPlatform] = useState("branch");
    const [notification, setNotification] = useState('');
    const [unassignedDeepLinsCount, setUnassignedDeepLinksCount] = useState(0);
    const [warningNotification, setWarningNotification] = useState('');
    const [selectedUploadDeepLinkOption, setSelectedUploadDeepLinkOption] = useState('single');
    const [deepLinksText, setDeepLinksText] = useState('');
    const [startNumber, setStartNumber] = useState(1);
    const [selectedDeepLink, setSelectedDeepLink] = useState(null);
    const user = FetchUserAndFirebaseDocsOnAuth();
    const [passwordResetLink, setPasswordResetLink] = useState(null);
    const {
        subscriptionStatus,
        companyUnassignedDeepLinks,
        setCompanyUnassignedDeepLinks,
        companyId,
        companyName,
        companyAffiliatePaymentPercentage
    } = useContext(ApiContext);

    useEffect(() => {
        trackPageView('/createAffiliate.js');
    }, []);

    const [newAffiliatePaymentPercentage, setNewAffiliatePaymentPercentage] = useState(5);

    useEffect(() => {
    if (companyAffiliatePaymentPercentage !== undefined && companyAffiliatePaymentPercentage !== '' && companyAffiliatePaymentPercentage !== newAffiliatePaymentPercentage) {
        setNewAffiliatePaymentPercentage(companyAffiliatePaymentPercentage);
    }
    }, [companyAffiliatePaymentPercentage, newAffiliatePaymentPercentage]);

    const [ localCompanyUnassignedDeepLinks, setLocalCompanyUnassignedDeepLinks ] = useState(companyUnassignedDeepLinks);

    const navigate = useNavigate();

    useEffect(() => {
        if (user?.uid) {
            fetchUnassignedDeepLinksCount().then((count) => {
                setUnassignedDeepLinksCount(count);
            });
        }
    }, [user]);

    useEffect(() => {
        if (Array.isArray(companyUnassignedDeepLinks) && 
            companyUnassignedDeepLinks.length > 0 &&
            companyUnassignedDeepLinks !== '' &&
            companyUnassignedDeepLinks !== localCompanyUnassignedDeepLinks) {
            setLocalCompanyUnassignedDeepLinks(companyUnassignedDeepLinks.map(code => ({
                    value: code,
                    label: `${code}`
                }))
            );
        }
    }, [companyUnassignedDeepLinks]);
    
    const handleChange = (newValue, actionMeta) => {
        setSelectedDeepLink(newValue);
    };

    const handleCreate = (inputValue) => {
        const newOption = {
            value: inputValue,
            label: `${inputValue}`,
        };

        setLocalCompanyUnassignedDeepLinks((prevOptions) => [...prevOptions, newOption]);
        setSelectedDeepLink(newOption);
    };
    

    useEffect(() => {
        if (subscriptionStatus === false || subscriptionStatus === undefined) {
            navigate('/billing');
        }
    }, [subscriptionStatus, navigate]);

    const auth = getAuth();

    // Utility function to read the CSV file
    const readCsvFile = (csvFile) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                resolve(event.target.result);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsText(csvFile);
        });
    };

    // Function to find short URLs by marketing title
    const findShortUrlsByTitle = async (csvFile, pattern) => {
        try {
            const csvData = await readCsvFile(csvFile);
            const results = parse(csvData, { header: false });
            const data = results.data;
    
            // Identify the correct headers (assumed to be in the 6th row)
            const headersRowIndex = 6;
    
            // Extract relevant rows (after the header row)
            const relevantData = data.slice(headersRowIndex + 1);
    
            // Filter the rows based on the pattern and map to short URLs
            const matchingUrls = relevantData
                .filter(row => row[1] && row[1].includes(pattern))
                .map(row => row[2]);
    
            return matchingUrls;
        } catch (error) {
            console.error("Error processing CSV file: ", error);
            return [];
        }
    };

    const removeUrlFromUnassignedDeepLinks = async (urlToRemove) => {
        const companiesRef = collection(db, 'Companies');
        const userDocRef = doc(companiesRef, user.uid);
    
        try {
            // Fetch the current company unassigned deep links
            const userDocSnapshot = await getDoc(userDocRef);
            const currentUnassignedDeepLinks = userDocSnapshot.data().companyUnassignedDeepLinks || [];
    
            // Filter out the URL to be removed
            const updatedUnassignedDeepLinks = currentUnassignedDeepLinks.filter(url => url !== urlToRemove);
    
            // Update Firestore with the new array
            await updateDoc(userDocRef, {
                companyUnassignedDeepLinks: updatedUnassignedDeepLinks,
            });

        } catch (error) {
            console.error('Error removing URL from companyUnassignedDeepLinks:', error);
        }
    };    

    const processDeepLinks = async (inputText) => {
        if (!inputText.trim()) {
            setWarningNotification("Please enter at least one deep link.");
            return;
        }
    
        // Split by commas, trim whitespace, remove duplicates
        const deepLinksArray = [...new Set(
            inputText.split(",").map(link => link.trim()).filter(link => link !== "")
        )];
    
        if (deepLinksArray.length === 0) {
            setWarningNotification("No valid deep links found. Please check your input.");
            return;
        }
    
        console.log("deepLinksArray:", deepLinksArray);
        const uniqueUrls = await filterUniqueAndUnassignedUrls(deepLinksArray);

        console.log("uniqueUrls:", uniqueUrls);

        if (uniqueUrls.length > 0) {
            await updateAndSaveDeepLinks({ newCompanyUnassignedDeepLinks: uniqueUrls });
            // handleDeepLinksUpload(deepLinksArray);
            fetchUnassignedDeepLinksCount().then((count) => {
                setUnassignedDeepLinksCount(count);
            });
        } else {
            setWarningNotification(`No new deep links found in the input.`);
            setTimeout(() => setWarningNotification(''), 3000); // Auto-hide after 3 seconds
        }

       
    };

    const handleCsvUpload = async (csvFile) => {
        if (!csvFile) {
            console.error("No CSV file provided");
            return;
        }

        const pattern = "Insert Affiliate Link"; // Replace with the actual pattern you want to match

        const matchingUrls = await findShortUrlsByTitle(csvFile, pattern);

        // TODO: I only want to upload this if it's nto already within the companyUnassignedDeepLinks for this user in firebase.
        // I also need to check if the deep link is already in use by another affiliate
        // If it is, remove it befor ecalling updateAndSaveDeepLinks

        const uniqueUrls = await filterUniqueAndUnassignedUrls(matchingUrls);

        if (uniqueUrls.length > 0) {
            await updateAndSaveDeepLinks({ newCompanyUnassignedDeepLinks: uniqueUrls });
            fetchUnassignedDeepLinksCount().then((count) => {
                setUnassignedDeepLinksCount(count);
            });
        } else {
            setWarningNotification(`No new deep links found in the CSV file.`);
            setTimeout(() => setWarningNotification(''), 3000); // Auto-hide after 3 seconds
        }
    };

    const filterUniqueAndUnassignedUrls = async (urls) => {
        const companiesRef = collection(db, 'Companies');
        const userDocRef = doc(companiesRef, user.uid);
    
        // Fetch the current company unassigned deep links
        const userDocSnapshot = await getDoc(userDocRef);
        const currentUnassignedDeepLinks = userDocSnapshot.data().companyUnassignedDeepLinks || [];
    
        const affiliateCollectionRef = collection(userDocRef, 'Affiliates');
        const affiliateSnapshot = await getDocs(affiliateCollectionRef);
    
        // Get all deep links currently used by affiliates
        const affiliateDeepLinks = [];
        affiliateSnapshot.forEach(doc => {
            const affiliateData = doc.data();
            if (affiliateData.deeplinkurl) {
                affiliateDeepLinks.push(affiliateData.deeplinkurl);
            }
        });
    
        // Filter out URLs already in unassigned deep links or used by affiliates
        const uniqueUrls = urls.filter(url => 
            !currentUnassignedDeepLinks.includes(url) && !affiliateDeepLinks.includes(url)
        );
    
        return uniqueUrls;
    };
    

    const updateAndSaveDeepLinks = async ({ newCompanyUnassignedDeepLinks }) => {
        console.log("updateAndSaveDeepLinks");
    
        const companiesRef = collection(db, 'Companies');
        const userDocRef = doc(companiesRef, user.uid);
    
        try {
            // Fetch the current unassigned deep links from Firestore
            const userDocSnapshot = await getDoc(userDocRef);
            const currentUnassignedDeepLinks = userDocSnapshot.exists()
                ? userDocSnapshot.data().companyUnassignedDeepLinks || []
                : [];
    
            // Merge the existing and new deep links, ensuring no duplicates
            const updatedDeepLinks = [...new Set([...currentUnassignedDeepLinks, ...newCompanyUnassignedDeepLinks])];
    
            // Update Firestore with the combined list
            await updateDoc(userDocRef, { companyUnassignedDeepLinks: updatedDeepLinks });
    
            // Update state with the new list
            setCompanyUnassignedDeepLinks(updatedDeepLinks);
    
            setNotification(`Successfully added ${newCompanyUnassignedDeepLinks.length} new deep links.`);
            setTimeout(() => setNotification(''), 3000);
        } catch (error) {
            console.error('Error updating Firestore document:', error);
        }
    };
    

    const handleDownloadTemplate = async () => {
        if (!user.uid) {
            console.error("No company name provided");
            return;
        }

        try {
            // Await the response from fetchFirestoreAffiliatesCount
            let startNumber = await fetchFirestoreAffiliatesCount(user.uid);

            setStartNumber(startNumber);
    
            // Ensure startNumber is at least 1
            startNumber = Math.max(startNumber, 1);
    
            // Define the CSV content based on the user's data
            const numberOfLines = 100;
            let csvContent = "campaign,data\n";
    
            for (let i = startNumber; i < startNumber + numberOfLines; i++) {
                csvContent += `insert-affiliate,"{ ""$marketing_title"":""Insert Affiliate Link ${i}"", ""$og_description"":"""" }"\n`;
            }
    
            // Create a Blob from the CSV content
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.setAttribute("href", url);
            link.setAttribute("download", "template.csv");
    
            // Append the link to the document body and trigger the download
            document.body.appendChild(link);
            link.click();
    
            // Clean up and remove the link
            document.body.removeChild(link);
            URL.revokeObjectURL(url); // Release the URL object
        } catch (error) {
            console.error("Error handling download template: ", error);
        }
    };

    const fetchUnassignedDeepLinksCount = async () => {
        if (!user.uid) {
            console.error("No company name provided");
            return;
        }

        try {
            const companiesRef = collection(db, 'Companies');
            const userDocRef = doc(companiesRef, user.uid);
            const userDocSnapshot = await getDoc(userDocRef);
            const companyData = userDocSnapshot.data();
            const unassignedDeepLinks = companyData.companyUnassignedDeepLinks || [];
            return unassignedDeepLinks.length;
        } catch (error) {
            console.error("Error fetching unassigned deep links count: ", error);
        }
    };

    const fetchFirestoreAffiliatesCount = async (uid) => {
        if (!uid) {
            console.error("User UID is not provided");
            return;
        }

        try {
            const companiesRef = collection(db, 'Companies');
            const userDocRef = doc(companiesRef, uid);
            const affiliateCollectionRef = collection(userDocRef, 'Affiliates');

            const affiliateSnapshot = await getDocs(affiliateCollectionRef);
            const affiliatesCount = affiliateSnapshot.size; // This gives the count of documents

            return affiliatesCount + 1;
        } catch (error) {
            console.error("Error fetching affiliates count: ", error);
        }
    };


    const createFirebaseAffiliate = async () => {
        // TODO: Michael continue here. This user IS an affiliate - but you just have to check for it on login not on create as you're still logged in as your old user otherwise
        if (!user.uid) {
            console.error("No company name provided");
            return;
        }

        if (!selectedDeepLink) {
            setWarningNotification(`Please select a deep link.`);
            setTimeout(() => setWarningNotification(''), 3000); // Auto-hide after 3 seconds
            return;
        }

        if (newAffiliateShortCode.length !== 10) {
            setWarningNotification(`Please enter a 10 character short code. Yours was ${newAffiliateShortCode.length} characters.`);
            setTimeout(() => setWarningNotification(''), 3000); // Auto-hide after 3 seconds
            return;
        }

        removeUrlFromUnassignedDeepLinks(selectedDeepLink.value);

        // Prepare the affiliate data
        const affiliateData = {
            affiliatename: newAffiliateName,
            email: newAffiliateEmail,
            deeplinkurl: selectedDeepLink.value,
            affiliateShortCode: newAffiliateShortCode,
            affiliatePaymentPercentage: newAffiliatePaymentPercentage,
            affiliateOfferCode: "",
            createdAt: new Date().toISOString(),
            totalAffiliateHasNotBeenPaid: 0,
            transactionsAffiliateHasNotBeenPaidFor: [],
            totalAffiliateHasBeenPaid: 0,
            transactionsAffiliateHasBeenPaidFor: [],
        };

        const companiesRef = collection(db, 'Companies');
        const userDocRef = doc(db, 'Companies', user.uid);

        const affiliateRef = doc(collection(userDocRef, 'Affiliates'), newAffiliateEmail);

        try {
            await setDoc(affiliateRef, affiliateData, { merge: true });

            const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/createAffiliateUser`;
            const payload = {
                email: newAffiliateEmail,
                displayName: newAffiliateName,
                companyId: user.uid,
                companyName: companyName
            };

            try {
                const idToken = await user.getIdToken(); // Get Firebase Auth token

                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${idToken}` // Pass the token in the header
                    },
                    body: JSON.stringify(payload),
                });

                const data = await response.json();
                console.log("data:", data);
                if (!response.ok) {
                    throw new Error(data.error || 'Unknown error occurred');
                }

               // Handle and modify the password reset link
                try {

                    // Parse the original link and extract the oobCode
                    const parsedUrl = new URL(data.link); // Ensure variable naming clarity
                    const oobCode = parsedUrl.searchParams.get("oobCode");

                    if (!oobCode) {
                        throw new Error("oobCode is missing from the password reset link.");
                    }

                    // Construct the new custom link
                    const customLink = `https://app.insertaffiliate.com/affiliate-set-password?oobCode=${oobCode}`;

                    // Set the custom password reset link
                    setPasswordResetLink(customLink);
                } catch (error) {
                    console.error("Error processing password reset link:", error.message);
                }
            } catch (error) {
                setWarningNotification(`${error}`);
                setTimeout(() => setWarningNotification(''), 3000); // Auto-hide after 3 seconds
            }
        } catch (error) {
            console.error('Error creating affiliate:', error);
            setWarningNotification(`${error.message}`);
            setTimeout(() => setWarningNotification(''), 3000); // Auto-hide after 3 seconds
        }
    };

    return (
        <div>
            {notification && (
                <div className="bg-green-500 text-white p-2 rounded mb-4">
                    {notification}
                </div>
            )}

            {warningNotification && (
                <div className="bg-red-500 text-white p-2 rounded mb-4">
                    {warningNotification}
                </div>
            )}
            <Container>
                <div>
                    <div className="pb-8 mx-auto"  style={{ minHeight: '65vh' }}>
                        {passwordResetLink ? (
                            <div className="p-2.5 rounded-lg text-center">
                                <div className="pb-4">
                                    <h1 className="font-display text-2xl tracking-tight text-slate-900 sm:text-3xl">
                                        Affiliate successfully created
                                    </h1>
                                    <p className="mt-2 mb-4 text-lg tracking-tight text-slate-700">
                                    An onboarding email has been sent to your affiliate with the link below.
                                    If you’d like to share it directly, you can copy and paste this link:
                                    </p>
                                </div>
                                <div className="p-3 bg-gold-900 rounded-lg overflow-hidden">
                                    <a href={passwordResetLink} 
                                        target="_blank" 
                                        rel="noopener noreferrer" 
                                        className="overflow-hiddeninline-block text-black p-2 rounded-md text-sm leading-tight break-words"
                                    >
                                    {passwordResetLink}
                                    </a>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="pb-4">
                                    <BillingAlertModal />
                                    <h1 className="mb-2 font-display text-2xl tracking-tight text-slate-900 sm:text-3xl">
                                        Create an Affiliate
                                    </h1>
                                    {/* <p className="mt-2 mb-2 text-lg tracking-tight text-slate-700">
                                        Ready to create a new affiliate and track their sales?
                                    </p> */}
                                     <legend className="text-lg font-semibold text-gray-800">
                                        Select an Affiliate Creation Mode
                                    </legend>
                                    <div className="mt-2 flex space-x-4">
                                      
                                        <label 
                                            className={`flex flex-col p-3 border rounded cursor-pointer w-full sm:w-1/3 ${
                                                selectedUploadDeepLinkOption === 'single' 
                                                    ? 'border-blue-500 bg-blue-100' 
                                                    : 'border-gray-300 bg-gray-100 hover:bg-gray-200'
                                            }`}
                                        >
                                            <input
                                                type="radio"
                                                name="affiliateMode"
                                                value="single"
                                                checked={selectedUploadDeepLinkOption === 'single'}
                                                onChange={() => setSelectedUploadDeepLinkOption('single')}
                                                className="hidden"
                                            />
                                            <span className="text-gray-800 font-medium">Create Affiliate (Admin)</span>
                                            <span className="text-sm text-gray-600 mt-1">
                                                Manually create an affiliate and assign them a deep link.
                                            </span>
                                        </label>
                                        <label 
                                            className={`flex flex-col p-3 border rounded cursor-pointer w-full sm:w-1/3 ${
                                                selectedUploadDeepLinkOption === 'multiple' 
                                                    ? 'border-blue-500 bg-blue-100' 
                                                    : 'border-gray-300 bg-gray-100 hover:bg-gray-200'
                                            }`}
                                        >
                                            <input
                                                type="radio"
                                                name="affiliateMode"
                                                value="multiple"
                                                checked={selectedUploadDeepLinkOption === 'multiple'}
                                                onChange={() => setSelectedUploadDeepLinkOption('multiple')}
                                                className="hidden"
                                            />
                                            <span className="text-gray-800 font-medium">Enable Affiliate Self-Signup</span>
                                            <span className="text-sm text-gray-600 mt-1">
                                                Upload deep links to allow affiliates to sign up on their own.
                                            </span>
                                        </label>
                                    </div>
                                </div>
                               
                                <form onSubmit={(e) => { e.preventDefault() }} >

                                    <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-6">
                                        {selectedUploadDeepLinkOption === 'single' ? (
                                            <div>
                                                <h3 className="mb-2 font-display text-xl tracking-tight text-slate-900 sm:text-1xl">
                                                    New Affiliate Details
                                                </h3>
                                                <div>
                                                    <label
                                                        htmlFor="newAffiliateName"
                                                        className="mb-3 block text-sm font-medium text-gray-700"
                                                    >
                                                        Affiliate Name:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="newAffiliateName"
                                                        value={newAffiliateName}
                                                        onChange={(e) => setNewAffiliateName(e.target.value)}
                                                        className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                                                    />
                                                </div>
                                                <div>
                                                    <label
                                                        htmlFor="newAffiliateShortCode"
                                                        className="mt-6 block text-sm font-medium text-gray-700"
                                                    >
                                                        Affiliate Short Code (10 Characters):
                                                    </label>
                                                    <p className="text-sm text-gray-700 mb-3">Note - this cannot be changed after creation</p>
                                                    <input
                                                        type="text"
                                                        id="newAffiliateShortCode"
                                                        value={newAffiliateShortCode}
                                                        required
                                                        onChange={(e) => {
                                                            const sanitizedValue = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, ""); // Allow only uppercase letters and numbers
                                                            if (sanitizedValue.length <= 10) {
                                                                setNewAffiliateShortCode(sanitizedValue);
                                                            }
                                                        }}
                                                        className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                                                    />
                                                </div>
                                                <div>
                                                    <label
                                                        htmlFor="newAffiliatePaymentPercentage"
                                                        className="mt-6 block text-sm font-medium text-gray-700"
                                                    >
                                                        Affiliate Commission Percentage:
                                                    </label>
                                                    <input
                                                        type="number"
                                                        min="1"
                                                        max="99"
                                                        required
                                                        id="newAffiliatePaymentPercentage"
                                                        value={newAffiliatePaymentPercentage}
                                                        onChange={(e) => {
                                                            setNewAffiliatePaymentPercentage(e.target.value);
                                                        }}
                                                        className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                                                    />
                                                </div>
                                                <div>
                                                    <label
                                                        htmlFor="newAffiliateEmail"
                                                        className="mt-6 mb-2 block text-sm font-medium text-gray-700"
                                                    >
                                                        Affiliate Email:
                                                    </label>
                                                    <input
                                                        type="email"
                                                        id="newAffiliateEmail"
                                                        value={newAffiliateEmail}
                                                        onChange={(e) => setNewAffiliateEmail(e.target.value)}
                                                        className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                                                    />
                                                </div>
                                                <div className="relative">
                                                    <label
                                                        htmlFor="selectedDeepLink"
                                                        className="mt-6 mb-2 block text-sm font-medium text-gray-700"
                                                    >
                                                        Affiliate Deep Link:
                                                    </label>
                                                    <div className="flex items-center">
                                                        <CreatableSelect
                                                            id="localCompanyUnassignedDeepLinks"
                                                            isClearable
                                                            value={selectedDeepLink}
                                                            onChange={handleChange}
                                                            onCreateOption={handleCreate}
                                                            options={localCompanyUnassignedDeepLinks}
                                                            placeholder="Select existing or add a new deep link"
                                                            classNamePrefix="react-select"
                                                        />
                                                        <Button
                                                            className="ml-4"
                                                            aria-label="Help"
                                                            onClick={() => window.open('https://docs.insertaffiliate.com/create-affiliate', '_blank')}
                                                        >
                                                            Help
                                                        </Button>
                                                    </div>
                                                </div>
                                                <Button onClick={ createFirebaseAffiliate } className="mt-6">Create Affiliate</Button>
                                            </div>
                                        ) : (
                                            <div className="mb-4">
                                                <fieldset className="mb-4 border border-gray-300 rounded-lg p-4">
                                                    <legend className="text-lg font-semibold text-gray-800">
                                                        Currently Unassigned Deep Links
                                                    </legend>
                                                    <p className="mb-4">You have
                                                    <span className="font-bold"> {unassignedDeepLinsCount} unassigned deep links.</span></p>
                                                    <p className="mt-1">Affiliates can <a className="text-purple-600 hover:underline" href="https://app.insertaffiliate.com/signup/affiliate">sign up </a>using your company code: 
                                                    <span className="font-bold"> {companyId}. </span>
                                                        To test this process, please log out before signing up.
                                                    </p>
                                                    <p></p>
                                                    
                                                    <p className="mt-1">For more information, refer to our <a className="text-purple-600 hover:underline" href="https://docs.insertaffiliate.com/create-affiliate-affiliate-self-signup#4-affiliate-signup-process">our docs</a></p>
                                                </fieldset>
                                                {/* Step 1: Select Deep Linking Provider */}
                                                <fieldset className="border border-gray-300 rounded-lg p-4">
                                                    <legend className="text-lg font-semibold text-gray-800">
                                                        Select Your Deep Linking Platform
                                                    </legend>
                                            
                                                    <div className="flex flex-col sm:flex-row sm:space-x-4 mt-2">
                                                        {/* Branch.io Selection */}
                                                        <label 
                                                            className={`flex flex-col p-3 border rounded cursor-pointer w-full sm:w-1/2 ${
                                                                selectedDeepLinkPlatform === 'branch' 
                                                                    ? 'border-blue-500 bg-blue-100' 
                                                                    : 'border-gray-300 bg-gray-100 hover:bg-gray-200'
                                                            }`}
                                                        >
                                                            <input
                                                                type="radio"
                                                                name="deepLinkPlatform"
                                                                value="branch"
                                                                checked={selectedDeepLinkPlatform === 'branch'}
                                                                onChange={() => setSelectedDeepLinkPlatform('branch')}
                                                                className="hidden"
                                                            />
                                                            <span className="text-gray-800 font-medium">Branch.io</span>
                                                            <span className="text-sm text-gray-600 mt-1">
                                                                Use if your app integrates with Branch.io deep linking.
                                                            </span>
                                                        </label>
                                            
                                                        {/* Other Deep Linking Platform Selection */}
                                                        <label 
                                                            className={`flex flex-col p-3 border rounded cursor-pointer w-full sm:w-1/2 ${
                                                                selectedDeepLinkPlatform === 'other' 
                                                                    ? 'border-blue-500 bg-blue-100' 
                                                                    : 'border-gray-300 bg-gray-100 hover:bg-gray-200'
                                                            }`}
                                                        >
                                                            <input
                                                                type="radio"
                                                                name="deepLinkPlatform"
                                                                value="other"
                                                                checked={selectedDeepLinkPlatform === 'other'}
                                                                onChange={() => setSelectedDeepLinkPlatform('other')}
                                                                className="hidden"
                                                            />
                                                            <span className="text-gray-800 font-medium">Other Deep Linking Platform</span>
                                                            <span className="text-sm text-gray-600 mt-1">
                                                                Use if you're using another deep linking provider.
                                                            </span>
                                                        </label>
                                                    </div>
                                                </fieldset>

                                                {/* Step 2: Upload Deep Links (Conditional Rendering) */}
                                                {selectedDeepLinkPlatform && (
                                                    <fieldset className="mt-4 mb-4 border border-gray-300 rounded-lg p-4">
                                                        <legend className="text-lg font-semibold text-gray-800">
                                                            Upload Deep Links
                                                        </legend>

                                                        {/* CSV Upload Steps */}
                                                        {selectedDeepLinkPlatform === 'branch' ? (
                                                            <div>
                                                                <ol className="list-decimal list-inside text-gray-800">
                                                                    <li>
                                                                        Download your personal Deep Link CSV template:
                                                                        <button
                                                                            className="block mt-1 mb-2 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded hover:bg-blue-700"
                                                                            onClick={(e) => {
                                                                                e.preventDefault(); 
                                                                                handleDownloadTemplate(); 
                                                                            }}
                                                                        >
                                                                            Download
                                                                        </button>
                                                                    </li>
                                                                    <li>
                                                                        Upload the CSV file to create multiple deep links for future users as shown in our 
                                                                        <a
                                                                            href="https://docs.insertaffiliate.com/create-affiliate-affiliate-self-signup"
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            className="ml-1 text-purple-600 hover:underline"
                                                                        >
                                                                            user guide.
                                                                        </a>

                                                                    </li>
                                                                    <li>
                                                                        Export the created deep links to a CSV file (also shown in our
                                                                        <a
                                                                            href="https://docs.insertaffiliate.com/create-affiliate-affiliate-self-signup"
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            className="mx-1 text-purple-600 hover:underline"
                                                                        >
                                                                            user guide)
                                                                        </a>
                                                                        and upload the result below:
                                                                    </li>
                                                                </ol>
                                                                <div className="flex items-center mt-4">
                                                                    <input
                                                                        type="file"
                                                                        id="csvUpload"
                                                                        accept=".csv"
                                                                        onChange={(e) => {
                                                                            const file = e.target.files[0];
                                                                            if (file && file.type === "text/csv") {
                                                                                handleCsvUpload(file);
                                                                            } else {
                                                                                setWarningNotification(`Please upload a valid CSV file.`);
                                                                                setTimeout(() => setWarningNotification(''), 3000);
                                                                            }
                                                                        }}
                                                                        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                                                                    />
                                                                </div>
                                                            </div>
                                                        ): (
                                                            <div>

                                                                <p className="mb-2">
                                                                    Add your deep links below as a <span className="font-bold">comma-separated list</span>.
                                                                </p>

                                                                {/* Deep Links Input */}
                                                                <textarea
                                                                    id="deepLinksInput"
                                                                    placeholder="e.g. https://insert-affiliate.link/vYITSh, https://insert-affiliate.link/abc123"
                                                                    value={deepLinksText}
                                                                    onChange={(e) => setDeepLinksText(e.target.value)}
                                                                    className="mt-3 w-full h-24 border border-gray-300 rounded-lg p-2 text-gray-900 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 text-sm"
                                                                />

                                                                {/* Submit Button */}
                                                                <button
                                                                    onClick={() => processDeepLinks(deepLinksText)}
                                                                    className="mt-3 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded hover:bg-blue-700"
                                                                >
                                                                    Submit Deep Links
                                                                </button>
                                                            </div>

                                                        )}
                                                    </fieldset>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </form>
                            </div>
                        )}
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default CreateAffiliate;