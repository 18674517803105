import React, { useState, useEffect } from 'react';
import { collection, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const AffiliateIapticWebhookSalesTransactions = ({
    tableData,
    setTableData,
    affiliatePaymentPercentage,
    affiliateEmail,
    user,
    totalAffiliateHasBeenPaid,
    setTotalAffiliateHasBeenPaid,
    totalAffiliateHasNotBeenPaid,
    setTotalAffiliateHasNotBeenPaid,
}) => {
    const [selectedItems, setSelectedItems] = useState(new Set());
    const [initalised, setInitialised] = useState(false);

    const [paymentFilter, setPaymentFilter] = useState('unpaid'); // 'all', 'paid', 'unpaid'
    const [localStartDate, setLocalStartDate] = useState('');
    const [localEndDate, setLocalEndDate] = useState('');

    const uniqueTransactionIds = [...new Set(tableData.map((t) => t.id))];
    const [selectedTransactionIds, setSelectedTransactionIds] = useState([]); // Stores multiple Transaction IDs
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const uniqueProductIds = [...new Set(tableData.map((t) => t.product_id))];
    const [selectedProductIds, setSelectedProductIds] = useState([]); // Stores multiple Product IDs
    const [isProductIdDropdownOpen, setIsProductIdDropdownOpen] = useState(false);

    const [selectedCustomerIds, setSelectedCustomerIds] = useState([]); // Stores multiple Customer IDs
    const [isCustomerDropdownOpen, setIsCustomerDropdownOpen] = useState(false);
    const uniqueCustomerIds = [...new Set(tableData.map((t) => t.app_user_id))];

    const handleSelectCustomerId = (id) => {
        setSelectedCustomerIds((prev) =>
            prev.includes(id) ? prev.filter((t) => t !== id) : [...prev, id]
        );
    };

    const handleSelectProductId = (id) => {
        setSelectedProductIds((prev) =>
            prev.includes(id) ? prev.filter((t) => t !== id) : [...prev, id]
        );
    };

    const handleSelectTransactionId = (id) => {
        setSelectedTransactionIds((prev) =>
            prev.includes(id) ? prev.filter((t) => t !== id) : [...prev, id]
        );
    };

    const handleStartDateBlur = (e) => {
        setLocalStartDate(e.target.value);
    };
    
    const handleEndDateBlur = (e) => {
        const newEndDate = e.target.value;
        if (newEndDate && localStartDate && new Date(newEndDate) < new Date(localStartDate)) {
            setLocalEndDate(''); // Reset end date
        } else {
            setLocalEndDate(newEndDate);
        }
    };
    

    useEffect(() => {
        if (initalised) {
            updateFirebaseAffiliateTransactionsAffiliateHasBeenPaidFor({
                affiliateEmail,
                totalAffiliateHasBeenPaid,
                totalAffiliateHasNotBeenPaid
            });
        } else {
            setInitialised(true);
        }
    }, [affiliateEmail, totalAffiliateHasBeenPaid, totalAffiliateHasNotBeenPaid, initalised]);    

    const updateFirebaseAffiliateTransactionsAffiliateHasBeenPaidFor = async ( affiliate ) => {
        const companiesRef = collection(db, 'Companies');
        const userDocRef = doc(companiesRef, user.uid);

        const affiliateRef = doc(collection(userDocRef, 'Affiliates'), affiliate.affiliateEmail);

        try {
            await updateDoc(affiliateRef, {
                totalAffiliateHasBeenPaid: affiliate.totalAffiliateHasBeenPaid,
                totalAffiliateHasNotBeenPaid: affiliate.totalAffiliateHasNotBeenPaid
            });
        } catch (error) {
            console.error("Error updating affiliate:", error);
        }
    }

    const toggleIapticTransactionPaidStatus = async (id, isPaid, amount) => {
        const companiesRef = collection(db, 'Companies');
        const userDocRef = doc(companiesRef, user.uid);
        const transactionRef = doc(collection(doc(collection(userDocRef, 'Affiliates'), affiliateEmail), 'iapticTransactions'), id);

        await updateDoc(transactionRef, {
            affiliateHasBeenPaid: isPaid,
        });

        // Update `tableData` directly (if needed)
        setTableData((prevData) =>
            prevData.map((transaction) =>
                transaction.id === id ? { ...transaction, affiliateHasBeenPaid: isPaid } : transaction
            )
        );

        if (isPaid) {
            setTotalAffiliateHasBeenPaid(prevTotal => prevTotal + amount);
            setTotalAffiliateHasNotBeenPaid(prevTotal => prevTotal - amount);
        } else {
            setTotalAffiliateHasBeenPaid(prevTotal => prevTotal - amount);
            setTotalAffiliateHasNotBeenPaid(prevTotal => prevTotal + amount);
        }
    };

    const handleSelectItem = (iapticId) => {
        setSelectedItems(prevState => {
            const newState = new Set(prevState);
            if (newState.has(iapticId)) {
                newState.delete(iapticId);
            } else {
                newState.add(iapticId);
            }
            return newState;
        });
    };

    const handleSelectAll = () => {
        const allItems = new Set(tableData.map((transaction) => transaction.id));

        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.size === allItems.size) {
                return new Set(); // Clear all selections
            } else {
                return allItems; // Select all items
            }
        });
    };

    const markAllAsPaid = async () => {
        const companiesRef = collection(db, 'Companies');
        const userDocRef = doc(companiesRef, user.uid);
    
        // Loop through all selected items
        const promises = Array.from(selectedItems).map(async (transactionId) => {
            const transactionRef = doc(
                collection(doc(collection(userDocRef, 'Affiliates'), affiliateEmail), 'iapticTransactions'),
                transactionId
            );

            const transaction = tableData.find((t) => t.id === transactionId);
            if (!transaction) return; // Skip if transaction not found in tableData

            const amountToAdd = (transaction.price * affiliatePaymentPercentage) / 100;

            // Update in Firestore
            await updateDoc(transactionRef, { affiliateHasBeenPaid: true });
    
            // Update local state
            setTotalAffiliateHasBeenPaid((prev) => prev + amountToAdd);
            setTotalAffiliateHasNotBeenPaid((prev) => prev - amountToAdd);
    
            // Optimistically update the tableData
            setTableData((prevData) =>
                prevData.map((t) =>
                    t.id === transactionId ? { ...t, affiliateHasBeenPaid: true } : t
                )
            );
        });

        // Wait for all updates to finish
        await Promise.all(promises);

        // Clear selected items after marking them as paid
        setSelectedItems(new Set());
    };

    const filteredTransactions = tableData.filter((transaction) => {
        // Convert purchase date from milliseconds to YYYY-MM-DD format
        const purchaseDate = new Date(transaction.purchased_date).toISOString().split("T")[0];

        // Ensure start and end date filters are applied correctly
        const isAfterStartDate = !localStartDate || purchaseDate >= localStartDate;
        const isBeforeEndDate = !localEndDate || purchaseDate <= localEndDate;

        // Filter by Transaction IDs (if any are selected)
        const isMatchingTransactionId =
            selectedTransactionIds.length === 0 || selectedTransactionIds.includes(transaction.id);

        // Filter by Product Ids (if any are selected)
        const isMatchingProductId =
            selectedProductIds.length === 0 || selectedProductIds.includes(transaction.product_id);
        
        // Filter by Customer Ids (if any are selected)
        const isMatchingCustomerId =
            selectedCustomerIds.length === 0 || selectedCustomerIds.includes(transaction.app_user_id);

        // Payment filter logic
        const isMatchingPaymentFilter =
            paymentFilter === "all" ||
            (paymentFilter === "paid" && transaction.affiliateHasBeenPaid) ||
            (paymentFilter === "unpaid" && !transaction.affiliateHasBeenPaid);

        return isAfterStartDate && isBeforeEndDate && isMatchingTransactionId && isMatchingCustomerId && isMatchingProductId && isMatchingPaymentFilter;
    })

    const filteredTableData = filteredTransactions.map((transaction, index) => (
        <tr key={index}>
            <td className="text-center">
                <input
                    type="checkbox"
                    checked={selectedItems.has(transaction.id)}
                    onChange={() => handleSelectItem(transaction.id)}
                />
            </td>
            <td>{getAffiliateNameFromUrlString(transaction.app_user_id)}</td>
            <td>{transaction.id}</td>
            <td>{transaction.iaptic_id}</td>
            <td>{transaction.type.reason}</td>
            <td>{transaction.product_id}</td>
            <td>{formatDateOutput(transaction.purchased_date)}</td>
            <td>{formatDateOutput(transaction.expiration_date)}</td>
            <td>{transaction.priceInUSD}</td>
            <td>
                {((transaction.priceInUSD * affiliatePaymentPercentage) / 100).toFixed(2)}

            </td>
            <td
                className={` ${
                    transaction.affiliateHasBeenPaid ? 'text-green-600' : 'text-red-600'
                }`}
            >
                {transaction.affiliateHasBeenPaid ? "Paid" : "Unpaid"}
            </td>
            <td className="whitespace-nowrap">
                {transaction.affiliateHasBeenPaid ? (
                    <button onClick={() =>
                        toggleIapticTransactionPaidStatus(
                            transaction.id,
                            !transaction.affiliateHasBeenPaid,
                            ((transaction.priceInUSD * affiliatePaymentPercentage) / 100)
                        )
                    } className="text-red-600">
                        Mark as Unpaid
                    </button>
                ) : (
                    <button onClick={() => 
                        toggleIapticTransactionPaidStatus(
                            transaction.id,
                            !transaction.affiliateHasBeenPaid,
                            ((transaction.priceInUSD * affiliatePaymentPercentage) / 100)
                        )} className="text-purple-600">
                        Mark as Paid
                    </button>
                )}
            </td>
        </tr>
    ));


    return (
        <div>
            <h2 className="mt-4 mb-4 font-display text-xl tracking-tight text-slate-900 sm:text-2xl">
                Affiliate's Transactions
            </h2>
            <div className="flex flex-wrap items-center gap-4 sm:gap-6 w-full">
                <div className="flex flex-col align-center ">
                    <label className="font-bold">Status</label>
                    <select
                        id="paymentFilter"
                        value={paymentFilter}
                        onChange={(e) => setPaymentFilter(e.target.value)}
                        className="px-10 py-2 border rounded-md"
                    >
                        <option value="all">All</option>
                        <option value="paid">Paid</option>
                        <option value="unpaid">Unpaid</option>
                    </select>
                </div>
                <div className="flex-1">
                    <label className="font-bold">Purchase Start Date</label>
                    <DateInputWithPlaceholder
                        id="startDate"
                        value={localStartDate}
                        onChange={(e) => setLocalStartDate(e.target.value)}
                        onBlur={handleStartDateBlur}
                        placeholder="Start Date"
                    />
                </div>
                <div className="flex-1">
                    <label className="font-bold">Purchase End Date</label>
                    <DateInputWithPlaceholder
                        id="endDate"
                        value={localEndDate}
                        onChange={(e) => setLocalEndDate(e.target.value)}
                        onBlur={handleEndDateBlur}
                        placeholder="End Date"
                        min={localStartDate}
                    />
                </div>
                <div className="flex-1">
                    <label className="font-bold">Customer ID</label>
                    <div
                        className="rounded-md flex items-center gap-2 h-[42.5px] max-w-full overflow-x-auto overflow-y-hidden bg-gray-50 p-2 border border-gray-300"
                        style={{ width: "100%", whiteSpace: "nowrap", overflowX: "auto", display: "flex" }}
                        onClick={() => setIsCustomerDropdownOpen(!isCustomerDropdownOpen)}
                
                    >
                        <span>{selectedCustomerIds.length || 0} filter(s) applied</span>
                        {/* Dropdown Menu */}
                        {isCustomerDropdownOpen && (
                            <div className="absolute z-10 mt-1 bg-white border border-gray-300 rounded-md shadow-md max-h-48 overflow-y-auto">
                                {uniqueCustomerIds.map((id) => (
                                    <div
                                        key={id}
                                        onClick={() => handleSelectCustomerId(id)}
                                        className={`p-2 cursor-pointer hover:bg-gray-100 flex justify-between ${
                                            selectedCustomerIds.includes(id) ? "bg-blue-100" : ""
                                        }`}
                                    >
                                        {id}
                                        {selectedCustomerIds.includes(id) && <span className="text-blue-600">✔</span>}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex-1">
                    <label className="font-bold">Transaction ID</label>
                    <div
                        className="rounded-md flex items-center gap-2 h-[42.5px] max-w-full overflow-x-auto overflow-y-hidden bg-gray-50 p-2 border border-gray-300"
                        style={{ width: "100%", whiteSpace: "nowrap", overflowX: "auto", display: "flex" }}
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                
                    >
                        {/* Selected Transaction ID Bubbles */}
                        <span>{selectedTransactionIds.length || 0} filter(s) applied</span>
                        {isDropdownOpen && (
                            <div className="absolute z-10 mt-1 bg-white border border-gray-300 rounded-md shadow-md max-h-48 overflow-y-auto">
                                {uniqueTransactionIds.map((id) => (
                                    <div
                                        key={id}
                                        onClick={() => handleSelectTransactionId(id)}
                                        className={`p-2 cursor-pointer hover:bg-gray-100 flex justify-between ${
                                            selectedTransactionIds.includes(id) ? "bg-blue-100" : ""
                                        }`}
                                    >
                                        {id}
                                        {selectedTransactionIds.includes(id) && <span className="text-blue-600">✔</span>}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex-1">
                    <label className="font-bold">Product ID</label>
                    <div
                        className="rounded-md flex items-center gap-2 h-[42.5px] max-w-full overflow-x-auto overflow-y-hidden bg-gray-50 p-2 border border-gray-300"
                        style={{ width: "100%", whiteSpace: "nowrap", overflowX: "auto", display: "flex" }}
                        onClick={() => setIsProductIdDropdownOpen(!isProductIdDropdownOpen)}
                
                    >
                        {/* Selected Transaction ID Bubbles */}
                        <span>{selectedProductIds.length || 0} filter(s) applied</span>

                        {/* Dropdown Menu */}
                        {isProductIdDropdownOpen && (
                            <div className="absolute z-10 mt-1 bg-white border border-gray-300 rounded-md shadow-md max-h-48 overflow-y-auto">
                                {uniqueProductIds.map((id) => (
                                    <div
                                        key={id}
                                        onClick={() => handleSelectProductId(id)}
                                        className={`p-2 cursor-pointer hover:bg-gray-100 flex justify-between ${
                                            selectedProductIds.includes(id) ? "bg-blue-100" : ""
                                        }`}
                                    >
                                        {id}
                                        {selectedProductIds.includes(id) && <span className="text-blue-600">✔</span>}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <table className="dataTable">
                <thead>
                    <tr>
                        <th className="text-center">
                            <input
                                type="checkbox"
                                checked={selectedItems.size === tableData.length && tableData.length > 0}
                                onChange={handleSelectAll}
                            />
                        </th>
                        <th>Customer UUID</th>
                        <th>Transaction ID</th>
                        <th>Iaptic ID</th>
                        <th>Transaction Type</th>
                        <th>Product ID</th>
                        <th>Original Purchase Date</th>
                        <th>Expiration Date</th>
                        <th>Total Earnings ($)</th>
                        {paymentFilter === 'unpaid' && (
                            <th>Amount Owed to Affiliate ($)</th>
                        )}
                        {paymentFilter === 'paid' && (
                            <th>Amount Paid to Affiliate ($)</th>
                        )}
                        {paymentFilter === 'all' && (
                            <th>Amount of Transaction Assigned to Affiliate</th>
                        )}
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredTableData}
                    <tr className="totalRow">
                    
                        <td colSpan="15">
                            <button onClick={markAllAsPaid} className="text-purple-600">
                                Mark Selected As Paid
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr className="totalRow">
                    <td colSpan="2">Total</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            {filteredTransactions
                                .reduce((total, transaction) => {
                                    return total + transaction.priceInUSD;
                                }, 0)
                                .toFixed(2)}
                        </td>
                        <td>
                            {filteredTransactions
                                .reduce((total, transaction) => {
                                    return total + (transaction.priceInUSD * (affiliatePaymentPercentage / 100));
                                }, 0)
                                .toFixed(2)}
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

const DateInputWithPlaceholder = ({ id, value, onChange, onBlur, placeholder, min }) => {
    const [isFocused, setIsFocused] = useState(false);
  
    const handleFocus = (e) => {
      setIsFocused(true);
      e.target.type = 'date';
      e.target.placeholder = '';
    };
  
    const handleBlur = (e) => {
      if (!e.target.value) {
        e.target.type = 'text';
        e.target.placeholder = placeholder;
      }
      setIsFocused(false);
      onBlur && onBlur(e);
    };
  
    return (
      <div className="relative">
        <input
          type="text"
          id={id}
          value={value}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={isFocused ? '' : placeholder}
          className="block w-full appearance-none rounded-md border border-gray-300 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-500 sm:text-sm"
          style={{ width: '100%', minWidth: '180px' }}
          min={min}
        />
      </div>
    );
};

const formatDateOutput = (date) => {
    if (!date) {
        return "N/A";
    }
    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    }).format(new Date(date));
};

const getAffiliateNameFromUrlString = (url) => {
    if (!url) return 'N/A';
    const delimiter = url.includes('-') ? '-' : '/';

    if (url.includes(delimiter)) {
        const parts = url.split(delimiter);
        return parts[parts.length - 1];
    }

    return url;
};

export default AffiliateIapticWebhookSalesTransactions;
