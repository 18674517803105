// AffiliateSelfSearchSalesTransactions.js
import React from 'react';

const formatDateOutput = (date) => {
    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    }).format(new Date(date));
};

const getAffiliateNameFromUrlString = (url) => {
    if (!url) return 'N/A';
    const delimiter = url.includes('-') ? '-' : '/';

    if (url.includes(delimiter)) {
        const parts = url.split(delimiter);
        return parts[parts.length - 1];
    }

    return url;
};

const AffiliateSelfSearchSalesTransactions = ({
    tableData,
    transactionsAffiliateHasBeenPaidFor,
    // onSearch,
    // startDate,
    // endDate,
    // setStartDate,
    // setEndDate
}) => (
    <div>
        {/* Date Filter Inputs */}
        <h2 className="mt-4 mb-4 font-display text-xl tracking-tight text-slate-900 sm:text-2xl">
            Transactions
        </h2>
        {/* <div className="flex flex-wrap items-center space-y-4 md:space-y-0 md:space-x-4">
            <div className="flex-grow">
                <label htmlFor="startDate" className="mb-3 block text-sm font-medium text-gray-700">
                    Start Date:
                </label>
                <input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                />
            </div>

            <div className="flex-grow">
                <label htmlFor="endDate" className="mb-3 block text-sm font-medium text-gray-700">
                    End Date:
                </label>
                <input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                />
            </div>
            <div>
                <Button onClick={onSearch} className="mt-8">Search</Button>
            </div>
        </div> */}

        {/* Display Message if No Data */}
        {tableData.length === 0 ? (
            <p className="pt-8">No earnings data available yet. Please check back later.</p>
        ) : (
            /* Transactions Table */
            <div>
                <table className="dataTable">
                    <thead>
                        <tr>
                            <th>Purchaser UUID</th>
                            <th>Platform</th>
                            <th>Sandbox</th>
                            <th>Product ID</th>
                            <th>Purchase Date</th>
                            <th>Expiration Date</th>
                            <th>Renewal Intent</th>
                            <th>Amount USD</th>
                            <th>Currency</th>
                            <th>Status of Payment</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.flatMap((row, rowIndex) => (
                            Object.values(row.transactions).map((purchase, purchaseIndex) => (
                                <tr key={`${rowIndex}-${purchaseIndex}`}>
                                    <td>{getAffiliateNameFromUrlString(row.applicationUsername)}</td>
                                    <td>{purchase.platform}</td>
                                    <td>{purchase.sandbox ? "Yes" : "No"}</td>
                                    <td>{purchase.productId}</td>
                                    <td>{formatDateOutput(purchase.purchaseDate)}</td>
                                    <td>{purchase.expirationDate ? formatDateOutput(purchase.expirationDate) : "N/A"}</td>
                                    <td>{purchase.renewalIntent}</td>
                                    <td>{(purchase.amountUSD !== null && purchase.amountUSD !== undefined) ? purchase.amountUSD : "Not Available - Perhaps it's in sandbox"}</td>
                                    <td>{(purchase.currency !== null && purchase.currency !== undefined) ? purchase.currency : "Not Available"}</td>
                                    <td className={`${transactionsAffiliateHasBeenPaidFor.includes(purchase.transactionId) ? 'text-green-600' : 'text-red-600'}`}>
                                        {transactionsAffiliateHasBeenPaidFor.includes(purchase.transactionId) ? "Paid" : "Unpaid"}
                                    </td>
                                </tr>
                            ))
                        ))}
                    </tbody>
                </table>
            </div>
        )}
    </div>
);

export default AffiliateSelfSearchSalesTransactions;
