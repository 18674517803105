import React, { useState } from 'react';

const sunsetPlans = [
    {
        id: 'price_1A',
        name: 'Starter (Sunset)',
        price: '£14.95/month',
        priceId: 'price_1Qygx9RuLSxnvF11N0kbzJzp',
        features: [
            'Limited to 10 affiliates.',
            'Email support.',
            'Access to the Insert Affiliate SDK and Dashboard.',
        ]
    },
    {
        id: 'price_1B',
        name: 'Growth (Sunset)',
        price: '£49.95/month',
        priceId: 'price_1QRAvPRuLSxnvF11IfC1yMhk',
        features: [
            'Up to 50 affiliates.',
            'Enjoy a significantly reduced price exclusively reserved for beta phase 2 users.',
            'Priority customer support.',
            'White Glove Onboarding.',
            'Access to the Insert Affiliate SDK and Dashboard.',
        ]
    }
];


const plans = [
    {
        id: 'price_1C',
        name: 'Starter',
        price: '£49.00/month',
        priceId: 'price_1R44beRuLSxnvF11MWU4vdnp',
        features: [
            'Limited to 5 affiliates.',
            'Basic email support.',
            'Self-server onboarding.',
        ]
    },
    {
        id: 'price_1D',
        name: 'Growth',
        price: '£99.00/month',
        priceId: 'price_1R44hpRuLSxnvF11WJHU9YHI',
        features: [
            'Up to 25 affiliates.',
            'Faster email support.',
            'Priority feedback consideration.',
            'Custom feature requests.',
        ]
    },
    {
        id: 'price_1E',
        name: 'Scale',
        price: '£299.00/month',
        priceId: 'price_1R44j2RuLSxnvF11h1T43DaN',
        features: [
            'Up to 100 affiliates, with potential to increase based on usage.',
            'Dedicated account manager.',
            'Priority support.',
            'White glove onboarding.',
            'Optional quarterly strategy calls.',
            'Fast-tracked feedback with direct roadmap influence.',
            'Highest priority for custom feature requests.',
        ]
    }
];

function convertUnixTimestampToDate(timestamp) {
    // Create a new JavaScript Date object based on the Unix timestamp (in milliseconds)
    const date = new Date(timestamp * 1000);

    // Get the day, month, and year
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-indexed, so we need to add 1
    const year = date.getFullYear();

    // Return the formatted date string
    return `${day}/${month}/${year}`;
}

const SubscriptionSwitcher = ({ current_period_end, currentSubscriptionId, currentPriceId, stripePurchaserEmail, companyId }) => {
    const [selectedPlan, setSelectedPlan] = useState(currentPriceId);
    const [isUpdating, setIsUpdating] = useState(false);

    const activePlans = [...plans];
    const currentPlan = sunsetPlans.find(plan => plan.priceId === currentPriceId);
    if (currentPlan) {
        activePlans.push(currentPlan);
    }

    const handleSwitchPlan = async (newPriceId) => {
        if (newPriceId === currentPriceId) {
            alert("You're already on this plan.");
            return;
        }

        setIsUpdating(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/update-subscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    subscriptionId: currentSubscriptionId,
                    newPriceId,
                    stripePurchaserEmail,
                    companyId
                })
            });

            const result = await response.json();

            if (result.success) {
                alert('Subscription updated successfully!');
                setSelectedPlan(newPriceId);  // Update UI
            } else {
                alert(`Failed to update subscription: ${result.error}`);
            }
        } catch (error) {
            console.error('Error switching plan:', error);
            alert('An error occurred while switching plans.');
        } finally {
            setIsUpdating(false);
        }
    };

    return (
        <div className="pricing-table grid grid-cols-1 md:grid-cols-3 gap-6">
            {activePlans.map(plan => (
                <div
                    key={plan.id}
                    className={`p-6 border rounded-lg cursor-pointer transition-shadow duration-300 hover:shadow-lg flex flex-col justify-between
                        ${selectedPlan === plan.priceId ? 'border-purple-600 bg-purple-50' : 'border-gray-300 bg-white'}
                    `}
                >
                    <h3 className="text-2xl font-bold text-gray-800">{plan.name}</h3>
                    <p className="text-lg my-4 text-gray-600">{plan.price}</p>

                    <ul className="text-left space-y-2 mb-4 list-disc list-inside">
                        {plan.features.map((feature, index) => (
                            <li key={index} className="text-gray-700">
                                {feature}
                            </li>
                        ))}
                    </ul>

                    {selectedPlan === plan.priceId ? (
                        <p>
                        Next payment: {convertUnixTimestampToDate(current_period_end)}
                    </p>
                    ) : (
                        <br></br>
                    )}
                    <div className="mt-auto">
                        <button
                            className={`mt-4 py-2 px-6 w-full rounded-lg transition-colors duration-300
                                ${selectedPlan === plan.priceId ? 'bg-purple-600 text-white' : 'bg-gray-200 text-gray-800 hover:bg-gray-300'}
                            `}
                            disabled={isUpdating}
                            onClick={() => handleSwitchPlan(plan.priceId)}
                        >
                            {isUpdating && selectedPlan === plan.priceId ? 'Updating...' : (selectedPlan === plan.priceId ? 'Current Plan' : 'Switch to this Plan')}
                        </button>
                    </div>
                </div>
            ))}
        </div>

    );
};

function CheckIcon({
    className,
    ...props
  }) {
    return (
      <svg
        aria-hidden="true"
        className={
          'h-6 w-6 flex-none fill-current stroke-current'}
        {...props}
      >
        <path
          d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
          strokeWidth={0}
        />
        <circle
          cx={12}
          cy={12}
          r={8.25}
          fill="none"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  }
  

export default SubscriptionSwitcher;
