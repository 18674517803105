import React, { useState, useEffect } from 'react';
import { collection, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const AffiliateIapticSalesTransactions = ({
    tableData,
    affiliatePaymentPercentage,
    transactionsAffiliateHasBeenPaidFor,
    setTransactionsAffiliateHasBeenPaidFor,
    transactionsAffiliateHasNotBeenPaidFor,
    setTransactionsAffiliateHasNotBeenPaidFor,
    affiliateEmail,
    user,
    totalAffiliateHasBeenPaid,
    setTotalAffiliateHasBeenPaid,
    totalAffiliateHasNotBeenPaid,
    setTotalAffiliateHasNotBeenPaid,
}) => {
    const [selectedItems, setSelectedItems] = useState(new Set());
    const [initalised, setInitialised] = useState(false);
    
    const [paymentFilter, setPaymentFilter] = useState('unpaid'); // 'all', 'paid', 'unpaid'
    const [localStartDate, setLocalStartDate] = useState('');
    const [localEndDate, setLocalEndDate] = useState('');

    const uniqueTransactionIds = [
        ...new Set(
            tableData.flatMap(row => Object.values(row.transactions)).map(purchase => purchase.transactionId)
        )
    ];
    const [selectedTransactionIds, setSelectedTransactionIds] = useState([]); // Stores multiple Transaction IDs
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const uniqueProductIds = [
        ...new Set(
            tableData.flatMap(row => Object.values(row.transactions)).map(purchase => purchase.productId)
        )
    ];
    const [selectedProductIds, setSelectedProductIds] = useState([]); // Stores multiple Product IDs
    const [isProductIdDropdownOpen, setIsProductIdDropdownOpen] = useState(false);

    const [selectedCustomerIds, setSelectedCustomerIds] = useState([]); // Stores multiple Customer IDs
    const [isCustomerDropdownOpen, setIsCustomerDropdownOpen] = useState(false);
    
    const uniqueCustomerIds = [...new Set(tableData.map((t) => getAffiliateNameFromUrlString(t.applicationUsername)))];

    const handleSelectCustomerId = (id) => {
        setSelectedCustomerIds((prev) =>
            prev.includes(id) ? prev.filter((t) => t !== id) : [...prev, id]
        );
    };

    const handleSelectProductId = (id) => {
        setSelectedProductIds((prev) =>
            prev.includes(id) ? prev.filter((t) => t !== id) : [...prev, id]
        );
    };

    const handleSelectTransactionId = (id) => {
        setSelectedTransactionIds((prev) =>
            prev.includes(id) ? prev.filter((t) => t !== id) : [...prev, id]
        );
    };

    const handleStartDateBlur = (e) => {
        setLocalStartDate(e.target.value);
    };
    
    const handleEndDateBlur = (e) => {
        const newEndDate = e.target.value;
        if (newEndDate && localStartDate && new Date(newEndDate) < new Date(localStartDate)) {
            setLocalEndDate(''); // Reset end date
        } else {
            setLocalEndDate(newEndDate);
        }
    };

    useEffect(() => {
        if (initalised) {
            updateFirebaseAffiliateTransactionsAffiliateHasBeenPaidFor({
                affiliateEmail,
                transactionsAffiliateHasBeenPaidFor,
                transactionsAffiliateHasNotBeenPaidFor,
                totalAffiliateHasBeenPaid,
                totalAffiliateHasNotBeenPaid
            });
        } else {
            setInitialised(true);
        }
    }, [transactionsAffiliateHasBeenPaidFor, transactionsAffiliateHasNotBeenPaidFor, totalAffiliateHasBeenPaid, totalAffiliateHasNotBeenPaid]);    

    const updateFirebaseAffiliateTransactionsAffiliateHasBeenPaidFor = async ( affiliate ) => {
        const companiesRef = collection(db, 'Companies');
        const userDocRef = doc(companiesRef, user.uid);

        const affiliateRef = doc(collection(userDocRef, 'Affiliates'), affiliate.affiliateEmail);

        try {
            await updateDoc(affiliateRef, {
                transactionsAffiliateHasBeenPaidFor: affiliate.transactionsAffiliateHasBeenPaidFor,
                transactionsAffiliateHasNotBeenPaidFor: affiliate.transactionsAffiliateHasNotBeenPaidFor,
                totalAffiliateHasBeenPaid: affiliate.totalAffiliateHasBeenPaid,
                totalAffiliateHasNotBeenPaid: affiliate.totalAffiliateHasNotBeenPaid
            });
        } catch (error) {
            // console.error("Error updating affiliate:", error);
        }
    }

    const handleSelectItem = (transactionId) => {
        setSelectedItems(prevState => {
            const newState = new Set(prevState);
            if (newState.has(transactionId)) {
                newState.delete(transactionId);
            } else {
                newState.add(transactionId);
            }
            return newState;
        });
    };

    const handleSelectAll = () => {
        const allItems = new Set(tableData.flatMap(row => Object.values(row.transactions)).map(purchase => purchase.transactionId));
        if (selectedItems.size === allItems.size) {
            setSelectedItems(new Set());
        } else {
            setSelectedItems(allItems);
        }
    };

    const markAllAsPaid = () => {
        setTransactionsAffiliateHasBeenPaidFor(prevState => {
            const newPaidTransactions = [...prevState];
            selectedItems.forEach(transactionId => {
                if (!newPaidTransactions.includes(transactionId)) {
                    newPaidTransactions.push(transactionId);
                }
            });
            return newPaidTransactions;
        });

        setTransactionsAffiliateHasNotBeenPaidFor(prevState => {
            const newUnpaidTransactions = prevState.filter(transactionId => !selectedItems.has(transactionId));
            
            const selectedTransactions = tableData.flatMap(row => Object.values(row.transactions)).filter(purchase => selectedItems.has(purchase.transactionId));
            const amountToAdd = selectedTransactions.reduce((total, purchase) => total + (purchase.amountOwedToAffiliate || 0), 0);
            
            setTotalAffiliateHasBeenPaid(prevTotal => prevTotal + amountToAdd);
            setTotalAffiliateHasNotBeenPaid(prevTotal => prevTotal - amountToAdd);
    
            updateFirebaseAffiliateTransactionsAffiliateHasBeenPaidFor({
                affiliateEmail,
                transactionsAffiliateHasBeenPaidFor: [...transactionsAffiliateHasBeenPaidFor, ...Array.from(selectedItems)],
                transactionsAffiliateHasNotBeenPaidFor: newUnpaidTransactions,
                totalAffiliateHasBeenPaid: totalAffiliateHasBeenPaid + amountToAdd,
                totalAffiliateHasNotBeenPaid: totalAffiliateHasNotBeenPaid - amountToAdd
            });
    
            return newUnpaidTransactions;
        });
    };
    
    const markTransactionAsPaid = (transactionId) => {
        setTransactionsAffiliateHasBeenPaidFor(prevState => [...(prevState || []), transactionId]);
        setTransactionsAffiliateHasNotBeenPaidFor(prevState => prevState.filter(id => id !== transactionId));

        const transaction = tableData.flatMap(row => Object.values(row.transactions)).find(purchase => purchase.transactionId === transactionId);
        const amount = transaction?.amountOwedToAffiliate || 0;

        setTotalAffiliateHasBeenPaid(prevTotal => prevTotal + amount);
        setTotalAffiliateHasNotBeenPaid(prevTotal => prevTotal - amount);
    }

    const markTransactionAsUnpaid = (transactionId) => {
        setTransactionsAffiliateHasBeenPaidFor(prevState => prevState.filter(id => id !== transactionId));
        setTransactionsAffiliateHasNotBeenPaidFor(prevState => [...(prevState || []), transactionId]);

        const transaction = tableData.flatMap(row => Object.values(row.transactions)).find(purchase => purchase.transactionId === transactionId);
        const amount = transaction?.amountOwedToAffiliate || 0;
    
        setTotalAffiliateHasBeenPaid(prevTotal => prevTotal - amount);
        setTotalAffiliateHasNotBeenPaid(prevTotal => prevTotal + amount);
    };

    const filteredTableData = tableData.flatMap((row, rowIndex) => 
        Object.values(row.transactions)
        .filter((purchase) => {
            // Convert purchase date from milliseconds to YYYY-MM-DD format
            const purchaseDate = new Date(purchase.purchaseDate).toISOString().split("T")[0];

            // Ensure start and end date filters are applied correctly
            const isAfterStartDate = !localStartDate || purchaseDate >= localStartDate;
            const isBeforeEndDate = !localEndDate || purchaseDate <= localEndDate;

            // Filter by Transaction IDs (if any are selected)
            const isMatchingTransactionId =
                selectedTransactionIds.length === 0 || selectedTransactionIds.includes(purchase.transactionId);

            // Filter by Product Ids (if any are selected)
            const isMatchingProductId =
                selectedProductIds.length === 0 || selectedProductIds.includes(purchase.productId);
            
            // Filter by Customer Ids (if any are selected)
            const isMatchingCustomerId =
                selectedCustomerIds.length === 0 || selectedCustomerIds.includes(getAffiliateNameFromUrlString(row.applicationUsername));

            // Payment filter logic
            const isMatchingPaymentFilter =
                paymentFilter === "all" ||
                (paymentFilter === "paid" && transactionsAffiliateHasBeenPaidFor.includes(purchase.transactionId)) ||
                (paymentFilter === "unpaid" && !transactionsAffiliateHasBeenPaidFor.includes(purchase.transactionId));

            return isAfterStartDate && isBeforeEndDate && isMatchingTransactionId && isMatchingCustomerId && isMatchingProductId && isMatchingPaymentFilter;
        }).map((purchase, purchaseIndex) => (
            <tr key={`${rowIndex}-${purchaseIndex}`}>
                <td className="text-center">
                    <input
                        type="checkbox"
                        checked={selectedItems.has(purchase.transactionId)}
                        onChange={() => handleSelectItem(purchase.transactionId)}
                    />
                </td>
                <td>{getAffiliateNameFromUrlString(row.applicationUsername)}</td>
                <td>{purchase.transactionId}</td>
                <td>{purchase.purchaseId}</td>
                <td>{purchase.platform}</td>
                <td>{purchase.sandbox ? "Yes" : "No"}</td>
                <td>{purchase.productId}</td>
                <td>{formatDateOutput(purchase.purchaseDate)}</td>
                <td>{formatDateOutput(purchase.lastRenewalDate)}</td>
                <td>{purchase.expirationDate ? formatDateOutput(purchase.expirationDate) : "N/A"}</td>
                <td>{purchase.renewalIntent ? purchase.renewalIntent : "N/A"}</td>
                <td>{(purchase.amountUSD !== null && purchase.amountUSD !== undefined) ? purchase.amountUSD : "Sandbox Transaction"}</td>
                <td>{(purchase.amountUSD !== null && purchase.amountUSD !== undefined) ? purchase.amountUSD * (affiliatePaymentPercentage / 100).toFixed(2) : "Sandbox Transaction"}</td>
                <td className={` ${transactionsAffiliateHasBeenPaidFor && transactionsAffiliateHasBeenPaidFor.includes(purchase.transactionId) ? 'text-green-600' : 'text-red-600'}`}>
                    {(transactionsAffiliateHasBeenPaidFor && transactionsAffiliateHasBeenPaidFor.includes(purchase.transactionId)) ? "Paid" : "Unpaid"}
                </td>
                <td className="whitespace-nowrap">
                    {transactionsAffiliateHasBeenPaidFor && transactionsAffiliateHasBeenPaidFor.includes(purchase.transactionId) ? (
                        <button onClick={() => markTransactionAsUnpaid(purchase.transactionId)} className="text-red-600">
                            Mark as Unpaid
                        </button>
                    ) : (
                        <button onClick={() => markTransactionAsPaid(purchase.transactionId)} className="text-purple-600">
                            Mark as Paid
                        </button>
                    )}
                </td>
            </tr>
        ))
    );
    
    return (
        <div>
            <h2 className="mt-4 mb-4 font-display text-xl tracking-tight text-slate-900 sm:text-2xl">
                Affiliate's Transactions
            </h2>
            <div className="flex flex-wrap items-center gap-4 sm:gap-6 w-full">
                <div className="flex flex-col align-center ">
                    <label className="font-bold">Status</label>
                    <select
                        id="paymentFilter"
                        value={paymentFilter}
                        onChange={(e) => setPaymentFilter(e.target.value)}
                        className="px-10 py-2 border rounded-md"
                    >
                        <option value="all">All</option>
                        <option value="paid">Paid</option>
                        <option value="unpaid">Unpaid</option>
                    </select>
                </div>
                <div className="flex-1">
                    <label className="font-bold">Purchase Start Date</label>
                    <DateInputWithPlaceholder
                        id="startDate"
                        value={localStartDate}
                        onChange={(e) => setLocalStartDate(e.target.value)}
                        onBlur={handleStartDateBlur}
                        placeholder="Start Date"
                    />
                </div>
                <div className="flex-1">
                    <label className="font-bold">Purchase End Date</label>
                    <DateInputWithPlaceholder
                        id="endDate"
                        value={localEndDate}
                        onChange={(e) => setLocalEndDate(e.target.value)}
                        onBlur={handleEndDateBlur}
                        placeholder="End Date"
                        min={localStartDate}
                    />
                </div>
                <div className="flex-1">
                    <label className="font-bold">Customer ID</label>
                    <div
                        className="rounded-md flex items-center gap-2 h-[42.5px] max-w-full overflow-x-auto overflow-y-hidden bg-gray-50 p-2 border border-gray-300"
                        style={{ width: "100%", whiteSpace: "nowrap", overflowX: "auto", display: "flex" }}
                        onClick={() => setIsCustomerDropdownOpen(!isCustomerDropdownOpen)}
                
                    >
                        <span>{selectedCustomerIds.length || 0} filter(s) applied</span>
                        {/* Dropdown Menu */}
                        {isCustomerDropdownOpen && (
                            <div className="absolute z-10 mt-1 bg-white border border-gray-300 rounded-md shadow-md max-h-48 overflow-y-auto">
                                {uniqueCustomerIds.map((id) => (
                                    <div
                                        key={id}
                                        onClick={() => handleSelectCustomerId(id)}
                                        className={`p-2 cursor-pointer hover:bg-gray-100 flex justify-between ${
                                            selectedCustomerIds.includes(id) ? "bg-blue-100" : ""
                                        }`}
                                    >
                                        {id}
                                        {selectedCustomerIds.includes(id) && <span className="text-blue-600">✔</span>}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex-1">
                    <label className="font-bold">Transaction ID</label>
                    <div
                        className="rounded-md flex items-center gap-2 h-[42.5px] max-w-full overflow-x-auto overflow-y-hidden bg-gray-50 p-2 border border-gray-300"
                        style={{ width: "100%", whiteSpace: "nowrap", overflowX: "auto", display: "flex" }}
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                
                    >
                        {/* Selected Transaction ID Bubbles */}
                        <span>{selectedTransactionIds.length || 0} filter(s) applied</span>
                        {isDropdownOpen && (
                            <div className="absolute z-10 mt-1 bg-white border border-gray-300 rounded-md shadow-md max-h-48 overflow-y-auto">
                                {uniqueTransactionIds.map((id) => (
                                    <div
                                        key={id}
                                        onClick={() => handleSelectTransactionId(id)}
                                        className={`p-2 cursor-pointer hover:bg-gray-100 flex justify-between ${
                                            selectedTransactionIds.includes(id) ? "bg-blue-100" : ""
                                        }`}
                                    >
                                        {id}
                                        {selectedTransactionIds.includes(id) && <span className="text-blue-600">✔</span>}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex-1">
                    <label className="font-bold">Product ID</label>
                    <div
                        className="rounded-md flex items-center gap-2 h-[42.5px] max-w-full overflow-x-auto overflow-y-hidden bg-gray-50 p-2 border border-gray-300"
                        style={{ width: "100%", whiteSpace: "nowrap", overflowX: "auto", display: "flex" }}
                        onClick={() => setIsProductIdDropdownOpen(!isProductIdDropdownOpen)}
                
                    >
                        {/* Selected Transaction ID Bubbles */}
                        <span>{selectedProductIds.length || 0} filter(s) applied</span>

                        {/* Dropdown Menu */}
                        {isProductIdDropdownOpen && (
                            <div className="absolute z-10 mt-1 bg-white border border-gray-300 rounded-md shadow-md max-h-48 overflow-y-auto">
                                {uniqueProductIds.map((id) => (
                                    <div
                                        key={id}
                                        onClick={() => handleSelectProductId(id)}
                                        className={`p-2 cursor-pointer hover:bg-gray-100 flex justify-between ${
                                            selectedProductIds.includes(id) ? "bg-blue-100" : ""
                                        }`}
                                    >
                                        {id}
                                        {selectedProductIds.includes(id) && <span className="text-blue-600">✔</span>}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <table className="dataTable">
                <thead>
                    <tr>
                        <th className="text-center">
                            <input
                                type="checkbox"
                                checked={selectedItems.size === tableData.flatMap(row => Object.values(row.transactions)).length}
                                onChange={handleSelectAll}
                            />
                        </th>
                        <th>Customer UUID</th>
                        <th>Transaction ID</th>
                        <th>Purchase ID</th>
                        <th>Platform</th>
                        <th>Sandbox</th>
                        <th>Product Id</th>
                        <th>Original Purchase Date</th>
                        <th>Last Renewal Date</th>
                        <th>Expiration Date</th>
                        <th>Renewal Intent</th>
                        <th>Total Earnings ($)</th>
                        {paymentFilter === 'unpaid' && (
                            <th>Amount Owed to Affiliate ($)</th>
                        )}
                        {paymentFilter === 'paid' && (
                            <th>Amount Paid to Affiliate ($)</th>
                        )}
                        {paymentFilter === 'all' && (
                            <th>Amount of Transaction Assigned to Affiliate</th>
                        )}
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                {filteredTableData}
                    <tr className="totalRow">
                        <td colSpan="15">
                            <button onClick={markAllAsPaid} className="text-purple-600">
                                Mark Selected As Paid
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr className="totalRow">
                    <td colSpan="2">Total</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            {tableData
                                .filter((transaction) => {
                                    if (paymentFilter === 'all') return true;
                                    if (paymentFilter === 'paid') return transaction.affiliateHasBeenPaid;
                                    if (paymentFilter === 'unpaid') return !transaction.affiliateHasBeenPaid;
                                    return true; // Default case
                                })
                                .reduce((total, transaction) => {
                                    return total + ((transaction?.amountUSD || 0));
                                }, 0)
                            .toFixed(2)}
                        </td>
                        <td>
                            {tableData
                                .filter((transaction) => {
                                    if (paymentFilter === 'all') return true;
                                    if (paymentFilter === 'paid') return transaction.affiliateHasBeenPaid;
                                    if (paymentFilter === 'unpaid') return !transaction.affiliateHasBeenPaid;
                                    return true; // Default case
                                })
                                .reduce((total, transaction) => {
                                    return total + (((transaction?.amountUSD || 0)) * (affiliatePaymentPercentage / 100));
                                }, 0)
                            .toFixed(2)}
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

const DateInputWithPlaceholder = ({ id, value, onChange, onBlur, placeholder, min }) => {
    const [isFocused, setIsFocused] = useState(false);
  
    const handleFocus = (e) => {
      setIsFocused(true);
      e.target.type = 'date';
      e.target.placeholder = '';
    };
  
    const handleBlur = (e) => {
      if (!e.target.value) {
        e.target.type = 'text';
        e.target.placeholder = placeholder;
      }
      setIsFocused(false);
      onBlur && onBlur(e);
    };
  
    return (
      <div className="relative">
        <input
          type="text"
          id={id}
          value={value}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={isFocused ? '' : placeholder}
          className="block w-full appearance-none rounded-md border border-gray-300 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-500 sm:text-sm"
          style={{ width: '100%', minWidth: '180px' }}
          min={min}
        />
      </div>
    );
};

const formatDateOutput = (date) => {
    if (!date) {
        return "N/A";
    }
    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    }).format(new Date(date));
};

const getAffiliateNameFromUrlString = (url) => {
    if (!url) return 'N/A';
    const delimiter = url.includes('-') ? '-' : '/';

    if (url.includes(delimiter)) {
        const parts = url.split(delimiter);
        return parts[parts.length - 1];
    }
    return url;
};

export default AffiliateIapticSalesTransactions;
