// AffiliateSelfSearchNativeSalesTransactions.js
import React, {useState} from 'react';

const formatDateOutput = (date) => {
    if (!date) {
        return "N/A";
    }

    if (typeof date === 'string' && !isNaN(date)) {
        date = Number(date);
    }

    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    }).format(new Date(date));
};

const getAffiliateNameFromUrlString = (url) => {
    if (!url) return 'N/A';
    const delimiter = url.includes('-') ? '-' : '/';

    if (url.includes(delimiter)) {
        const parts = url.split(delimiter);
        return parts[parts.length - 1];
    }
    
    return url;
};

const AffiliateSelfSearchNativeSalesTransactions = ({
    tableData,
    affiliatePaymentPercentage
}) => {
    const [paymentFilter, setPaymentFilter] = useState('unpaid');

    const filteredTableData = tableData
        .filter((transaction) => {
            if (paymentFilter === 'all') return true;
            if (paymentFilter === 'paid') return transaction.affiliateHasBeenPaid;
            if (paymentFilter === 'unpaid') return !transaction.affiliateHasBeenPaid;
            return true; // Default case
        })
        .map((transaction, index) => {
            // Render different rows based on platform
            if (transaction.platform === 'Android') {
                return (
          
                    <tr key={transaction.transaction_id}>
                        <td>{transaction.app_user_id}</td>
                        <td>{transaction.transaction_id}</td>
                        <td>{transaction.type}</td>
                        <td>{transaction.product_id}</td>
                        <td>{formatDateOutput(transaction.purchase_date)}</td>
                        <td>{formatDateOutput(transaction.expiration_date)}</td>
                        <td>{(transaction.price / 1000)}</td>
                        <td>
                            {((transaction.price / 1000) * (affiliatePaymentPercentage / 100)).toFixed(2)}
                        </td>
                        <td
                            className={` ${
                                transaction.affiliateHasBeenPaid ? 'text-green-600' : 'text-red-600'
                            }`}
                        >
                            {transaction.affiliateHasBeenPaid ? "Paid" : "Unpaid"}
                        </td>
                    </tr>
                )
            } else {
                return (
                    <tr key={transaction.transaction_id}>
                        <td>{getAffiliateNameFromUrlString(transaction.app_user_id)}</td>
                        <td>{transaction.transaction_id}</td>
                        <td>{transaction.type}</td>
                        <td>{transaction.product_id}</td>
                        <td>{formatDateOutput(transaction.purchase_date)}</td>
                        <td>{formatDateOutput(transaction.expiration_date)}</td>
                        <td>{(transaction.price / 1000)}</td>
                        <td>
                            {((transaction.price / 1000) * (affiliatePaymentPercentage / 100)).toFixed(2)}
                        </td>
                        <td
                            className={` ${
                                transaction.affiliateHasBeenPaid ? 'text-green-600' : 'text-red-600'
                            }`}
                        >
                            {transaction.affiliateHasBeenPaid ? "Paid" : "Unpaid"}
                        </td>
                    </tr>
                )
            }            
        });

    return (
        <div>
            <h2 className="mt-4 mb-4 font-display text-xl tracking-tight text-slate-900 sm:text-2xl">
                Transactions
            </h2>

            <div className="flex flex-wrap items-center space-y-4 md:space-y-0 md:space-x-4">
                <div className="">
                    <select
                        id="paymentFilter"
                        value={paymentFilter}
                        onChange={(e) => setPaymentFilter(e.target.value)}
                        className="px-10 py-2 border rounded-md"
                    >
                        <option value="all">All</option>
                        <option value="paid">Paid</option>
                        <option value="unpaid">Unpaid</option>
                    </select>
                </div>
            </div>

            {tableData.length === 0 ? (
                <p className="pt-8">No earnings data available yet. Please check back later.</p>
            ) : (
                <div>
                    <table className="dataTable">
                        <thead>
                            <tr>
                                <th>Customer UUID</th>
                                <th>Transaction ID</th>
                                <th>Transaction Type</th>
                                <th>Product ID</th>
                                <th>Original Purchase Date</th>
                                <th>Expiration Date</th>
                                <th>Transaction Total Amount ($)</th>
                                <th>Amount You Earned</th>
                                <th>Status of Payment</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredTableData}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )   
};

export default AffiliateSelfSearchNativeSalesTransactions;
