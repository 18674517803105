import React, { useState, useContext, useEffect } from 'react';
import { Container } from '../../components/Container.js';
import { ApiContext } from '../../contexts/ApiContext.js';
import FetchUserAndFirebaseDocsOnAuth from '../../components/fetchFirebaseDocsOnAuth.js';
import { collection, getDoc, updateDoc, doc } from 'firebase/firestore'; // Import firestore functions
import { db } from '../../firebase.js';
import { Button } from '../../components/Button.js';
import CreatableSelect from 'react-select/creatable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipboardDocumentIcon } from '@heroicons/react/24/solid';
import { trackPageView } from '../../util/analyticsService.js';

const Settings = () => {
  useEffect(() => {
    trackPageView('/settings.js');
  }, []);

  const {
    iapticAppName,
    setIapticAppName,
    iapticSecretKey,
    setIapticSecretKey,
    iapticWebhookEnabled,
    setIapticWebhookEnabled,
    selectedVerificationMethod,
    setSelectedVerificationMethod,
    selectedAffiliatePaymentType,
    setSelectedAffiliatePaymentType,
    companyName,
    revenueCatAuthHeader,
    setRevenueCatAuthHeader,
    appStoreNotificationUrl,
    setAppStoreNotificationUrl,
    googlePlayStoreNotificationUrl,
    setGooglePlayStoreNotificationUrl,
    setCompanyName,
    companyId,
    companyContactEmail,
    setCompanyContactEmail,
    companyAffiliatePaymentPercentage,
    setCompanyAffiliatePaymentPercentage,
    companyOfferCouponCodes,
    setCompanyOfferCouponCodes,
  } = useContext(ApiContext);
  const [localSelectedAffiliatePaymentType, setLocalSelectedAffiliatePaymentType] = useState(selectedAffiliatePaymentType);
  const [localSelectedVerificationMethod, setLocalSelectedVerificationMethod] = useState(selectedVerificationMethod);
  const [notification, setNotification] = useState('');
  const [showIapticSecret, setShowIapticSecret] = useState(false);
  const [localCompanyName, setLocalCompanyName] = useState(companyName || "");
  const [localCompanyContactEmail, setLocalCompanyContactEmail] = useState(companyContactEmail || "");
  const [localCompanyAffiliatePaymentPercentage, setLocalCompanyAffiliatePaymentPercentage] = useState(companyAffiliatePaymentPercentage || 25);
  const [localCompanyOfferCouponCodes, setLocalCompanyOfferCouponCodes] = useState(() => {
    if (Array.isArray(companyOfferCouponCodes) && companyOfferCouponCodes !== null) {
        return companyOfferCouponCodes.map(code => ({
            value: code.value,
            label: `${code.value} (expires: ${new Date(code.expiry).toLocaleDateString()})`,
            expiry: code.expiry
        }));
    }
    return [];
  });
  const [localIapticAppName, setLocalIapticAppName] = useState(iapticAppName || "");
  const [localIapticSecretKey, setLocalIapticSecretKey] = useState(iapticSecretKey || "");
  const [localIapticWebhookEnabled, setLocalIapticWebhookEnabled] = useState(iapticWebhookEnabled || false);

  const [googleServiceAccountFile, setGoogleServiceAccountFile] = useState(null);
  const [googleServiceAccountFileName, setGoogleServiceAccountFileName] = useState('');
  const [googleServiceAccountJson, setGoogleServiceAccountJson] = useState(null);
  const [googleServiceAccountExists, setGoogleServiceAccountExists] = useState(false);

  const handleGoogleServicesAccountFileUpload = (event) => {
    const file = event.target.files[0];

    if (!file) {
      setGoogleServiceAccountFile(null);
      setGoogleServiceAccountFileName('');
      setGoogleServiceAccountJson(null);
      return;
    }

    if (file.type !== "application/json") {
      toast.error("Please upload a valid JSON file.");
      setGoogleServiceAccountFile(null);
      setGoogleServiceAccountFileName('');
      setGoogleServiceAccountJson(null);
      return;
    }

    setGoogleServiceAccountFile(file);
    setGoogleServiceAccountFileName(file.name);

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const parsed = JSON.parse(e.target.result);
        validateServiceAccount(parsed);
        setGoogleServiceAccountJson(parsed);
      } catch (error) {
        toast.error("Invalid service account file.");
        setGoogleServiceAccountFile(null);
        setGoogleServiceAccountFileName('');
        setGoogleServiceAccountJson(null);
      }
    };

    reader.readAsText(file);
  };

  // Basic validation function to check for required fields in service account file
  const validateServiceAccount = (json) => {
    const requiredFields = [
      "type",
      "project_id",
      "private_key_id",
      "private_key",
      "client_email",
      "client_id"
    ];

    requiredFields.forEach(field => {
      if (!json[field]) {
        throw new Error(`Missing required field: ${field}`);
      }
    });
  };

  useEffect(() => {
    const triggerApiCallForRevenueCat = async () => {
      if (localSelectedVerificationMethod === "RevenueCat" && revenueCatAuthHeader === "") {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/generate-auth-token`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ companyId }),
          });
  
          if (!response.ok) {
            throw new Error("Failed to generate RevenueCat auth token");
          }
  
          const data = await response.json();
          setRevenueCatAuthHeader(data.token);
          const companiesRef = collection(db, 'Companies');
          const userDocRef = doc(companiesRef, user.uid);

          await updateDoc(userDocRef, {
            revenueCatAuthHeader: data.token
          });
        } catch (error) {
          console.error("Error during RevenueCat Auth Token API call:", error);
        }
      }
    };
  
    triggerApiCallForRevenueCat();
  }, [localSelectedVerificationMethod, revenueCatAuthHeader, companyId]); // Dependency array ensures the effect triggers only on relevant changes


  useEffect(() => {
    const triggerApiCallForAppStoreNotification = async () => {
      if (localSelectedVerificationMethod === "Native" && (appStoreNotificationUrl === "" || appStoreNotificationUrl === undefined)) {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/generate-app-store-notification-url`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ companyId }),
          });

          if (!response.ok) {
            throw new Error("Failed to generate App Store notification URL");
          }

          const data = await response.json();
          setAppStoreNotificationUrl(data.url);
          const companiesRef = collection(db, 'Companies');
          const userDocRef = doc(companiesRef, user.uid);

          await updateDoc(userDocRef, {
            appStoreNotificationUrl: data.url
          });

        } catch (error) {
          console.error("Error during App Store Notification URL API call:", error);
        }
      }
    };
    
    triggerApiCallForAppStoreNotification();
  }, [localSelectedVerificationMethod, appStoreNotificationUrl, companyId]);
  
  useEffect(() => {
    const triggerApiCallForGooglePlayStoreNotification = async () => {
      if (localSelectedVerificationMethod === "Native" && (googlePlayStoreNotificationUrl === "" || googlePlayStoreNotificationUrl === undefined)) {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/generate-google-play-store-notification-url`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ companyId }),
          });

          if (!response.ok) {
            throw new Error("Failed to generate App Store notification URL");
          }

          const data = await response.json();
          setGooglePlayStoreNotificationUrl(data.url);
          const companiesRef = collection(db, 'Companies');
          const userDocRef = doc(companiesRef, user.uid);

          await updateDoc(userDocRef, {
            googlePlayStoreNotificationUrl: data.url
          });

        } catch (error) {
          console.error("Error during App Store Notification URL API call:", error);
        }
      }
    };
    
    triggerApiCallForGooglePlayStoreNotification();
  }, [localSelectedVerificationMethod, googlePlayStoreNotificationUrl, companyId]);

  
  // Update local when remote is retrieved
  useEffect(() => {
    if (companyName !== '' && companyName !== localCompanyName) {
      setLocalCompanyName(companyName);
    }
  }, [companyName]);

  useEffect(() => {
    if (companyContactEmail !== '' && companyContactEmail !== localCompanyContactEmail) {
      setLocalCompanyContactEmail(companyContactEmail);
    }
  }, [companyContactEmail]);

  useEffect(() => {
    if (iapticAppName !== '' && iapticAppName !== localIapticAppName) {
      setLocalIapticAppName(iapticAppName);
    }
  }, [iapticAppName]);

  useEffect(() => {
    if (iapticSecretKey !== '' && iapticSecretKey !== localIapticSecretKey) {
      setLocalIapticSecretKey(iapticSecretKey);
    }
  }, [iapticSecretKey]);

  useEffect(() => {  
    if (iapticWebhookEnabled !== '' && iapticWebhookEnabled !== localIapticWebhookEnabled) {
      setLocalIapticWebhookEnabled(iapticWebhookEnabled);
    }
  }, [iapticWebhookEnabled]);


  useEffect(() => {
    if (companyAffiliatePaymentPercentage != undefined && companyAffiliatePaymentPercentage !== '' && companyAffiliatePaymentPercentage !== localCompanyAffiliatePaymentPercentage) {
      setLocalCompanyAffiliatePaymentPercentage(companyAffiliatePaymentPercentage);
    }
  }, [companyAffiliatePaymentPercentage]);

  useEffect(() => {
    if (companyOfferCouponCodes && Array.isArray(companyOfferCouponCodes)) {
      setLocalCompanyOfferCouponCodes(
        companyOfferCouponCodes.map(code => ({
          value: code.value,
          label: `${code.value} (expires: ${new Date(code.expiry).toLocaleDateString()})`,
          expiry: code.expiry
        }))
      );
    }
  }, [companyOfferCouponCodes]);

  const handleVerificationMethodChange = (e) => {
    setLocalSelectedVerificationMethod(e.target.value);
    setSelectedVerificationMethod(e.target.value);
  };

  const handleAffiliatePaymentTypeChange = (e) => {
    setLocalSelectedAffiliatePaymentType(e.target.value);
    setSelectedAffiliatePaymentType(e.target.value);

  };

  useEffect(() => {
    if (selectedVerificationMethod !== localSelectedVerificationMethod) {
      setLocalSelectedVerificationMethod(selectedVerificationMethod);
    }
  }, [selectedVerificationMethod]);
  
  useEffect(() => {
    if (selectedAffiliatePaymentType !== localSelectedAffiliatePaymentType) {
      setLocalSelectedAffiliatePaymentType(selectedAffiliatePaymentType);
    }
  }, [selectedAffiliatePaymentType]);

  const user = FetchUserAndFirebaseDocsOnAuth();


  useEffect(() => {
    const fetchExistingServiceAccountKey = async () => {
      if (user?.uid) {
        const companiesRef = collection(db, 'Companies');
        const userDocRef = doc(companiesRef, user.uid);
        const privateRef = collection(userDocRef, 'Private');
        const privateDocRef = doc(privateRef, 'Credentials');

        const privateDocSnapshot = await getDoc(privateDocRef);

        if (privateDocSnapshot.exists()) {
          const privateData = privateDocSnapshot.data();
          if (privateData?.googleServiceAccountKey) {
            setGoogleServiceAccountExists(true);
            setGoogleServiceAccountJson(privateData.googleServiceAccountKey);  // Pre-load it
          }
        }
      }
    };

    fetchExistingServiceAccountKey();
  }, [user, user?.uid]);

  const [selectedCode, setSelectedCode] = useState(null);

  const handleChange = (newValue, actionMeta) => {
    setSelectedCode(newValue);
  };

  const handleCreate = (inputValue) => {
    const expiryDate = prompt('Enter expiry date (YYYY-MM-DD):');
    const newOption = {
        value: inputValue,
        label: `${inputValue} (expires: ${new Date(expiryDate).toLocaleDateString()})`,
        expiry: expiryDate
    };
    setLocalCompanyOfferCouponCodes((prevOptions) => [...prevOptions, newOption]);
    setSelectedCode(newOption);
  };

  const updateAndSaveSettings = async ({
    companyName,
    companyContactEmail,
    iapticAppName,
    iapticSecretKey,
    iapticWebhookEnabled,
    companyAffiliatePaymentPercentage,
    companyOfferCouponCodes,
    selectedVerificationMethod,
    selectedAffiliatePaymentType
  }) => {
    // Assuming your Firestore collection is named 'copanies'
    const companiesRef = collection(db, 'Companies');

    const userDocRef = doc(companiesRef, user.uid); // assuming user.uid is the user's UID

    const privateRef = collection(userDocRef, 'Private');
    const privateDocRef = doc(privateRef, 'Credentials');


    try {
      const userDocSnapshot = await getDoc(userDocRef);
      console.log("selectedAffiliatePaymentType", selectedAffiliatePaymentType)
      // Update the Firestore document with the new settings
      await updateDoc(userDocRef, {
        companyName: companyName,
        selectedVerificationMethod: selectedVerificationMethod,
        selectedAffiliatePaymentType: selectedAffiliatePaymentType,
        companyContactEmail: companyContactEmail,
        companyAffiliatePaymentPercentage: companyAffiliatePaymentPercentage,
        companyOfferCouponCodes: companyOfferCouponCodes,
        iapticWebhookEnabled: iapticWebhookEnabled
      });

      await updateDoc(privateDocRef, {
        iapticAppName: iapticAppName,
        iapticSecretKey: iapticSecretKey,
        googleServiceAccountKey: googleServiceAccountJson || null
      });

      setCompanyName(companyName);
      setSelectedVerificationMethod(selectedVerificationMethod);
      setSelectedAffiliatePaymentType(selectedAffiliatePaymentType);
      setCompanyContactEmail(companyContactEmail);
      setIapticAppName(iapticAppName);
      setIapticSecretKey(iapticSecretKey);
      setIapticWebhookEnabled(iapticWebhookEnabled);
      setCompanyAffiliatePaymentPercentage(companyAffiliatePaymentPercentage);
      setCompanyOfferCouponCodes(companyOfferCouponCodes);

      setNotification('Settings have been saved successfully.');
      setTimeout(() => setNotification(''), 3000); // Auto-hide after 3 seconds
    } catch (error) {
        console.error('Error updating Firestore document:', error);
    }
  };

  const handlePercentageChange = (e) => {
    let value = e.target.value;

    // Allow empty string temporarily for better UX
    if (value === '') {
      setLocalCompanyAffiliatePaymentPercentage('');
      return;
    }

    // Parse to float and clamp between 5 and 100
    let numericValue = parseFloat(value);

    if (isNaN(numericValue)) {
        numericValue = 0;
    }

    if (numericValue < 1) numericValue = 1;
    if (numericValue > 100) numericValue = 100;

    setLocalCompanyAffiliatePaymentPercentage(numericValue);
  };


  const toggleSecretIapticVisibility = () => {
    setShowIapticSecret(!showIapticSecret);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const offerCouponCodesAsStrings = localCompanyOfferCouponCodes.map(option => ({
      value: option.value,
      expiry: option.expiry
    }));
    updateAndSaveSettings({
      companyName: localCompanyName,
      selectedVerificationMethod: localSelectedVerificationMethod,
      selectedAffiliatePaymentType: localSelectedAffiliatePaymentType,
      companyContactEmail: localCompanyContactEmail,
      iapticAppName: localIapticAppName,
      iapticSecretKey: localIapticSecretKey,
      iapticWebhookEnabled: localIapticWebhookEnabled,
      companyAffiliatePaymentPercentage: localCompanyAffiliatePaymentPercentage || 1,
      companyOfferCouponCodes: offerCouponCodesAsStrings
    });
  };

  const [isCustomerId, setCustomerId] = useState(null);
  const [url, setUrl] = useState(null);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/getInitialPayment`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: user?.email })
    }).then((res) => res.json()).then(data => {
      setCustomerId(data?.data?.customerId)
    })
  }, [user?.email])

  const handleManage = async() => {
    await fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/manage-subscription`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ customerId: isCustomerId })
    }).then((res) => res.json()).then(data => {
      window.location.href=data.url;
    })
  }

  return (
    <div>
      <Container>
        {notification && (
          <div className="bg-green-500 text-white p-2 rounded mb-4">
            {notification}
          </div>
        )}
        <div className="pb-8 mx-auto" style={{ minHeight: '65vh' }}>
          <div className="pb-4">
            <h1 className="font-display text-2xl tracking-tight text-slate-900 sm:text-3xl">
              Settings
            </h1>
            <ToastContainer position="bottom-center" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick rtl pauseOnFocusLoss draggable pauseOnHover />
            <p className="mt-2 mb-4 text-lg tracking-tight text-slate-700">
              This is all we need to get started. Most questions can be answered by 
              <a
                href="https://docs.insertaffiliate.com"
                target="_blank"
                className="text-purple-600 hover:underline pl-1">
                 reading the docs
              </a>.
            </p>
          </div>
        
          <form onSubmit={handleSubmit}>
            <fieldset className="mb-4 border border-gray-300 rounded-lg p-4">
              <legend className="text-lg font-semibold text-gray-800">
                Company Settings
              </legend>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label
                    htmlFor="companyName"
                    className="mb-2 block text-sm font-medium text-gray-700"
                  >
                    Company Name:
                  </label>
                  <p className="text-xs text-gray-500 mb-4">
                    The official name of the company that affiliates will see when they join your program.
                  </p>
                  <input
                    type="text"
                    id="companyName"
                    value={localCompanyName || ""}
                    onChange={(e) => setLocalCompanyName(e.target.value)}
                    className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label
                    htmlFor="companyContactEmail"
                    className="mb-2 block text-sm font-medium text-gray-700"
                  >
                    Contact Email:
                  </label>
                  <p className="text-xs text-gray-500 mb-4">
                    The email address that affiliates can use to reach out for questions or support.
                  </p>
                  <input
                    type="email"
                    id="companyContactEmail"
                    value={localCompanyContactEmail || ""}
                    onChange={(e) => setLocalCompanyContactEmail(e.target.value)}
                    className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label
                    htmlFor="companyName"
                    className="mb-2 block text-sm font-medium text-gray-700"
                  >
                    Company Code:
                  </label>
                  <p className="text-xs text-gray-500 mb-4">
                    Required when initialising our SDK if you wish to use short affiliate codes.
                  </p>
                  <p
                    type="text"
                    id="companyName"
                    className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                  >
                    {companyId}
                  </p>
                </div>
                <div>
                  <label htmlFor="affiliatePaymentType" className="mb-2 block text-sm font-medium text-gray-700">
                    Affiliate Payment Type:
                  </label>
                  <p className="text-xs text-gray-500 mb-4">
                    Choose whether affiliates earn a recurring commission on subscriptions or a one-time payout based on the first transaction only.
                  </p>
                  <select
                    id="affiliatePaymentType"
                    value={localSelectedAffiliatePaymentType}
                    onChange={handleAffiliatePaymentTypeChange}
                    className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  >
                    <option value="recurring">Recurring</option>
                    <option value="oneTime">One-Time</option>
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="localCompanyAffiliatePaymentPercentage"
                    className="mb-2 block text-sm font-medium text-gray-700"
                  >
                    Default Affiliate Commission Percentage:
                  </label>
                  <p className="text-xs text-gray-500 mb-4">
                    The default percentage of the revenue generated by affiliates that you will pay them as commission. Minimum is 1%.
                  </p>
                  <div className="relative">
                    <input
                      id="localCompanyAffiliatePaymentPercentage"
                      value={localCompanyAffiliatePaymentPercentage}
                      type="number"
                      min="5"
                      max="100"
                      step="0.01"
                      placeholder="The percentage of the generated revenue that you will pay affiliates"
                      onChange={handlePercentageChange}
                      className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="referralCode"
                    className="mb-2 block text-sm font-medium text-gray-700"
                  >
                    Referral Code:
                  </label>
                  <p className="text-xs text-gray-500 mb-4">
                    Refer others to use Insert Affiliate and earn rewards! Share your referral code to get started.
                  </p>
                  <div className="relative">
                    <p
                      type="text"
                      id="companyName"
                      className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                    >
                      {companyId.substring(0,8)}
                    </p>
                    <button
                      type="button"
                      onClick={() => {
                        navigator.clipboard.writeText(companyId.substring(0,8))
                          .then(() => {
                            toast.success('Copied to clipboard.');
                          })
                          .catch((err) => {
                            toast.error('Failed to copy to clipboard.');
                            console.error('Clipboard error: ', err);
                          });
                      }}
                      className="absolute inset-y-0 right-0 px-3 flex items-center text-sm font-medium text-gray-700 hover:text-purple-600"
                    >
                      <ClipboardDocumentIcon className="h-5 w-5" />
                    </button>
                  </div>
                  
                </div>
                <div>
                  <label
                    htmlFor="localCompanyOfferCouponCodes"
                    className="mb-2 block text-sm font-medium text-gray-700"
                  >
                    Offer Codes:
                  </label>
                  <p className="text-xs text-gray-500 mb-4">
                    Select or add new offer codes that your affiliates links can be set to automatically assign to customers. These will help track affiliate performance.
                  </p>
                  <CreatableSelect
                    id="localCompanyOfferCouponCodes"
                    isClearable
                    value={selectedCode}
                    onChange={handleChange}
                    onCreateOption={handleCreate}
                    options={localCompanyOfferCouponCodes}
                    placeholder="Select or add a new offer code"
                    classNamePrefix="react-select"
                  />
                </div>
              </div>
            </fieldset>
            

            <fieldset className="mb-4 border border-gray-300 rounded-lg p-4">
              <legend className="text-lg font-semibold text-gray-800">
                Purchase Verification Settings
              </legend>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Verification method */}
                <div>
                  <label htmlFor="verificationMethod" className="mb-2 block text-sm font-medium text-gray-700">
                    In-App Purchase Verification Method:
                  </label>
                  <select
                    id="verificationMethod"
                    value={localSelectedVerificationMethod}
                    onChange={handleVerificationMethodChange}
                    className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  >
                    <option value="RevenueCat">RevenueCat</option>
                    <option value="Iaptic">Iaptic</option>
                    <option value="Native">I don't use a Receipt Validator (Native App Store / Google Play Integration)</option>
                  </select>
                </div>
                <div></div>
                {localSelectedVerificationMethod === 'Iaptic' && (
                  <>
                    <div>
                      <label
                        htmlFor="iapticAppName"
                        className="mb-2 block text-sm font-medium text-gray-700"
                      >
                        Iaptic App Name:
                      </label>
                      <p className="text-xs text-gray-500 mb-4">
                        The name of the Iaptic app integrated with this platform.<a href="https://docs.insertaffiliate.com/iaptic#important-settings" target="_blank" className="text-purple-600 hover:underline pl-1">Learn more</a>.
                      </p>
                      <div>
                        <input
                          type="text"
                          id="iapticAppName"
                          placeholder="Important: this is CASE SENSITIVE."
                          value={localIapticAppName || ""}
                          onChange={(e) => setLocalIapticAppName(e.target.value)}
                          className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="iapticSecretKey"
                        className="mb-2 block text-sm font-medium text-gray-700"
                      >
                        Iaptic Secret:
                      </label>
                      <p className="text-xs text-gray-500 mb-4">
                        The secret key for securely connecting the Iaptic app to your platform. <a href="https://www.iaptic.com/settings)" target="_blank" className="text-purple-600 hover:underline pl-1">Link to Iaptic's Settings</a>.
                      </p>
                      <div className="relative">
                        <input
                          type={showIapticSecret ? 'text' : 'password'}
                          id="iapticSecretKey"
                          value={localIapticSecretKey || ""}
                          onChange={(e) => setLocalIapticSecretKey(e.target.value)}
                          className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                        />
                        <button
                          type="button"
                          onClick={toggleSecretIapticVisibility}
                          className="absolute inset-y-0 right-0 px-3 flex items-center text-sm font-medium text-gray-700"
                        >
                          {showIapticSecret ? 'Hide' : 'Show'}
                        </button>
                      </div>
                    </div>
                    {process.env.REACT_APP_ENABLE_IAPTIC_WEBHOOK  && (
                      <div>
                        <label
                          htmlFor="iapticWebhookEnabled"
                          className="mb-2 block text-sm font-medium text-gray-700"
                        >
                          Enable Iaptic Webhook:
                        </label>
                        <p className="text-xs text-gray-500 mb-4">
                          Enable this setting to receive real-time webhook notifications from Iaptic (additional setup required, this disables non webhook received transactions).
                        </p>
                        <select
                          id="iapticWebhookEnabled"
                          value={localIapticWebhookEnabled}
                          onChange={(e) => setLocalIapticWebhookEnabled(e.target.value)}
                          className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                        >
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                      </div>
                    )}
                  
                  </>
                )}
                {localSelectedVerificationMethod === 'Native' && (
                  <>
                    <div>
                      <label
                        htmlFor="appStoreNotificationUrl"
                        className="mb-2 block text-sm font-medium text-gray-700"
                      >
                        iOS App Store Notification URL:
                      </label>
                      <p className="text-xs text-gray-500 mb-4">
                        The URL that will be used to receive real-time notifications from the App Store.
                      </p>
                      <div className="relative">
                        <input
                          type="text"
                          id="appStoreNotificationUrl"
                          value={appStoreNotificationUrl}
                          readOnly
                          className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 pr-12 px-3 py-2 text-gray-900 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                        />
                        <button
                          type="button"
                          onClick={() => {
                            navigator.clipboard.writeText(appStoreNotificationUrl)
                              .then(() => {
                                toast.success('Copied to clipboard.');
                              })
                              .catch((err) => {
                                toast.error('Failed to copy to clipboard.');
                                console.error('Clipboard error: ', err);
                              });
                          }}
                          className="absolute inset-y-0 right-0 px-3 flex items-center text-sm font-medium text-gray-700 hover:text-purple-600"
                        >
                          <ClipboardDocumentIcon className="h-5 w-5" />
                        </button>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="googlePlayStoreNotificationUrl"
                        className="mb-2 block text-sm font-medium text-gray-700"
                      >
                        Google Play Store Pub/Sub Endpoint URL (Android):
                      </label>
                      <p className="text-xs text-gray-500 mb-4">
                        The URL that will be used to receive real-time notifications from the App Store.
                      </p>
                      <div className="relative">
                        <input
                          type="text"
                          id="googlePlayStoreNotificationUrl"
                          value={googlePlayStoreNotificationUrl}
                          readOnly
                          className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 pr-12 px-3 py-2 text-gray-900 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                        />
                        <button
                          type="button"
                          onClick={() => {
                            navigator.clipboard.writeText(googlePlayStoreNotificationUrl)
                              .then(() => {
                                toast.success('Copied to clipboard.');
                              })
                              .catch((err) => {
                                toast.error('Failed to copy to clipboard.');
                                console.error('Clipboard error: ', err);
                              });
                          }}
                          className="absolute inset-y-0 right-0 px-3 flex items-center text-sm font-medium text-gray-700 hover:text-purple-600"
                        >
                          <ClipboardDocumentIcon className="h-5 w-5" />
                        </button>
                      </div>
                    </div>
                    <div className="mt-6">
                      <label
                        htmlFor="googleServiceAccountFile"
                        className="mb-2 block text-sm font-medium text-gray-700"
                      >
                        Upload Google Service Account Key:
                      </label>
                      <p className="text-xs text-gray-500 mb-4">
                        This file is required to verify Google Play purchases in Native mode.
                      </p>
                      {googleServiceAccountExists ? (
                          <p className="text-sm text-green-600 mb-2">
                              ✅ A service account key is already uploaded.
                          </p>
                      ) : (
                          <p className="text-sm text-red-600 mb-2">
                              ⚠️ No service account key found.
                          </p>
                      )}
                      <input
                        type="file"
                        accept=".json"
                        id="googleServiceAccountFile"
                        onChange={handleGoogleServicesAccountFileUpload}
                        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-md cursor-pointer focus:outline-none"
                      />
                      {googleServiceAccountFileName && (
                        <p className="mt-2 text-sm text-green-600">{googleServiceAccountFileName} accepted, please save changes below</p>
                      )}
                    </div>
                  </>
                )}
                {localSelectedVerificationMethod === 'RevenueCat' && (
                  <>
                    <div>
                      <label
                        htmlFor="revenueCatAuthHeader"
                        className="mb-2 block text-sm font-medium text-gray-700"
                      >
                        RevenueCat Webhook Authentication Header:
                      </label>
                      <div className="relative">
                        <input
                          type="text"
                          id="revenueCatAuthHeader"
                          value={revenueCatAuthHeader}
                          readOnly
                          className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 pr-12 px-3 py-2 text-gray-900 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                          />
                        <button
                          type="button"
                          onClick={() => {
                            navigator.clipboard.writeText(revenueCatAuthHeader)
                              .then(() => {
                                toast.success('Copied to clipboard.');
                              })
                              .catch((err) => {
                                toast.error('Failed to copy to clipboard.');
                                console.error('Clipboard error: ', err);
                              });
                          }}
                          className="absolute inset-y-0 right-0 px-3 flex items-center text-sm font-medium text-gray-700 hover:text-purple-600"
                        >
                          <ClipboardDocumentIcon className="h-5 w-5" />
                        </button>
                      </div>
                    </div>
                    <div></div>
                  </>
                )}
                </div>
            </fieldset>
          </form>
          <Button onClick={handleSubmit} className="mx-auto mt-4 pt-2">Save Changes</Button>
         
        </div>
      </Container>
    </div>
  );
};

export default Settings;
