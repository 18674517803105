import { Link, useLocation } from "react-router-dom"; 
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import {
  createUserWithEmailAndPassword,
  getAuth,
  updateProfile
} from "firebase/auth";

import { getFunctions, httpsCallable } from "firebase/functions";
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { Container } from '../../components/Container';
import { Button } from '../../components/Button.js';
import UseCustomClaims from '../../components/UseCustomClaims.js';
import { trackPageView } from "../../util/analyticsService.js";
const createUserCompany = async (user) => {
  const functions = getFunctions();
  const createCompanyDocument = httpsCallable(functions, 'createCompanyDocument');
  createCompanyDocument({
    selectedVerificationMethod: "Iaptic",
    selectedAffiliatePaymentType: "Recurring",
    iapticAppName: "",
    iapticSecretKey: "",
    stripePurchaserEmail: user.email
  })
    .then((result) => {
      // console.log(result.data.message); 
    })
    .catch((error) => {
      console.error("Error creating company document:", error);
    });
};

const generateRandomCode = (length = 10) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

const PasswordSignUp = () => {
  const { claims, updateCustomClaims } = UseCustomClaims();
  const { userType } = useParams();
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [shortCode, setShortCode] = useState(generateRandomCode());
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [referralCode, setReferralCode] = useState("");
  const location = useLocation();

  useEffect(() => {
    // Check if referral code is in the URL query parameters
    const params = new URLSearchParams(location.search);
    const referralCodeFromUrl = params.get("referralcode");
    if (referralCodeFromUrl) {
      setReferralCode(referralCodeFromUrl);
    }

    trackPageView('/passwordSignUp.js');
  }, [location]);


  // instantiate the auth service SDK
  const auth = getAuth();

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "email") setEmail(value);
    if (name === "password") setPassword(value);
    if (name === "username") setUsername(value);
    if (name === "companyName") setCompanyName(value);
    if (name === "referralCode") setReferralCode(value);
    if (name === "shortCode") {
      const sanitizedValue = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, ""); // Allow only uppercase letters and numbers
      if (sanitizedValue.length <= 10) {
        setShortCode(sanitizedValue);
      }
    }
  };

  const addAffiliateToCompany = async (
    username,
    email,
    shortCode,
    companyName,
    paymentPercentage
  ) => {
    const functions = getFunctions();
    const addAffiliateToCompanyCallable = httpsCallable(functions, 'addAffiliateToCompany');
  
    try {
      const result = await addAffiliateToCompanyCallable({
        username: username,
        email: email,
        shortCode: shortCode,
        companyName: companyName,
        paymentPercentage: paymentPercentage
      });
    
      // Return the result to the calling function
      return result.data;
    } catch (error) {
      console.error("Error creating affiliate:", error);
  
      // Rethrow the error to be handled in the calling function
      throw error;
    }
  };
  
  const setAffiliateUserCustomClaims = async (email, companyName) => {
    const functions = getFunctions();
    const setCustomClaims = httpsCallable(functions, 'setCustomClaims');

    return setCustomClaims({ email, companyName: companyName });
  };

  // Handle user sign up with email and password
  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      if (username === "") {
        setError(true);
        setLoading(false);
        setErrorMessage("Username cannot be empty");
        return;
      } else if (email === "") {
        setError(true);
        setLoading(false);
        setErrorMessage("Email cannot be empty");
        return;
      } else if (password === "") {
        setError(true);
        setLoading(false);
        setErrorMessage("Password cannot be empty");
        return;
      } else if (shortCode === "") {
        setError(true);
        setLoading(false);
        setErrorMessage("Short code cannot be empty");
        return;
      } else if (shortCode.length !== 10) {
        setError(true);
        setLoading(false);
        setErrorMessage("Short code must be 10 characters long");
        return;
      } else if (!shortCode.match(/^[A-Z0-9]+$/)) {
        setError(true);
        setLoading(false);
        setErrorMessage("Short code must only contain letters and numbers");
        return;
      } 

      if (userType === 'affiliate') {
        try {
          const response = await addAffiliateToCompany(username, email, shortCode, companyName);
          if (response.message !== "Affiliate added to company.") {
            setError(true);
            setLoading(false);
            setErrorMessage(response.message);
            return;
          }
        } catch (error) {
          setError(true);
          setLoading(false);
          setErrorMessage("Error - " + error);
          return;
        }
      }

      await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      if (auth.currentUser !== null && username !== null && username !== "") {
        await updateProfile(auth.currentUser, {
          displayName: username
        });
      }

      if (userType === 'affiliate') {
        await setAffiliateUserCustomClaims(email, companyName);
        await updateCustomClaims();
        setLoading(false);
        navigate('/');
      } else {
        await createUserCompany(auth.currentUser, companyName);

        await fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/sendWelcomeEmailToCompany`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              email: email,
              displayName: username
            }),
        });

        if (referralCode ) {
          await fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/store-referral`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              company_email: email,
              referral_code: referralCode,
              date_time: new Date().toISOString(),
              discount_amount: 10
            })
          });
        }
      }

      setLoading(false);
      navigate('/');
    } catch (err) {
      const errorMessage = err.message;
      const errorCode = err.code;
      setLoading(false);
      setError(true);

      switch (errorCode) {
        case "auth/weak-password":
          setErrorMessage("The password is too weak.");
          break;
        case "auth/email-already-in-use":
          setErrorMessage(
            "This email address is already in use by another account."
          );
          break;
        case "auth/invalid-email":
          setErrorMessage("This email address is invalid.");
          break;
        case "auth/operation-not-allowed":
          setErrorMessage("Email/password accounts are not enabled.");
          break;
        default:
          setErrorMessage(errorMessage);
          break;
      }
    }
  };

  return (
    <div id="app">
      <Container>
        <Header />
      </Container>

      <div className="signupContainer flex flex-col items-center justify-center" style={{ minHeight: '65vh' }}>
        <div className='max-w-lg w-full px-4 pb-8'>
          <h1 className="pb-6 mx-auto max-w-4xl font-display text-5xl font-medium tracking-tight text-slate-900 sm:text-5xl">
            Sign Up
          </h1>
          <form className='signinContainer__box__form w-full max-w-lg mx-auto flex flex-col space-y-4' onSubmit={handleSubmit}>
           <input
              type='text'
              placeholder='Username, e.g. MichaelButler'
              onChange={handleChange}
              name='username'
              value={username}
              className='border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500'
            />
            {userType === 'affiliate' ? (
              <input
                type='email'
                placeholder='Email, e.g. michael@insertaffiliate.com'
                onChange={handleChange}
                name='email'
                value={email}
                className='border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500 w-full'
              />
            ) : (
              <>
                <input
                  type='email'
                  placeholder='Email (must match email used for Payment)'
                  onChange={handleChange}
                  name='email'
                  value={email}
                  className='border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500 w-full'
                />
                <input
                  type='text'
                  placeholder='Referral Code'
                  onChange={handleChange}
                  name='referralCode'
                  value={referralCode}
                  className='border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500'
                />
              </>
              
            )}
            <input
              type='password'
              placeholder='Password'
              onChange={handleChange}
              name='password'
              value={password}
              className='border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500'
            />
            {userType === 'affiliate' && ( // Conditionally render the company name field
              <>
                <input
                  type='text'
                  placeholder='Company Name, e.g. Insert Affiliate'
                  onChange={handleChange}
                  name='companyName'
                  value={companyName}
                  className='border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500'
                />
                <label>The short code is the code your audience to enter after downloading the app to attribute you as the affiliate <br></br><br></br>10 letters, characters and numbers only</label>
                <input
                  type='text'
                  placeholder='Short Code, e.g. INSERTAFFILIATE '
                  onChange={handleChange}
                  name='shortCode'
                  value={shortCode}
                  className='border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500'
                />
              </>
            )}
            <Button type='submit'>
              {loading ? "Loading..." : "Sign Up"}
            </Button>
            {error && <p className='text-red-500'>{errorMessage}</p>}
          </form>

          <div className='signupContainer__box__login pt-4'>
            <p>
              Already have an account? <Link to='/signin' className="text-purple-600 cursor-pointer">Sign In</Link>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PasswordSignUp;