import React, {useContext } from 'react';
import { Container } from '../../components/Container.js';
import { ClipboardDocumentIcon, LinkIcon, UserPlusIcon, GiftIcon } from '@heroicons/react/20/solid';
import { toast } from 'react-toastify';
import { ApiContext } from '../../contexts/ApiContext.js';
import FetchUserAndFirebaseDocsOnAuth from '../../components/fetchFirebaseDocsOnAuth.js';


const Refer = () => {
    const { companyId } = useContext(ApiContext);
    FetchUserAndFirebaseDocsOnAuth();

    return (
        <div>
            <Container>
                <div className="pb-8 mx-auto" style={{ minHeight: '65vh' }}>
                    <div className="pb-4">
                        <h1 className="font-display text-2xl tracking-tight text-slate-900 sm:text-3xl">
                            Love Insert Affiliate? 
                        </h1>
                        <p className="mt-2 mb-4 text-lg tracking-tight text-slate-700">
                            Refer another business and get <span className="font-semibold text-purple-600">one month free</span> when they sign up.
                        </p>
                    </div>
                    {/* How It Works Section */}
                    <div className="mb-8 p-4 bg-white shadow-md border border-gray-200 rounded-lg">
                        <h2 className="text-xl font-semibold text-gray-800 text-center mb-4">
                            How It Works
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-center">
                            {/* Step 1 */}
                            <div className="flex flex-col items-center">
                                <LinkIcon className="h-12 w-12 text-purple-600" />
                                <p className="mt-2 font-medium text-gray-800">Share your link</p>
                                <p className="text-sm text-gray-600">Send your unique referral link to another app owner.</p>
                            </div>

                            {/* Step 2 */}
                            <div className="flex flex-col items-center">
                                <UserPlusIcon className="h-12 w-12 text-green-500" />
                                <p className="mt-2 font-medium text-gray-800">They sign up</p>
                                <p className="text-sm text-gray-600">They create an account and purchase a package.</p>
                            </div>

                            {/* Step 3 */}
                            <div className="flex flex-col items-center">
                                <GiftIcon className="h-12 w-12 text-yellow-500" />
                                <p className="mt-2 font-medium text-gray-800">You both earn</p>
                                <p className="text-sm text-gray-600">You both receive free access worth one month of their selected package.</p>
                            </div>
                        </div>
                    </div>

                    <div className="mb-8 p-4 bg-white shadow-md border border-gray-200 rounded-lg">
                        <h2 className="text-xl font-semibold text-gray-800 text-center mb-4">
                            Copy and Share Your Referral Link
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <label
                                    htmlFor="referralCode"
                                    className="mb-2 block text-sm font-medium text-gray-700"
                                >
                                    Referral Code:
                                </label>
                                <p className="text-xs text-gray-500 mb-4">
                                    Share this referral code with a friend to get one month free.
                                </p>
                                <div class="relative">
                                    <p
                                        type="text"
                                        id="companyName"
                                        className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                                    >
                                        {companyId.substring(0,8)}
                                    </p>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            navigator.clipboard.writeText(companyId.substring(0,8))
                                            .then(() => {
                                                toast.success('Copied to clipboard.');
                                            })
                                            .catch((err) => {
                                                toast.error('Failed to copy to clipboard.');
                                                console.error('Clipboard error: ', err);
                                            });
                                        }}
                                        className="absolute inset-y-0 right-0 px-3 flex items-center text-sm font-medium text-gray-700 hover:text-purple-600"
                                    >
                                    <ClipboardDocumentIcon className="h-5 w-5" />
                                    </button>
                                </div>
                            </div>
                            <div>
                                <label
                                    htmlFor="referralLink"
                                    className="mb-2 block text-sm font-medium text-gray-700"
                                >
                                    Referral Link:
                                </label>
                                <p className="text-xs text-gray-500 mb-4">
                                    Copy and share this referral link to invite businesses directly.
                                </p>
                                <div class="relative">
                                    <p
                                        type="text"
                                        id="companyName"
                                        className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                                    >
                                        https://app.insertaffiliate.com/signup/app-owner?referralcode={companyId.substring(0,8)}
                                    </p>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            navigator.clipboard.writeText("https://app.insertaffiliate.com/signup/app-owner?referralcode=" + companyId.substring(0,8))
                                            .then(() => {
                                                toast.success('Copied to clipboard.');
                                            })
                                            .catch((err) => {
                                                toast.error('Failed to copy to clipboard.');
                                                console.error('Clipboard error: ', err);
                                            });
                                        }}
                                        className="absolute inset-y-0 right-0 px-3 flex items-center text-sm font-medium text-gray-700 hover:text-purple-600"
                                    >
                                    <ClipboardDocumentIcon className="h-5 w-5" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
            </Container>
        </div>
    );
};

export default Refer;