import { Link, useNavigate, useLocation } from 'react-router-dom'; // Updated import
import React, { useState, useEffect } from "react";
import { getAuth, confirmPasswordReset } from "firebase/auth";
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { Container } from '../../components/Container';
import { Button } from '../../components/Button.js';
import { trackPageView } from '../../util/analyticsService.js';

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  
  // Firebase Auth instance
  const auth = getAuth();

  // Extract the oobCode from the URL query parameters
  const [oobCode, setOobCode] = useState("");
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("oobCode");
    if (code) {
      setOobCode(code);
    } else {
      setError(true);
      setErrorMessage("Invalid or missing code in the URL.");
    }
  }, [location.search]);

  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  useEffect(() => {
    trackPageView('/resetPassword.js');
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!oobCode) {
      setError(true);
      setErrorMessage("Password reset code is missing.");
      return;
    }

    try {
      // Reset the password with Firebase
      await confirmPasswordReset(auth, oobCode, password);
      setSuccessMessage("Password has been set successfully! Redirecting...");
      setTimeout(() => navigate('/signin'), 3000); // Redirect after 3 seconds
    } catch (err) {
      // Handle errors
      setError(true);
      setErrorMessage(err.message || "Failed to reset password. Please try again.");
    }
  };

  return (
    <div id="app">
      <Container>
        <Header />
      </Container>

      <div className="flex flex-col items-center justify-center" style={{ minHeight: '65vh' }}>
        <div className='max-w-lg w-full px-4 pb-8'>
            <h1 className="pb-6 mx-auto max-w-4xl font-display text-5xl font-medium tracking-tight text-slate-900 sm:text-5xl">
                Reset Password
            </h1>
            <form className='signinContainer__box__form w-full max-w-lg mx-auto flex flex-col space-y-4' onSubmit={handleSubmit}>
                <input
                type='password'
                placeholder='Enter your new password'
                name='password'
                value={password}
                onChange={handleChange}
                className='border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500'
                />
                <Button type='submit'>Set Password</Button>
                {error && <p className='text-red-500'>{errorMessage}</p>}
                {successMessage && <p className='text-green-500'>{successMessage}</p>}
            </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ResetPassword;
