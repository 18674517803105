import React, { useState, useContext, useEffect } from 'react';
import * as Sentry from "@sentry/react";
import './App.css';
import './styles/styles.scss';
import { getAuth } from 'firebase/auth';
import PasswordSignIn from './app/auth/PasswordSignIn'; // Import your Login component

import FetchUserAndFirebaseDocsOnAuth from './components/fetchFirebaseDocsOnAuth.js'
import AdminHomeDashboard from './app/dashboard/AdminHomeDashboard.js';
import AffiliateHomeDashboard from './app/dashboard/AffiliateHomeDashboard.js';
import { ApiContext } from './contexts/ApiContext.js';
import { useNavigate } from 'react-router-dom';

const App = () => {
    const auth = getAuth();
    const user = FetchUserAndFirebaseDocsOnAuth();
    const [isAffiliate, setIsAffiliate] = useState(null);
    const {
        stripePurchaserEmail,
        setSubscriptionStatus
    } = useContext(ApiContext);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    // Check affililate status
    useEffect(() => {
        const fetchAffiliateStatus = async () => {
            if (user) {
                const auth = getAuth();
                try {
                    const idTokenResult = await auth.currentUser.getIdTokenResult();
                    setIsAffiliate(idTokenResult.claims.affiliate || false);
                } catch (error) {
                    Sentry.captureException("Error fetching user claims", error);
                    setIsAffiliate(false);
                } finally {
                    setTimeout(() => {
                        setLoading(false);
                    }, 3000);
                }
            } else {
                setIsAffiliate(false);
                setTimeout(() => {
                    setLoading(false);
                }, 4000); // Set loading to false after 4 seconds
            }
        };

        if (user) {
            fetchAffiliateStatus();
        } else {
            setIsAffiliate(false);
            setLoading(false);
        }

    }, [user]);

    useEffect(() => {
        // Reset state when user changes
        setIsAffiliate(null);
        setLoading(true);
    }, [user]);

    const [paidStatus, setPaidStatus] = useState(null);

    useEffect(()=>{
        const checkSubscriptionStatus = async () => {
            const isLocalhost = window.location.hostname === 'localhost';

            if(isAffiliate === false) {
                fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/getInitialPayment`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ email: stripePurchaserEmail })
                }).then((res) => res.json()).then(data => {
                    if (data?.status === 'Email not found') {
                        // this user not subscribed yet
                        setSubscriptionStatus(false);
                        localStorage.setItem('subscriptionStatus',false);

                        window.alert("You are not subscribed");
                        //  signOut(auth);
                        // window.location.href = `${process.env.REACT_APP_LANDING_PAGE}/#pricing`
                        navigate('/billing');
                    } else {
                        fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/retrieveSubscription`, {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ subscriptionId: data?.data?.subscriptionId })
                        }).then((res) => res.json()).then(data => {
                            const subscriptionData = data.subscription;
                            if (subscriptionData !== null && subscriptionData !== undefined) {
                                const { status } = subscriptionData;

                                if (status === 'active') {
                                    setPaidStatus(true);
                                    setSubscriptionStatus(true);
                                    localStorage.setItem('subscriptionStatus', true);
                                    localStorage.setItem('paidStatus', true);
                                } else {
                                    const invoiceId = subscriptionData.latest_invoice;
                                    setSubscriptionStatus(false);
                                    localStorage.setItem('subscriptionStatus', false);
                                    localStorage.setItem('paidStatus', false);
                                    localStorage.setItem('invoiceId', invoiceId);

                                    if (!isLocalhost) {
                                        navigate('/billing');
                                    } else {
                                        setLoading(false)
                                    }
                                    
                                }
                            } else {
                                if (isLocalhost) {
                                    setLoading(false) // -- Local testing only
                                    return;
                                }
                                setSubscriptionStatus(false);
                                localStorage.setItem('subscriptionStatus', false);
                                localStorage.setItem('paidStatus', false);
                                navigate('/billing');
                                // localStorage.setItem('invoiceId', invoiceId);
                            }
                        })

                    }
                })
            } else {
                setLoading(true)

                fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/check-affiliate`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ affiliateEmail: stripePurchaserEmail })
                }).then((res) => res.json()).then(data => {
                    setLoading(true);
                    fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/getInitialPayment`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ email: data.stripePurchaserEmail })
                    }).then((res) => res.json()).then(data => {
                        if (data?.status === 'Email not found') {
                            // ** This user not subscribed yet ** //
                            setSubscriptionStatus(false);
                            localStorage.setItem('subscriptionStatus', false);
                            setLoading(false);
                            navigate('/contact-company');
                        } else {
                            setLoading(false)
                        }
                    })
                })
            }
        }

        if (stripePurchaserEmail && isAffiliate !== null) {
            checkSubscriptionStatus();
        }

    }, [stripePurchaserEmail, isAffiliate])

    const renderContent = () => {
        if (loading) {
            return <div className='flex justify-center items-center text-3xl'>Loading...</div>;
        }

        switch (true) {
            case user === null:
                return <PasswordSignIn />;
            case user !== null && isAffiliate === true:
                return <AffiliateHomeDashboard />;
            case user !== null && isAffiliate === false:
                return <AdminHomeDashboard />;
            default:
                return <PasswordSignIn />;
        }
    };

    return (
        <div id="app" className="flex h-full flex-col">
           <div>{renderContent()}</div>
        </div>
    );
};

export default App;
