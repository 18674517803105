import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiContext } from '../../contexts/ApiContext';
import { ClipboardDocumentIcon } from '@heroicons/react/24/solid';
import FetchUserAndFirebaseDocsOnAuth from '../../components/fetchFirebaseDocsOnAuth.js'
import { Container } from '../../components/Container';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BillingAlertModal from '../../components/BillingAlertModal';
import { trackPageView } from '../../util/analyticsService.js';

import {
  getAuth,
  sendPasswordResetEmail
} from "firebase/auth";

import { db } from '../../firebase';
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';

// const getLastDayOfPreviousMonth = () => {
//   const now = new Date();
//   return new Date(now.getFullYear(), now.getMonth(), 0); // Setting day to 0 gets the last day of the previous month
// };

const ShowAllAffiliates = () => {
  const {
    iapticAppName,
    iapticSecretKey,
    apiUrl,
    subscriptionStatus,
    selectedVerificationMethod,
    companyId,
    iapticWebhookEnabled
  } = useContext(ApiContext);
  const [amountOwed, setAmountOwed] = useState({});

  const [tableData, setTableData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAmounts = async () => {
      const amounts = {};
      for (let affiliate of tableData) {
        amounts[affiliate.email] = await returnTotalAffiliateHasNotBeenPaid(affiliate);
      }
      setAmountOwed(amounts);
    };
  
    if (tableData.length > 0) {
      fetchAmounts();
    }
  }, [tableData]);

  useEffect(() => {
    trackPageView('/showAllAffiliates.js');
  }, []);

  useEffect(() => {
    if (subscriptionStatus === false || subscriptionStatus === undefined) {
      navigate('/billing');
    }
  }, [subscriptionStatus, navigate]);

  const auth = getAuth();
  const user = FetchUserAndFirebaseDocsOnAuth();
  
  useEffect(() => {
    
    if (user !== null && iapticAppName !== '' && iapticSecretKey !== '') {
      
      showAllAffiliates();
      setLoading(false);
    } else {
      const timeout = setTimeout(() => {
        setLoading(false);
      }, 500);
      
      return () => clearTimeout(timeout); // Cleanup timeout on unmount
    }
  }, [user, iapticAppName, iapticSecretKey]);

  useEffect(() => {
    if (user !== null && loading === false) {
      if (iapticAppName === '' || iapticSecretKey === '') {
        showAllAffiliates(true);
      }
    }
  }, [user, loading]);

  const refactorIapticCustomersToMatchFirebaseAffiliates = (iapticCustomers, firebaseAffiliates) => {
    if (iapticCustomers.length === 0 && firebaseAffiliates.length === 0) {
      return;
    }
    if (iapticCustomers.length === 0) {
      return;
    }

    iapticCustomers.forEach((iapticCus, index) => {
      firebaseAffiliates.forEach((firebaseAffiliate) => {
        if (firebaseAffiliate.deeplinkurl === iapticCus) {
          // Remove this iaptic customer from the list as they already exist within the Firebase list
          iapticCustomers.splice(index, 1);
        }
      });
    });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success('Copied to clipboard.');
    }).catch(err => {
        console.error('Failed to copy: ', err);
    });
  };
  
  const fetchFirestoreAffiliates = async (uid) => {
    if (!uid) {
      console.error("User UID is not provided");
      return [];
    }

    const companiesRef = collection(db, 'Companies');
    const userDocRef = doc(companiesRef, uid);

    const affiliateCollection = collection(userDocRef, 'Affiliates');

    // how to loop over each in affiliateCollection
    const affiliateCollectionSnapshot = await getDocs(affiliateCollection);

    let groupedDataFirebase = [];
    
    // how to loop over affiliateCollectionSnapshot and output all the data
    affiliateCollectionSnapshot.forEach((doc) => {
      let affiliateEntry = {};
      affiliateEntry.email = doc.data().email
      affiliateEntry.affiliatename = doc.data().affiliatename
      affiliateEntry.deeplinkurl = doc.data().deeplinkurl
      affiliateEntry.affiliateShortCode = doc.data().affiliateShortCode
      affiliateEntry.affiliateOfferCodes = doc.data().affiliateOfferCodes
      affiliateEntry.totalAffiliateHasNotBeenPaid = doc.data().totalAffiliateHasNotBeenPaid
      affiliateEntry.transactionsAffiliateHasBeenPaidFor = doc.data().transactionsAffiliateHasBeenPaidFor
      affiliateEntry.transactionsAffiliateHasNotBeenPaidFor = doc.data().transactionsAffiliateHasNotBeenPaidFor
      groupedDataFirebase.push(affiliateEntry);
    });

    return groupedDataFirebase
  };

  const searchAffiliate = (affiliatename) => {
    navigate(`/affiliate-search?affiliate=${affiliatename}`);
  }

  const markAllTransactionAsPaid = async (affiliate) => {
    const companiesRef = collection(db, 'Companies');
    const userDocRef = doc(companiesRef, user.uid);

    const affiliateRef = doc(collection(userDocRef, 'Affiliates'), affiliate.email);

    const updatedTransactionsAffiliateHasBeenPaidFor = affiliate.transactionsAffiliateHasBeenPaidFor.concat(affiliate.transactionsAffiliateHasNotBeenPaidFor);

    const amountToAdd = affiliate.totalAffiliateHasNotBeenPaid;
    
    try {
      await updateDoc(affiliateRef, {
        totalAffiliateHasNotBeenPaid: 0,
        transactionsAffiliateHasNotBeenPaidFor: [],
        transactionsAffiliateHasBeenPaidFor: updatedTransactionsAffiliateHasBeenPaidFor,
        totalAffiliateHasBeenPaid: (affiliate.totalAffiliateHasBeenPaid || 0) + amountToAdd
      });

      showAllAffiliates();
    } catch (error) {
        console.error("Error updating affiliate:", error);
    }
  }

  const showAllAffiliates = async (setDataMissingError = false) => {
    setError(null); // Clear previous errors

    if (setDataMissingError && selectedVerificationMethod === 'Iaptic') {
      setError("Please enter your Iaptic App Name and Iaptic Secret Key in Insert Affiliate's Settings to view all affiliates.");
    }

    try {
      // Database data from Firestore
      const firebaseAffiliates = await fetchFirestoreAffiliates(user.uid);

      //1: check the type of transaction...
      // Iaptic data...
      let iapticCustomers = [];
      if (selectedVerificationMethod === 'Iaptic') {
        if (iapticAppName !== '' && iapticSecretKey !== '') {
          try {
            const response1 = await fetch(`${apiUrl}/returnAllCustomersApplicationUsernames?appName=${iapticAppName}&secretKey=${iapticSecretKey}`);
            if (!response1.ok) {
              throw new Error(`Error fetching iaptic customers: ${response1.status}`);
            }
            iapticCustomers = await response1.json();
          } catch (error) {
            setError("Failed to fetch data. Please check all details within your company settings are correct and try again later.");
            return;
          }
        }
        // TODO: For Iaptic customers, I first need to go through the Firebase affiliates and check if the Firebase affiliate's deepLinkUrl's final part matches the Iaptic customer's affiliate name. If it does, then I want to change the "affiliateName" to the Firebase affiliate's name. If it doesn't, then I want to remove that Iaptic customer from the list.
        refactorIapticCustomersToMatchFirebaseAffiliates(iapticCustomers, firebaseAffiliates);
      }
      

      const combinedData = [...new Set([...firebaseAffiliates, ...iapticCustomers])]; // filteredIapticCustomers
      const groupedData = groupDataByAffiliate(combinedData);

      // Only show affiliates that're within Firebase from Iaptic...
      // Get all affiliate names
      const firebaseAffiliatesNames = firebaseAffiliates.map(affiliate => affiliate.affiliatename);
      const filteredIapticCustomers = groupedData.filter(iapticCus => firebaseAffiliatesNames.includes(iapticCus.affiliatename));

      setTableData(filteredIapticCustomers);
    } catch (error) {
      setError("Failed to fetch affiliate data. Please check all details are correct and try again later.");
    }
  };

  const groupDataByAffiliate = (data) => {
    const groupedData = [];

    data.forEach(item => {
      let affiliateEntry = {};

      if (item.hasOwnProperty('affiliatename')) {
        affiliateEntry.affiliatename = item.affiliatename;
      } else {
        affiliateEntry.affiliatename = item //extractAffiliateName(item);
      }

      if (item.hasOwnProperty('deeplinkurl')) {
        affiliateEntry.deeplinkurl = item.deeplinkurl;
      }

      if (item.hasOwnProperty('affiliateOfferCode')) {
        affiliateEntry.affiliateOfferCode = item.affiliateOfferCode;
      } else {
        affiliateEntry.affiliateOfferCode = "";
      }

      if (item.hasOwnProperty('affiliateShortCode')) {
        affiliateEntry.affiliateShortCode = item.affiliateShortCode;
      } else {
        affiliateEntry.affiliateShortCode = "";
      }

      if (item.hasOwnProperty('totalAffiliateHasNotBeenPaid')) {
        affiliateEntry.totalAffiliateHasNotBeenPaid = item.totalAffiliateHasNotBeenPaid;
      } else {
        affiliateEntry.totalAffiliateHasNotBeenPaid = 0;
      }

      if (item.hasOwnProperty('transactionsAffiliateHasBeenPaidFor')) {
        affiliateEntry.transactionsAffiliateHasBeenPaidFor = item.transactionsAffiliateHasBeenPaidFor;
      } else {
        affiliateEntry.transactionsAffiliateHasBeenPaidFor = [];
      }

      if (item.hasOwnProperty('transactionsAffiliateHasNotBeenPaidFor')) {
        affiliateEntry.transactionsAffiliateHasNotBeenPaidFor = item.transactionsAffiliateHasNotBeenPaidFor;
      } else {
        affiliateEntry.transactionsAffiliateHasNotBeenPaidFor = [];
      }

      if (item.hasOwnProperty('email')) {
        affiliateEntry.email = item.email;
      } else {
        affiliateEntry.email = "";
      }

      groupedData.push(affiliateEntry);
    });

    return groupedData;
  };

  const resetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      toast.success('Reset password email sent.');
    } catch (err) {
      toast.error('Error sending reset password email.');
    }
  }

  const deleteUser = async (email) => {
      const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/deleteAffiliateUser`;
      const payload = {
          email: email,
          companyId: companyId,
      };

      console.log("companyId: ", companyId);

      try {
          const idToken = await user.getIdToken(); // Get Firebase Auth token

          const response = await fetch(url, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}` // Pass auth token
              },
              body: JSON.stringify(payload),
          });

          const result = await response.json();
          console.log(result);
          if (response.ok) {
            toast.success('User deleted.');

            // Remove the deleted user from the state immediately for a smoother UI
            setTableData((prevData) => prevData.filter((affiliate) => affiliate.email !== email));

            // Fetch the updated list from Firestore to ensure it's accurate
            showAllAffiliates();
        } else {
            toast.error(result.error || 'Error deleting user.');
        }
      } catch (error) {
          console.error("Error calling deleteAffiliateUser:", error ,". If this issue persists, please contact michael@insertaffiliate.com for support.");
          toast.error('Error deleting user.');
      }
  }

  const sortTableData = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    const sortedData = [...tableData].sort((a, b) => {
      if (direction === 'ascending') {
        if (a[key] < b[key]) return -1;
        if (a[key] > b[key]) return 1;
      } else {
        if (a[key] < b[key]) return 1;
        if (a[key] > b[key]) return -1;
      }
      return 0;
    });
    setTableData(sortedData);
  };

  
  const returnTotalAffiliateHasNotBeenPaid = async (row) => {
    let totalAffiliateHasNotBeenPaid = 0;
    switch (selectedVerificationMethod) {
      case 'Iaptic':
        if (iapticWebhookEnabled === "false") {
          return row.totalAffiliateHasNotBeenPaid ? row.totalAffiliateHasNotBeenPaid.toFixed(2) : '0.00';
        } else {
          if (!row.email) {
            return '0.00';
          }
          const affiliateIapticRef = doc(collection(db, 'Companies', user.uid, 'Affiliates'), row.email);
          const iapticTransactionsRef = collection(affiliateIapticRef, 'iapticTransactions');
          const iapticTransactionsSnapshot = await getDocs(iapticTransactionsRef);

          if (iapticTransactionsSnapshot.empty || !iapticTransactionsSnapshot) {
            return '0.00';
          }

          iapticTransactionsSnapshot.forEach((doc) => {
           
            if (!doc.data().affiliateHasBeenPaid) {
              totalAffiliateHasNotBeenPaid += doc.data().priceInUSD;
            }
          });
          return totalAffiliateHasNotBeenPaid.toFixed(2);
        }
      case 'RevenueCat':
        if (!row.email) {
          return '0.00';
        }
        const affiliateRevCatRef = doc(collection(db, 'Companies', user.uid, 'Affiliates'), row.email);
        const revenueCatTransactionsRef = collection(affiliateRevCatRef, 'revenueCatTransactions');
        const revenueCatTransactionsSnapshot = await getDocs(revenueCatTransactionsRef);

        if (revenueCatTransactionsSnapshot.empty || !revenueCatTransactionsSnapshot) {
          return '0.00';
        }

        revenueCatTransactionsSnapshot.forEach((doc) => {
          if (!doc.data().affiliateHasBeenPaid) {
            totalAffiliateHasNotBeenPaid += doc.data().price;
          }
        });

        return totalAffiliateHasNotBeenPaid.toFixed(2);
      case 'Native':
        if (!row.email) {
          return '0.00';
        }
        const affiliateNativeRef = doc(collection(db, 'Companies', user.uid, 'Affiliates'), row.email);

        const nativeTransactionsRef = collection(affiliateNativeRef, 'appStoreTransactions');
        const nativeTransactionsSnapshot = await getDocs(nativeTransactionsRef);

        if (nativeTransactionsSnapshot.empty || !nativeTransactionsSnapshot) {
          return '0.00';
        }

        nativeTransactionsSnapshot.forEach((doc) => {
          if (!doc.data().affiliateHasBeenPaid) {
            if (doc.data().platform === 'iOS') {
              totalAffiliateHasNotBeenPaid += doc.data().price / 10000; // Convert from cents to dollars
            } else {
              totalAffiliateHasNotBeenPaid += doc.data().price / 1000000; // Convert from micros to dollars
            }
          }
        });

        return totalAffiliateHasNotBeenPaid.toFixed(2);

      default:
        return '0.00';
    }
  };

  return (
    <div>
      <Container>
        <div>
            <div className="pb-16 overflow-y-auto" style={{ minHeight: '65vh' }}>
              <BillingAlertModal />
              <div className="pb-4">
                <h1 className="font-display text-2xl tracking-tight text-slate-900 sm:text-3xl">
                  All Affiliates
                </h1>
              </div>
              <ToastContainer position="bottom-center" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick rtl pauseOnFocusLoss draggable pauseOnHover />
              {error && (
                <div className="mb-4 p-4 text-red-700 bg-red-100 border border-red-400 rounded">
                  {error}
                </div>
              )}
              <div>
                { tableData && tableData.length > 0 && (
                  <table className="table-auto dataTable">
                    <thead>
                      <tr>
                        <th className="font-bold cursor-pointer" onClick={() => sortTableData('email')}>Affiliate Email</th>
                        <th className="font-bold cursor-pointer" onClick={() => sortTableData('affiliatename')}>Affiliate Name</th>
                        <th className="font-bold cursor-pointer" onClick={() => sortTableData('deeplinkurl')}>Deep Link URL</th>
                        <th className="font-bold cursor-pointer">Amount Owed</th>
                        {/* <th className="font-bold">Action Required</th> */}
                        <th className="font-bold">Reset Password</th>
                        <th className="font-bold">Delete Affiliate</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData && tableData.length > 0 && (
                        tableData.map((row, index) => (
                          <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : ''}>
                            <td
                              className="py-2 px-4 cursor-pointer text-purple-600"
                              onClick={() => searchAffiliate(row.affiliatename) }
                            >
                              {row.email}
                            </td>
                            <td
                              className="py-2 px-4 cursor-pointer"
                              onClick={() => searchAffiliate(row.affiliatename) }
                            >
                              {row.affiliatename}
                            </td>
                            <td
                              className="py-2 px-4 cursor-pointer flex items-center"
                              onClick={() => copyToClipboard(row.deeplinkurl)} 
                            >
                              {row.deeplinkurl}
                              <ClipboardDocumentIcon 
                                className="h-5 w-5 ml-2  hover:text-gray-700 cursor-pointer" 
                                onClick={() => copyToClipboard(row.deeplinkurl)} 
                              />
                            </td>
                            <td>
                              {amountOwed[row.email] !== undefined ? amountOwed[row.email] : 'Loading...'}
                            </td>

                            {/* {row.totalAffiliateHasNotBeenPaid ? (
                            <td>
                              <button onClick={() => markAllTransactionAsPaid(row)} className="text-purple-600">
                                Mark as Paid
                              </button>
                            </td>
                            ) : (
                            <td>
                              <span>-</span>
                            </td>
                            )} */}
                            <td className="py-2 px-4 cursor-pointer">
                              <button onClick={() => resetPassword(row.email)} className="text-purple-600">
                                Reset
                              </button>
                            </td>
                            <td className="py-2 px-4 cursor-pointer">
                              <button onClick={() => deleteUser(row.email)} className="text-purple-600">
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                )}
                { (!tableData || tableData.length === 0) && (
                  <div>
                    <p className="text-gray-600">No affiliates found. Please create affiliates to manage data.</p>
                    {/* <button
                      className="bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700 mt-4"
                      onClick={createAffiliate}
                    >
                      Create Affiliate
                    </button> */}
                  </div>
                )}
              </div>
            </div>
        </div>
      </Container>
    </div>
  );
};

export default ShowAllAffiliates;
